main a {
    @if $type=="" {
        color: #5659f1;
        text-decoration: underline;
    }

    @if $type==sp {}

    @if $type==pc {
        &:hover {
            text-decoration: none;
        }
    }
}

h1,
h2,
h3,
h4,
h5 {
    line-height: 1.5;
}

.secSecond {
    @if $type==sp {
        margin-top: 50px;
    }

    @if $type==pc {
        margin-top: 80px;
    }
}

.pageTitleWrap {
    @if $type=="" {
        width: 100%;
        color: #192f60;
        position: relative;
    }

    @if $type==sp {
        border-bottom: 2px solid #dddddd;

        // min-height: 120px;
        &:after {
            content: "";
            display: inline-block;
            width: 65px;
            height: 2px;
            background-color: #192f60;
            position: absolute;
            bottom: -2px;
            left: 0;
        }

        &.post {
            .pageTitleJp {
                font-size: 22px;
            }
        }
    }

    @if $type==pc {
        min-height: 162px;
        border-bottom: 3px solid #dddddd;

        &:after {
            content: "";
            display: inline-block;
            width: 130px;
            height: 3px;
            background-color: #192f60;
            position: absolute;
            bottom: -3px;
            left: 0;
        }

        &.post {

            // min-height: 210px;
            .pageTitleInner {
                // min-height: 210px;
                max-width: 780px;
                padding: 40px 0 10px;
            }
        }
    }

    .pageTitleInner {
        @if $type=="" {
            position: relative;
        }

        @if $type==sp {
            padding: 35px 20px 0px;
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            // min-height: 120px;
        }

        @if $type==pc {
            @include maxPC;
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            min-height: 162px;
            height: 100%;
        }
    }

    .pageTitleJp {
        @if $type=="" {
            font-weight: bold;
        }

        @if $type==sp {
            font-size: 23px;
        }

        @if $type==pc {
            font-size: 34px;
        }
    }

    .pageTitleEn {
        @if $type=="" {
            font-weight: 500;
            @include fontEn;
            line-height: 1.3;
            text-transform: capitalize;
        }

        @if $type==sp {
            font-size: 11px;
        }

        @if $type==pc {
            font-size: 16px;
        }
    }

    .tag {
        @if $type=="" {
            display: inline-block;
            color: #9e9e9e;
            border: 1px solid #9e9e9e;
            border-radius: 3px;
            padding: 3px 3px;
            line-height: 1;
            margin-bottom: 5px;
        }

        @if $type==sp {
            font-size: 12px;
        }

        @if $type==pc {
            font-size: 13px;
        }
    }

    .subTitle {
        @if $type=="" {
            display: inline-block;
            line-height: 1;
            margin-bottom: 5px;
        }

        @if $type==sp {
            font-size: 13px;
            margin-left: 10px;
        }

        @if $type==pc {
            font-size: 14px;
            margin-left: 20px;
        }
    }
}

.breadcrumb {
    @if $type=="" {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-end;
        // position: absolute;
        // bottom: 0;
        // right: 0;
    }

    @if $type==sp {
        padding: 20px 0 8px 20px;
    }

    @if $type==pc {
        padding: 15px 0 15px 40px;
    }

    li {
        @if $type=="" {
            position: relative;
            color: $c_base;

            a {
                color: $c_base;
            }
        }

        @if $type==sp {
            font-size: 10px;

            +li {
                margin-left: 30px;

                &:before {
                    content: '／';
                    position: absolute;
                    top: 1px;
                    left: -20px;
                }
            }
        }

        @if $type==pc {
            font-size: 12px;

            +li {
                margin-left: 35px;

                &:before {
                    content: '／';
                    position: absolute;
                    top: 1px;
                    left: -22px;
                }
            }
        }
    }
}

#contents {
    @if $type=="" {
        line-height: 1.8;

        &.bgGray {
            background-color: #f8f8f8;
        }
    }

    @if $type==sp {
        padding: 50px 0;
    }

    @if $type==pc {
        padding: 80px 0;
    }

    #recommend {
        @if $type=="" {
            padding-bottom: 0;
        }
    }

    &:has(#faq) {
        @if $type=="" {
            padding: 0;
        }
    }
}

.inner {
    @if $type==sp {
        padding: 0 20px;

        &.wide {
            padding: 0;
        }

        &.col2 {
            >div {
                &:first-of-type {
                    margin-bottom: 40px;
                }
            }
        }
    }

    @if $type==pc {
        @include maxPC;

        &.narrow {
            max-width: 780px;
        }

        &.middle {
            max-width: 980px;
        }

        &.wide {
            max-width: none;
            width: 100%;
        }

        &.col2 {
            display: flex;

            >div {
                width: 50%;

                &:first-of-type {
                    padding-right: 4vw;
                }
            }
        }
    }
}

*+.articleList {
    @if $type==sp {
        margin-top: 30px;
    }

    @if $type==pc {
        margin-top: 40px;
    }
}

.articleList {
    @if $type==pc {
        display: flex;
        flex-wrap: wrap;
    }

    .articleListItem {
        @if $type=="" {}

        @if $type==sp {
            +.articleListItem {
                margin-top: 35px;
            }
        }

        @if $type==pc {
            width: calc((100% / 3) - 27px);

            &:not(:nth-of-type(3n + 1)) {
                margin-left: 40px;
            }

            &:nth-of-type(n + 4) {
                margin-top: 60px;
            }
        }

        figure {
            @if $type=="" {
                img {
                    width: 100%;
                }
            }

            @if $type==sp {}

            @if $type==pc {
                overflow: hidden;

                img {
                    transition: .3s transform $ttf;
                    //トリミング
                    // height: 15.9vw;
                    // object-fit: cover;
                    // max-height: 229px;
                }
            }
        }

        &>a {
            @if $type=="" {
                display: block;
                color: $c_base;
                text-decoration: none;
            }

            @if $type==sp {}

            @if $type==pc {
                &:hover {
                    img {
                        transform: scale(1.1);
                    }
                }
            }
        }

        .articleListTitle {
            @if $type=="" {
                color: $c_base;
                font-weight: bold;
            }

            @if $type==sp {
                margin-top: 10px;
                font-size: 14px;
            }

            @if $type==pc {
                margin-top: 10px;
                font-size: 16px;
            }
        }

        .articleListSub {
            @if $type=="" {
                color: $c_base;
            }

            @if $type==sp {
                font-size: 12px;
                margin-top: 15px;
            }

            @if $type==pc {
                font-size: 13px;
                margin-top: 15px;
            }
        }
    }
}

.articleNav {
    @if $type=="" {}

    @if $type==sp {
        display: flex;
        flex-wrap: wrap;
    }

    @if $type==pc {}

    a {
        @if $type=="" {
            color: $c_base;
            text-align: center;
            border: 1px solid $c_base;
            border-radius: 3px;
            text-decoration: none;
            font-weight: bold;

            &.current {
                background-color: $c_base;
                color: #fff;
            }
        }

        @if $type==sp {
            box-shadow: 1px 1px 0 0 $c_base;
            width: calc(50% - 10px);
            font-size: 12px;
            padding: 5px 10px;

            &:nth-of-type(even) {
                margin-left: 20px;
            }

            &:nth-of-type(n + 3) {
                margin-top: 15px;
            }
        }

        @if $type==pc {
            display: inline-block;
            padding: 5px 10px;
            min-width: 154px;
            box-shadow: 2px 2px 0 0 $c_base;
            margin-right: 20px;
            font-size: 14px;
            transition: .3s;

            &:hover {
                background-color: $c_base;
                color: #fff;
            }
        }
    }
}

.pager {
    @if $type==sp {
        margin-top: 40px;
    }

    @if $type==pc {
        margin-top: 60px;
    }

    ul {
        @if $type=="" {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            list-style-type: none;
        }

        li {
            @if $type=="" {
                @include fontEn;
                color: $c_base;
                line-height: 1;
                font-weight: bold;
            }

            @if $type==sp {
                font-size: 14px;
                margin: 0 7px;
            }

            @if $type==pc {
                font-size: 16px;
                margin: 0 10px;
            }

            a {
                @if $type=="" {
                    color: $c_base;
                    text-decoration: none;
                    padding: 8px 8px;
                    display: inline-block;
                    position: relative;
                    border-bottom: 1px solid #e0e0e0;
                    transition: .3s;
                }

                @if $type==pc {
                    &:hover {
                        border-bottom: 1px solid $c_base;
                    }
                }
            }
        }

        .current {
            @if $type=="" {
                color: $c_base;
                padding: 8px 8px;
                display: inline-block;
                position: relative;
                border-bottom: 1px solid $c_base;
            }
        }

        .prev,
        .next {
            position: relative;
            border: none;

            @if $type=="" {}

            @if $type==sp {
                font-size: 13px;
            }

            @if $type==pc {
                font-size: 15px;

                &:hover {
                    border: none;
                }
            }
        }

        .prev {
            @if $type=="" {
                &:before {
                    content: "";
                    position: absolute;
                    top: -2px;
                    bottom: 0;
                    width: 15px;
                    height: 13px;
                    left: 0px;
                    margin: auto;
                    background: url(../images/common/icon_arrow.svg) no-repeat;
                    transform: rotate(180deg);
                    background-size: contain;
                    transition: all .3s;
                }
            }

            @if $type==sp {
                margin-right: 5px;
                padding-left: 25px;
            }

            @if $type==pc {
                margin-right: 30px;
                padding-left: 25px;

                &:hover:before {
                    left: -5px;
                }
            }
        }

        .next {
            @if $type=="" {
                &:before {
                    content: "";
                    position: absolute;
                    top: -2px;
                    bottom: 0;
                    width: 15px;
                    height: 13px;
                    right: 0px;
                    margin: auto;
                    background: url(../images/common/icon_arrow.svg) no-repeat;
                    background-size: contain;
                    transition: all .3s;
                }
            }

            @if $type==sp {
                margin-left: 5px;
                padding-right: 25px;
            }

            @if $type==pc {
                margin-left: 30px;
                padding-right: 25px;

                &:hover:before {
                    right: -5px;
                }
            }
        }
    }
}

.prevNextLink {
    @if $type=="" {
        display: flex;
        justify-content: space-between;
        border-top: 1px solid $c_base;

        a {
            text-decoration: none;
            color: $c_base;
        }
    }

    @if $type==sp {
        margin-top: 40px;
        padding-top: 20px;
        font-size: 12px;
    }

    @if $type==pc {
        margin-top: 80px;
        padding-top: 30px;
    }

    .prev,
    .next {
        @if $type==sp {
            min-width: 95px;
        }

        @if $type==pc {
            min-width: 125px;
        }
    }

    .prev a {
        @if $type=="" {
            position: relative;
        }

        @if $type==sp {
            padding-left: 35px;

            &:before {
                content: "";
                display: inline-block;
                background: url(../images/common/icon_arrow_more_left.svg) no-repeat;
                width: 20px;
                height: 20px;
                background-size: cover;
                transition: .3s background $ttf;
                position: absolute;
                top: 0;
                left: 0;
                transform: rotate(180deg);
            }
        }

        @if $type==pc {
            padding-left: 45px;

            &:before {
                content: "";
                display: inline-block;
                background: url(../images/common/icon_arrow_more_left.svg) no-repeat;
                width: 24px;
                height: 24px;
                background-size: cover;
                transition: .3s background $ttf;
                position: absolute;
                top: 0;
                left: 0;
                transform: rotate(180deg);
            }

            &:hover {
                &:before {
                    background: url(../images/common/icon_arrow_more_left_hov.svg) no-repeat;
                    background-size: cover;
                }
            }
        }
    }

    .ichiran {
        @if $type==pc {
            &:hover {
                text-decoration: underline;
            }
        }
    }

    .next a {
        @if $type=="" {
            position: relative;
        }

        @if $type==sp {
            padding-right: 35px;

            &:before {
                content: "";
                display: inline-block;
                background: url(../images/common/icon_arrow_more.svg) no-repeat;
                width: 20px;
                height: 20px;
                background-size: cover;
                transition: .3s background $ttf;
                position: absolute;
                top: 0;
                right: 0;
            }
        }

        @if $type==pc {
            padding-right: 45px;

            &:before {
                content: "";
                display: inline-block;
                background: url(../images/common/icon_arrow_more.svg) no-repeat;
                width: 24px;
                height: 24px;
                background-size: cover;
                transition: .3s background $ttf;
                position: absolute;
                top: 0;
                right: 0;
            }

            &:hover {
                &:before {
                    background: url(../images/common/icon_arrow_more_hov.svg) no-repeat;
                    background-size: cover;
                }
            }
        }
    }
}

.whiteBox {
    @if $type=="" {
        background-color: #fff;
        box-shadow: 6px 5px 12px 2px #cccccc, -8px -9px 11px 5px #ffffff;
    }

    @if $type==sp {
        padding: 40px 20px;
    }

    @if $type==pc {
        padding: 60px;
    }
}

.formWrap {
    p[class*="is-error"] {
        @if $type=="" {
            margin-top: 10px;
            margin-bottom: 0;
        }
    }

    .is-error {
        @if $type=="" {
            color: #ff0000;
            font-weight: bold;
        }

        @if $type==sp {
            font-size: 14px;
        }

        @if $type==pc {
            font-size: 15px;
        }
    }

    input[type=text],
    input[type=password],
    input[type=tel],
    input[type=email],
    select {
        @if $type=="" {
            width: 100%;
            border: 1px solid #d0d0d0;
            border-radius: 3px;
            padding: 5px 15px;

            &::placeholder {
                color: #d0d0d0;
            }
        }

        &.is-error {
            @if $type=="" {
                font-weight: normal;
            }
        }
    }

    //check box
    input[type="checkbox"] {
        display: none;
    }

    input[type="checkbox"]+label {
        position: relative;
        display: inline-block;
        padding: 3px 3px 3px 25px;
        cursor: pointer;
        -webkit-transition: all .2s;
        transition: all .2s;

        @if $type==sp {
            margin-bottom: 5px;
        }

    }

    input[type="checkbox"]+label::before,
    input[type="checkbox"]+label::after {
        position: absolute;
        content: '';
        -webkit-transition: all .2s;
        transition: all .2s;
    }

    input[type="checkbox"]+label::before {
        top: 50%;
        left: 0;
        width: 16px;
        height: 16px;
        margin-top: -8px;
        background: #fff;
        border: 1px solid #ccc;
        border-radius: 3px;
    }

    input[type="checkbox"]+label::after {
        opacity: 0;
        top: 50%;
        left: 3px;
        width: 10px;
        height: 5px;
        margin-top: -4px;
        border-left: 2px solid $c_base;
        border-bottom: 2px solid $c_base;
        -webkit-transform: rotate(-45deg) scale(.5);
        transform: rotate(-45deg) scale(.5);
    }

    input[type="checkbox"]+label:hover::before {
        background: #fff;
    }

    input[type="checkbox"]:checked+label::before {
        background: #fff;
        border: 1px solid $c_base;
        ;
    }

    input[type="checkbox"]:checked+label::after {
        opacity: 1;
        -webkit-transform: rotate(-45deg) scale(1);
        transform: rotate(-45deg) scale(1);
    }

    input[type="submit"].commonBtn {
        background-image: url(../images/common/icon_arrow_white.svg);
        background-repeat: no-repeat;
        background-position: center right 24px;
    }

    dl {
        @if $type==sp {
            +dl {
                margin-top: 20px;
            }
        }

        @if $type==pc {
            +dl {
                margin-top: 25px;
            }
        }

        dt {}

        dd {
            @if $type=="" {
                margin-top: 15px;
            }
        }
    }

    .hissu {
        @if $type=="" {
            color: #fff;
            background-color: $c_orange;
            display: inline-block;
            line-height: 1;
            padding: 3px 8px;
            margin-left: 8px;
            transform: translateY(-2px);
        }

        @if $type==sp {
            font-size: 10px;
        }

        @if $type==pc {
            font-size: 11px;
        }
    }

    br {
        display: none;
    }

    br+label,
    label+input {
        @if $type=="" {
            display: block;
            margin-top: 15px;
        }
    }

    input[type="submit"] {
        @if $type=="" {
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 3px;

            background-color: #f07800;
            color: #fff;
            border: 1px solid #e8651a;
            box-shadow: 2px 2px 0 0 #e8651a;
            margin-left: auto;
            margin-right: auto;
            margin: 0 auto;
            font-weight: bold;
            position: relative;
            text-decoration: none;
            text-align: center;
            transition: .3s background, .3s color, .3s box-shadow $ttf;
        }

        @if $type==sp {
            margin-top: 30px;
            padding: 12px 40px;
            width: 72%;
        }

        @if $type==pc {
            margin-top: 40px;
            width: 288px;
            padding: 14px 40px;
            line-height: 1.2;
            transition: all .3s;

            &:not(:disabled) {
                cursor: pointer;

                &:hover {
                    background-color: $c_orange;
                    color: #fff;

                    &::before {
                        background-image: url(../images/common/icon_arrow_white.svg);
                    }
                }
            }
        }

        &::before {
            @if $type=="" {
                content: "";
                position: absolute;
                top: 0;
                bottom: 0;
                width: 15px;
                height: 13px;
                right: 16px;
                margin: auto;
                background: url(../images/common/icon_arrow.svg) no-repeat;
                background-image: url(../images/common/icon_arrow_white.svg);
                background-size: contain;
                transition: all .3s;
            }
        }

        &:disabled {
            @if $type=="" {
                background-color: #d8d8d8;
                border-color: #b1b1b1;
                box-shadow: 2px 2px 0 0 #b1b1b1;
                color: #939395;
            }
        }
    }
}

.featureList {
    @if $type=="" {}

    @if $type==sp {
        margin-top: 60px;
    }

    @if $type==pc {
        margin-top: 80px;
    }

    // &.featureListNarrow{
    //     @if $type == sp {

    //     }
    //     @if $type == pc {
    //         max-width: 1180px;
    //         margin-left: auto;
    //         margin-right: auto;
    //         margin-bottom: 80px;
    //     }
    //     .featureListItem {
    //         .featureTextWrap{
    //             @if $type == pc {
    //                 padding: 100px 60px;
    //             }
    //         }
    //     }
    // }
    .featureListItem {
        @if $type=="" {}

        @if $type==sp {
            &:nth-of-type(odd) {
                .featureTextWrap {
                    color: #fff;
                    background-color: rgba(23, 24, 75, .95);

                    .subTitle:before {
                        background-color: #fff;
                    }
                }

                ul.commonList li:before {
                    background-color: #fff;
                }
            }

            &:nth-of-type(even) {
                .featureTextWrap {
                    background-color: rgba(255, 255, 255, .95);

                    .subTitle:before {
                        background-color: $c_base;
                    }
                }
            }
        }

        @if $type==pc {
            display: flex;
            min-height: 564px;

            &.feature1 {
                background: url(../images/second/feature_img_1.png) no-repeat center left;
                background-size: cover;
            }

            &.feature2 {
                background: url(../images/second/feature_img_2.png) no-repeat center right;
                background-size: cover;
            }

            &.feature3 {
                background: url(../images/second/feature_img_3.png) no-repeat center left;
                background-size: cover;
            }

            &.feature4 {
                background: url(../images/second/feature_img_4.png) no-repeat center right;
                background-size: cover;
            }

            &:nth-of-type(odd) {
                .featureTextWrap {
                    color: #fff;
                    background-color: rgba(23, 24, 75, .95);
                    margin: 0 0 0 auto;

                    .subTitle:before {
                        background-color: #fff;
                    }
                }

                ul.commonList li:before {
                    background-color: #fff;
                }
            }

            &:nth-of-type(even) {
                .featureTextWrap {
                    background-color: rgba(255, 255, 255, .95);
                    margin: 0 auto 0 0;

                    .subTitle:before {
                        background-color: $c_base;
                    }
                }
            }
        }

        figure {
            @if $type=="" {}

            @if $type==sp {
                margin-bottom: 0;
            }

            @if $type==pc {
                width: 50%;
                display: none;
            }
        }

        .featureTextWrap {
            @if $type=="" {
                position: relative;
                z-index: 2;
            }

            @if $type==sp {
                padding: 35px 20px;
            }

            @if $type==pc {
                width: 50%;
                // padding: 100px;
                padding: 4vw 8vw;
            }

            .subTitle {
                @if $type=="" {
                    @include fontEn;
                    letter-spacing: .1em;
                    position: relative;
                }

                @if $type==sp {
                    padding-left: 35px;

                    &:before {
                        content: "";
                        display: inline-block;
                        width: 24px;
                        height: 1px;
                        position: absolute;
                        top: 50%;
                        left: 0;
                    }
                }

                @if $type==pc {
                    padding-left: 50px;

                    &:before {
                        content: "";
                        display: inline-block;
                        width: 33px;
                        height: 1px;
                        position: absolute;
                        top: 50%;
                        left: 0;
                    }
                }
            }

            h2 {
                @if $type=="" {
                    font-weight: bold;
                }

                @if $type==sp {
                    font-size: 19px;
                    margin-top: 10px;
                }

                @if $type==pc {
                    font-size: 30px;
                    margin-top: 10px;
                }
            }

            p {
                @if $type=="" {}

                @if $type==sp {}

                @if $type==pc {}
            }
        }
    }
}

#caseStudy {
    @if $type=="" {}

    @if $type==sp {
        .slideList {
            width: calc(100% + 40px);
            margin-left: -20px;
        }
    }

    @if $type==pc {
        .read {
            margin-top: 40px;
            text-align: center;
        }
    }

    h2 {
        @if $type=="" {
            text-align: center;
        }

        @if $type==sp {
            font-size: 23px;
        }

        @if $type==pc {
            font-size: 34px;
        }
    }

    .btnWrap {
        @if $type==sp {
            margin-top: 40px;
        }

        @if $type==pc {}
    }
}

#recommend {
    @if $type=="" {
        margin: 0;
    }

    @if $type==sp {
        padding: 60px 0;

        .slideList {
            width: calc(100% + 40px);
            margin-left: -20px;
        }
    }

    @if $type==pc {
        padding: 80px 0;

        .read {
            margin-top: 40px;
            text-align: center;
        }
    }

    h2 {
        @if $type=="" {
            text-align: center;
        }

        @if $type==sp {
            font-size: 23px;
        }

        @if $type==pc {
            font-size: 34px;
        }
    }

    .btnWrap {
        @if $type==sp {
            margin-top: 40px;
        }

        @if $type==pc {}
    }

    &.faqTop {
        background-color: #f8f8f8;

        @if $type==sp {
            padding: 45px 0 50px !important;
        }

        @if $type==pc {
            padding: 90px 0 100px !important;
        }
    }
}

.solarAbout {
    @if $type=="" {
        color: #fff;
        background: url(../images/second/solar_bg.png) no-repeat center;
        background-size: cover;
        width: 100%;
        margin-top: 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: center;
    }

    @if $type==sp {
        min-height: 370px;
    }

    @if $type==pc {
        min-height: 371px;
    }

    h2 {
        @if $type=="" {
            font-weight: bold;
        }

        @if $type==sp {
            font-size: 23px;

            +p {
                font-size: 13px;
                margin-top: 20px;
                font-weight: bold;
                line-height: 2;
            }
        }

        @if $type==pc {
            font-size: 34px;

            +p {
                font-size: 16px;
                margin-top: 40px;
                font-weight: bold;
                line-height: 2;
            }
        }
    }

    .fvBtnList {
        @if $type==sp {
            margin-top: 30px;
        }

        @if $type==pc {
            margin-top: 40px;
            display: flex;
            justify-content: center;
        }

        li {
            @if $type=="" {
                position: relative;
            }

            @if $type==sp {
                +li {
                    margin-top: 20px;
                }
            }

            @if $type==pc {
                display: inline-block;

                +li {
                    margin-left: 20px;
                }
            }

            a {
                @if $type=="" {
                    text-decoration: none;
                    color: #fff;
                    padding: 0;
                    display: block;
                    font-weight: bold;
                    text-align: center;
                    border-radius: 3px;
                }

                @if $type==sp {
                    font-size: 14px;
                    width: 270px;
                    margin: auto;
                    padding: 10px 10px;
                }

                @if $type==pc {
                    font-size: 16px;
                    width: 250px;
                    padding: 10px 10px;
                }

                span {
                    @if $type=="" {
                        position: relative;
                    }
                }

                .icon {
                    @if $type=="" {
                        margin-right: 25px;
                        display: inline-block;

                        &::before {
                            content: "";
                            background-repeat: no-repeat;
                            background-size: contain;
                            display: block;
                            position: absolute;
                            top: 50%;
                            left: 0;
                            transform: translateY(-50%);
                        }
                    }

                    &.iconMaterial {
                        &::before {
                            @if $type=="" {
                                background-image: url(../images/common/icon_document_white.svg);
                            }

                            @if $type==sp {
                                width: 14px;
                                height: 18px;
                            }

                            @if $type==pc {
                                width: 16px;
                                height: 21px;
                            }
                        }
                    }

                    &.iconMail {
                        @if $type==sp {}

                        @if $type==pc {
                            margin-right: 35px;
                        }

                        &::before {
                            background-image: url(../images/common/icon_mail_white.svg);

                            @if $type==sp {
                                width: 18px;
                                height: 12px;
                            }

                            @if $type==pc {
                                width: 22px;
                                height: 17px;
                            }
                        }
                    }
                }
            }

            &.btnMaterial {
                a {
                    @if $type=="" {
                        background-color: $c_orange;
                        border: 1px solid #fff;
                        transition: .3s background, .3s color $ttf;
                    }

                    @if $type==pc {
                        &:hover {
                            background-color: #fff;
                            color: $c_orange;
                            border-color: $c_orange;

                            .iconMaterial::before {
                                background-image: url(../images/common/icon_document_orange.svg);
                            }
                        }
                    }
                }
            }

            &.btnContact {
                a {
                    @if $type=="" {
                        background-color: $c_purple;
                        border: 1px solid #fff;
                        color: #fff;
                        transition: .3s background, .3s color $ttf;
                    }

                    @if $type==pc {
                        &:hover {
                            background-color: #fff;
                            border-color: $c_purple;
                            color: $c_purple;

                            .iconMail::before {
                                background-image: url(../images/common/icon_mail.svg);
                            }
                        }
                    }
                }
            }

            &.btnBlue {
                a {
                    @if $type=="" {
                        background-color: $c_purple;
                        border: 1px solid #fff;
                        color: #fff;
                        transition: .3s background, .3s color $ttf;
                        position: relative;

                        &:before {
                            content: "";
                            position: absolute;
                            top: 2px;
                            bottom: 0;
                            width: 15px;
                            height: 13px;
                            right: 10px;
                            margin: auto;
                            background: url(../images/common/icon_arrow_white.svg) no-repeat;
                            background-size: contain;
                            transition: all .3s;
                        }
                    }

                    @if $type==pc {
                        &:hover {
                            background-color: #fff;
                            border-color: $c_purple;
                            color: $c_purple;

                            &:before {
                                background: url(../images/common/icon_arrow.svg) no-repeat;
                                background-size: contain;
                            }
                        }
                    }
                }
            }
        }
    }
}

.meritWrap {
    @if $type=="" {}

    @if $type==sp {}

    @if $type==pc {}

    h3 {
        @if $type=="" {
            border-bottom: 1px solid $c_base;
            position: relative;
        }

        @if $type==sp {
            font-size: 19px;
            margin-top: 40px;
            padding: 0 0 15px 35px;
        }

        @if $type==pc {
            font-size: 26px;
            margin-top: 50px;
            padding: 0 0 20px 50px;
        }

        .meritNum {
            @if $type=="" {
                @include fontEn;
                position: absolute;
                line-height: 1;
                font-weight: 500;
                top: -3px;
                left: 0;
            }

            @if $type==sp {
                font-size: 10px;
            }

            @if $type==pc {
                font-size: 14px;
            }

            span {
                @if $type=="" {
                    display: block;
                }

                @if $type==sp {
                    font-size: 19px;
                }

                @if $type==pc {
                    font-size: 28px;
                }
            }
        }
    }

    .meritCont {
        @if $type=="" {}

        @if $type==sp {
            margin-top: 20px;
        }

        @if $type==pc {
            margin-top: 30px;
            display: flex;
        }

        figure {
            @if $type=="" {
                text-align: center;
            }

            @if $type==sp {
                img {
                    width: 125px;
                }
            }

            @if $type==pc {
                width: 245px;

                img {
                    margin-top: -15px;
                }
            }
        }

        .textWrap {
            @if $type=="" {}

            @if $type==sp {
                *+h4 {
                    margin-top: 25px;
                }
            }

            @if $type==pc {
                width: calc(100% - 245px);
                padding-left: 30px;

                *+h4 {
                    margin-top: 20px;
                }
            }

            h4 {
                @if $type=="" {
                    font-weight: bold;
                    position: relative;
                }

                @if $type==sp {
                    font-size: 14px;
                    padding-left: 20px;

                    &:before {
                        content: "";
                        display: inline-block;
                        width: 15px;
                        height: 2px;
                        background-color: $c_base;
                        position: absolute;
                        top: calc(50% - 1px);
                        left: 0;
                    }
                }

                @if $type==pc {
                    font-size: 16px;
                    padding-left: 30px;

                    &:before {
                        content: "";
                        display: inline-block;
                        width: 22px;
                        height: 2px;
                        background-color: $c_base;
                        position: absolute;
                        top: calc(50% - 1px);
                        left: 0;
                    }
                }
            }

            p {
                @if $type=="" {}

                @if $type==sp {
                    margin-top: 10px;
                }

                @if $type==pc {
                    margin-top: 15px;
                }
            }
        }
    }
}

.teianH2 {
    @if $type=="" {
        background-color: $c_base;
        color: #fff;
    }

    @if $type==sp {
        padding: 30px 20px;
        margin-top: 60px;
    }

    @if $type==pc {
        padding: 60px 0;
        margin-top: 80px;
    }
}

.teianSystem {
    @if $type=="" {}

    @if $type==sp {
        margin-top: 40px;
    }

    @if $type==pc {
        margin-top: 70px;
    }

    .inner {
        @if $type=="" {}

        @if $type==sp {}

        @if $type==pc {
            display: flex;
            justify-content: space-between;
        }
    }

    .teianSystemItem {
        @if $type=="" {
            box-shadow: 6px 5px 12px 2px #cccccc, -8px -9px 11px 5px #ffffff;
        }

        @if $type==sp {
            border-radius: 5px;

            +.teianSystemItem {
                margin-top: 20px;
            }
        }

        @if $type==pc {
            width: calc((100% / 3) - 14px);
            border-radius: 10px;
        }

        h3 {
            @if $type=="" {
                text-align: center;
                background-color: #f8f8f8;
            }

            @if $type==sp {
                border-radius: 5px 5px 0 0;
                padding: 10px 10px;
                font-size: 16px;
            }

            @if $type==pc {
                border-radius: 10px 10px 0 0;
                padding: 20px 20px;
                font-size: 20px;
            }

            span {
                @if $type=="" {}

                @if $type==sp {
                    font-size: 20px;
                }

                @if $type==pc {
                    font-size: 26px;
                }
            }
        }

        .teianSystemCont {
            @if $type=="" {
                background-color: #fff;
                text-align: center;
            }

            @if $type==sp {
                padding: 10px 10px;
                border-radius: 0 0 5px 5px;
            }

            @if $type==pc {
                padding: 20px 20px;
                border-radius: 0 0 10px 10px;
            }
        }
    }
}

.btnCol {
    @if $type==sp {
        .commonBtn {
            margin: 0 auto;
        }

        .commonBtn+.commonBtn {
            margin-top: 20px;
        }
    }

    @if $type==pc {
        display: flex;
        justify-content: center;

        .commonBtn+.commonBtn {
            margin-left: 20px;
        }
    }
}

.sindanImg {
    @if $type==sp {
        text-align: center;
        margin-top: 30px;
    }

    @if $type==pc {
        text-align: center;
        margin-top: 60px;
    }
}

//================================================
// 前データ　←WPへの記事移行とcss詳細度の関係で#contents内に入れました
//================================================
#contents {
    .gmap {
        @if $type==sp {
            margin-top: 20px;
        }

        @if $type==pc {
            margin-top: 30px;
        }

        iframe {
            @if $type=="" {
                width: 100%;
            }
        }
    }

    h2,
    h3,
    h4 {
        &+.commonImgBox {
            @if $type==sp {
                margin: 20px 0 40px;
            }

            @if $type==pc {
                margin: 30px 0 40px;
            }
        }
    }

    .commonImgBox {
        @if $type=="" {
            display: flex;
        }

        @if $type==sp {
            flex-direction: column-reverse;
            margin: 30px 0 40px;
        }

        @if $type==pc {
            align-items: flex-start;
            justify-content: space-between;
            flex-flow: nowrap;
            margin: 70px 0 40px;
        }

        .txt {
            @if $type==sp {
                margin-top: 30px;

            }

            @if $type==pc {
                width: 70%;
            }
        }

        .img {
            @if $type==sp {
                text-align: center;
            }

            @if $type==pc {
                width: 30%;
                margin-left: 60px;
            }

            img {
                @if $type==sp {
                    width: 100%;
                    max-width: 400px;
                    margin: auto;
                }

                @if $type==pc {
                    width: 100%;
                }
            }
        }

        @if $type==sp {
            margin: 0 0 40px;

            &.imgBtm {
                flex-direction: column;

                .img {
                    margin-top: 30px;
                }
            }
        }

        &.imgLeft {

            @if $type==pc {
                flex-direction: row-reverse;
            }

            .img {
                @if $type==pc {
                    margin-left: 0;
                    margin-right: 60px;
                }
            }
        }

        &.imgBig {
            @if $type==pc {
                align-items: center;
            }

            .img {
                @if $type==pc {
                    width: 70%;
                }

                .txt {
                    @if $type==pc {
                        width: 30%;
                    }
                }
            }
        }

        &.imgRight {
            @if $type==pc {
                flex-direction: row;
            }

            .img {
                @if $type==pc {
                    margin-left: 60px;
                }
            }
        }
    }

    .cardListWrap {
        .cardList {
            @if $type=="" {
                display: flex;
                justify-content: space-between;
                flex-flow: wrap;
            }

            @if $type==sp {
                max-width: 600px;
                margin: auto;
                position: relative;
                z-index: 2;
            }

            @if $type==pc {}
        }

        li {
            @if $type=="" {
                border: 1px solid #aaaaaa;
                background-color: #fff;
            }

            @if $type==sp {
                width: 100%;
                overflow: hidden;

                &+li {
                    margin-top: 20px;
                }
            }

            @if $type==pc {
                padding: 20px;
                width: calc(50% - 10px);

                &:nth-child(n + 3) {
                    margin-top: 20px;
                }
            }
        }

        a {
            @if $type=="" {
                display: flex;
                flex-flow: nowrap;
                background-color: #fff;
                text-decoration: none;
            }

            @if $type==sp {
                height: 37vw;
            }

            @if $type==pc {
                &:hover {
                    .viewmore::before {
                        transform: translateY(-50%)scaleX(6);
                    }
                }
            }
        }

        .img {
            @if $type==sp {
                position: relative;
                width: 45%;
            }

            @if $type==pc {
                width: 214px;
                margin-right: 10px;
            }

            @if $type==tb {
                width: 160px;
            }
        }

        img {
            @if $type==sp {
                max-width: 100%;
                width: 100%;
                transition: 0.3s ease-in-out;
                border-radius: 5px 0 0 5px;
                position: absolute;
                top: 50%;
                left: 50%;
                -webkit-transform: translate(-50%, -50%);
                transform: translate(-50%, -50%);
            }

            @if $type==pc {
                width: 100%;
            }
        }

        .txt {
            @if $type=="" {
                position: relative;
            }

            @if $type==sp {
                width: 55%;
                text-align: center;
            }

            @if $type==pc {
                // width: 50%;
                width: calc(100% - 214px - 10px);
            }

            @if $type==tb {
                width: calc(100% - 160px - 10px);
            }

            .ttl {
                @if $type=="" {
                    color: #151515;
                    font-size: 17px;
                    width: 100%;
                }

                @if $type==sp {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    height: 100%;
                    font-size: 17px;
                    padding: 0 4px;
                }

                @if $type==pc {
                    font-size: 20px;
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    text-align: center;
                }
            }

            .viewmore {
                @if $type=="" {
                    color: #192f60;
                    display: inline-block;
                    @include fontEn;
                    font-weight: 500;
                    text-align: right;
                }

                @if $type==sp {
                    font-size: 11px;
                    position: absolute;
                    right: 8px;
                    bottom: 8px;
                    z-index: 2;
                }

                @if $type==pc {
                    font-size: 16px;
                    position: absolute;
                    bottom: 0;
                    right: 0;
                }

                &::before {
                    @if $type=="" {
                        content: "";
                        display: block;
                        background: #192f60;
                        position: absolute;
                        z-index: 2;
                        transform-origin: right;
                        transform: translateY(-50%)scaleX(1);
                        transition: .3s all $ttf;
                    }

                    @if $type==sp {
                        width: 18px;
                        height: 1px;
                        top: 50%;
                        right: 60px;
                    }

                    @if $type==pc {
                        width: 18px;
                        height: 1px;
                        top: 50%;
                        left: -30px;
                    }
                }
            }
        }
    }

    .leadTtl {
        @if $type=="" {
            border-bottom: none;
            text-align: center;
        }

        @if $type==sp {
            font-size: 25px;
            margin: 40px 0 24px;
        }

        @if $type==pc {
            font-size: 34px;
            line-height: 1.5;
            margin: 30px 0 50px;
        }
    }

    .mainTtl1 {
        @if $type=="" {
            font-weight: bold;
            text-align: center;
        }

        @if $type==sp {
            font-size: 25px;
            margin: 36px 0 20px;
        }

        @if $type==pc {
            font-size: 30px;
            margin: 50px 0 30px;
        }
    }

    .subTtl {
        @if $type=="" {
            display: block;
            color: $c_orange;
            @include fontEn;
            letter-spacing: 1px;
        }

        @if $type==sp {
            font-size: 15px;
        }

        @if $type==pc {
            font-size: 18px;
        }
    }

    .rinenListWrap {
        @if $type==sp {
            margin: 0 0 60px;
        }

        @if $type==pc {
            display: flex;
            justify-content: space-between;
            margin: 40px 0 70px;
        }

        .rinenBorder {
            @if $type=="" {
                width: 100%;
            }

            @if $type==sp {
                border-bottom: 1px solid $c_purple;
            }

            @if $type==pc {
                border-left: 1px solid $c_purple;

                &:last-child {
                    border-right: 1px solid $c_purple;
                }
            }
        }

        .rinenList {
            @if $type=="" {
                width: 100%;
            }

            @if $type==sp {
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding: 10px 0;
                max-width: 330px;
                margin: auto;
                padding: 10px 0;
                position: relative;
            }

            @if $type==pc {
                text-align: center;
            }

            dt {
                @if $type=="" {
                    color: $c_orange;
                    font-weight: bold;
                    font-size: 24px;
                }

                @if $type==sp {
                    margin-left: 30px;
                    width: 30%;
                }

                @if $type==pc {
                    display: flex;
                    justify-content: center;
                }

                img {
                    @if $type==sp {
                        width: 42px;
                        height: auto;
                        vertical-align: middle;
                    }

                    @if $type==pc {
                        width: 48px;
                        height: auto;
                    }
                }
            }

            dd {
                @if $type==sp {
                    font-size: 14px;
                    line-height: 2;
                    width: 70%;
                }

                @if $type==pc {
                    font-size: 16px;
                    line-height: 2;
                    margin-top: 36px;
                }
            }
        }

        &.txtListWrap {
            .rinenList {
                @if $type==sp {
                    display: block;
                    padding: 10px 0;
                    text-align: center;
                }

                @if $type==pc {
                    padding-bottom: 30px;
                }

                dt {
                    @if $type==sp {
                        font-size: 18px;
                        width: 100%;
                        margin-left: 0;
                    }
                }

                dd {
                    @if $type==sp {
                        font-size: 16px;
                        width: 100%;
                    }

                    @if $type==pc {
                        font-size: 18px;
                        margin-top: 24px;
                    }
                }
            }

        }
    }

    .subTtl+.mainTtl1 {
        @if $type=="" {
            margin-top: 0;
        }
    }
}

.main_solar,
.main_solar-test {
    #contents {
        @if $type=="" {
            padding-top: 0;
        }
    }
}


//================================================
// 採用情報
//================================================
.numberWrap {
    .subTtl {
        @if $type=="" {
            color: $c_purple;
            font-weight: 500;
            text-transform: capitalize;
        }

        @if $type==sp {
            font-size: 13px;
        }

        @if $type==pc {
            font-size: 15px;
        }
    }
}

//================================================
//お客様の声
//================================================

.voiceList {
    @if $type==sp {}

    @if $type==pc {}

    a.voiceCont {
        @if $type=="" {
            display: block;
            text-decoration: none;
            color: $c_base;
        }

        @if $type==sp {
            max-width: 600px;
            margin: 0 auto 50px;
        }

        @if $type==pc {
            margin-top: 80px;
            display: flex;
            justify-content: space-between;
            gap: 0 20px;

            &:hover {
                .voiceImg img {
                    transform: scale(1.1);
                }

                .btnWrap::after {
                    right: 2px;
                }

                .viewMore::after {
                    transform: scale(1, 1);
                }
            }
        }

        &:last-of-type {
            @if $type==sp {
                margin-bottom: 40px;
            }
        }

        &:nth-of-type(even) {
            @if $type==pc {
                flex-direction: row-reverse;
            }
        }

        .descWrap {
            @if $type==pc {
                width: 50%;
            }
        }

        .voiceSubTitle {
            @if $type=="" {
                color: $c_orange;
                font-weight: 500;
                letter-spacing: 0.05rem;
            }

            @if $type==sp {
                text-align: center;
                font-size: 12px;
                margin-bottom: 15px;
            }

            @if $type==pc {
                font-size: 14px;
                margin-bottom: 20px;
            }

            .number {
                @include fontEn;
                font-weight: 600;
            }

            .cut {
                @if $type==sp {
                    margin: 0 8px;
                }

                @if $type==pc {
                    margin: 0 10px;
                }
            }
        }

        h3 {
            @if $type==sp {
                font-size: 17px;
                line-height: 1.59;
                margin-bottom: 15px;
            }

            @if $type==pc {
                font-size: 29px;
                line-height: 1.72;
                margin-bottom: 20px;
            }
        }

        .desc {
            @if $type==sp {
                line-height: 1.86;
                margin-bottom: 20px;
            }

            @if $type==pc {
                line-height: 2;
                margin-bottom: 25px;
            }
        }

        .voiceImg {
            @if $type=="" {
                overflow: hidden;
            }

            @if $type==sp {
                margin-bottom: 20px;
                box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.15);
            }

            @if $type==pc {
                // width: 515px;
                width: 43.7%;
                height: 100%;
                margin-top: 14px;
                box-shadow: 0px 5px 20px 0px rgba(0, 0, 0, 0.15);
            }

            img {
                object-fit: cover;
                transition: .3s transform $ttf;
            }

        }

        .btnWrap {
            @if $type=="" {
                position: relative;
                margin: 0;
                text-align: right;
            }

            &::after {
                @if $type=="" {
                    content: "";
                    display: block;
                    background: url(../images/common/icon_arrow_purple.svg) no-repeat center / 100%;
                    position: absolute;
                }

                @if $type==sp {
                    width: 11px;
                    height: 9px;
                    top: 8px;
                    right: 2.5px;
                }

                @if $type==pc {
                    width: 15px;
                    height: 13px;
                    top: 9px;
                    right: 7px;
                    transition: .3s;
                }
            }
        }

        .viewMore {
            @if $type=="" {
                position: relative;
                display: inline-block;
                @include fontEn;
                font-weight: 600;
            }

            @if $type==sp {
                font-size: 13px;
                padding-right: 24px;
            }

            @if $type==pc {
                font-size: 14px;
                padding-right: 40px;
            }

            &::before {
                @if $type=="" {
                    content: "";
                    display: block;
                    background-color: $c_border;
                    width: 100%;
                    height: 1px;
                    position: absolute;
                    left: 0;
                    bottom: -3px;
                }
            }

            &::after {
                @if $type==sp {}

                @if $type==pc {
                    content: "";
                    display: block;
                    background-color: $c_base;
                    width: 100%;
                    height: 1px;
                    position: absolute;
                    left: 0;
                    bottom: -3px;
                    transform: scale(0, 1);
                    transform-origin: left top;
                    transition: .3s;
                }
            }
        }
    }
}



//================================================
// お問い合わせ&a-form
//================================================
.main_contact {
    .headDeco {
        display: none;
    }
}

.aform {

    input[type=text],
    input[type=password],
    input[type=tel],
    input[type=email],
    select,
    textarea {
        @if $type==sp {
            border: 1px solid #a4a4a4;
            border-radius: 4px;
            padding: 10px;
            width: 100%;
            background-color: #fff;
        }

        @if $type==pc {
            border: 1px solid #a4a4a4;
            border-radius: 4px;
            padding: 10px;
            width: 100%;
            background-color: #fff;
        }

        &.error {
            background-color: #fff1f2;
        }
    }

    select {
        @if $type==sp {}

        @if $type==pc {}
    }

    input[type=text],
    input[type=password],
    input[type=tel],
    input[type=email],
    select {
        &.size-s {
            @if $type==sp {
                width: 22%;
            }

            @if $type==pc {
                width: 12%;
            }
        }

        &.size-sm {
            @if $type==sp {
                width: 20%;
            }

            @if $type==pc {
                width: 15%;
            }
        }

        &.size-m {
            @if $type==sp {
                width: 27%;
            }

            @if $type==pc {
                width: 35%;
            }
        }

        &.size-ml {
            @if $type==sp {
                width: 50%;
            }

            @if $type==pc {
                width: 43%;
            }
        }

        &.size-l {
            @if $type==sp {
                width: 75%;
            }

            @if $type==pc {
                width: 65%;
            }
        }

        &.size-special {
            @if $type==sp {
                width: 85%;
                margin-bottom: 10px;
            }

            @if $type==pc {
                width: 43%;
            }
        }

        &.year {
            @if $type==sp {
                width: 23%;
                margin-bottom: 10px;
            }

            @if $type==pc {
                width: 80px;
            }
        }

        &.month {
            @if $type==sp {
                width: 17%;
                margin-bottom: 10px;
            }

            @if $type==pc {
                width: 70px;
            }
        }
    }

    select {
        &.year {
            @if $type==sp {
                width: 23%;
                margin-bottom: 10px;
            }

            @if $type==pc {
                width: 80px;
            }
        }

        &.month {
            @if $type==sp {
                width: 17%;
                margin-bottom: 10px;
            }

            @if $type==pc {
                width: 70px;
            }
        }
    }

    button {
        @if $type==sp {
            border: 1px solid #ccc;
            padding: 4px 8px;
            background-color: #f6f6f6;
            box-shadow: 0px 1px 1px 0px #cccccc;
            font-size: 14px;

            &:hover {
                opacity: .7;
                cursor: pointer;
            }
        }

        @if $type==pc {
            border: 1px solid #ccc;
            padding: 4px 8px;
            background-color: #f6f6f6;
            box-shadow: 0px 1px 1px 0px #cccccc;
            font-size: 14px;

            &:hover {
                opacity: .7;
                cursor: pointer;
            }
        }
    }

    //radio button
    input[type="radio"] {
        display: none;
    }

    input[type="radio"]+label {
        position: relative;
        display: inline-block;
        padding: 3px 3px 3px 25px;
        cursor: pointer;
        margin-right: 20px;

        @if $type==sp {
            margin-bottom: 5px;
        }
    }

    input[type="radio"]+label::before,
    input[type="radio"]+label::after {
        position: absolute;
        content: '';
        top: 50%;
        border-radius: 100%;
        -webkit-transition: all .2s;
        transition: all .2s;
    }

    input[type="radio"]+label::before {
        left: 0;
        width: 17px;
        height: 17px;
        margin-top: -10px;
        background: #fff;
        border: 1px solid #ccc;
    }

    input[type="radio"]+label:hover::before {
        background: #fff;
    }

    input[type="radio"]+label::after {
        opacity: 0;
        left: 4px;
        width: 9px;
        height: 9px;
        margin-top: -6px;
        background: #5dbf5d;
        -webkit-transform: scale(2);
        transform: scale(2);
    }

    input[type="radio"]:checked+label::before {
        background: #fff;
        border: 1px solid #5dbf5d;
    }

    input[type="radio"]:checked+label::after {
        opacity: 1;
        -webkit-transform: scale(1);
        transform: scale(1);
    }

    //check box
    input[type="checkbox"] {
        //        display: none;
        opacity: 0;
        width: 1px;
        height: 1px;
    }

    input[type="checkbox"]+label {
        position: relative;
        display: inline-block;
        padding: 3px 3px 3px 40px;
        cursor: pointer;
        -webkit-transition: all .2s;
        transition: all .2s;

        @if $type==sp {
            margin-bottom: 5px;
        }
    }

    input[type="checkbox"]+label::before,
    input[type="checkbox"]+label::after {
        position: absolute;
        content: '';
        -webkit-transition: all .2s;
        transition: all .2s;
    }

    input[type="checkbox"]+label::before {
        top: 50%;
        left: 0;
        width: 20px;
        height: 20px;
        margin-top: -11px;
        background: #fff;
        border: 2px solid #ccc;
        border-radius: 3px;
    }

    input[type="checkbox"]+label::after {
        opacity: 0;
        border-left: 2px solid $c_purple;
        border-bottom: 2px solid $c_purple;
        -webkit-transform: rotate(-45deg) scale(.5);
        transform: rotate(-45deg) scale(.5);

        @if $type==sp {
            top: calc(50% - 7px);
            left: 5px;
            width: 11px;
            height: 7px;
        }

        @if $type==pc {
            top: 50%;
            left: 5px;
            width: 11px;
            height: 7px;
            margin-top: -6px;
        }

    }

    input[type="checkbox"]+label:hover::before {
        background: #fff;
    }

    input[type="checkbox"]:checked+label::before {
        background: #fff;
        border: 1px solid #ccc;
    }

    input[type="checkbox"]:checked+label::after {
        opacity: 1;
        -webkit-transform: rotate(-45deg) scale(1);
        transform: rotate(-45deg) scale(1);
    }

    &.selectType {
        @if $type==sp {
            width: 100%;
            margin-bottom: 50px;

            .form_dt {
                display: flex;
                justify-content: space-between;
                align-items: center;
                width: 99%;

                .aform-required {
                    background-color: $c_orange;
                    color: #fff;
                    font-size: 12px;
                    padding: 4px 8px;
                    line-height: 1;
                    display: inline-block;
                    margin-left: 10px;
                    border-radius: 3px;
                    white-space: nowrap;
                }
            }

            select {
                width: 100%;
                background-color: #f8f8f8;
                border: none;
            }
        }

        @if $type==pc {
            display: flex;

            .form_dt {
                display: flex;
                justify-content: space-between;
                align-items: center;
                width: 250px;
                margin-right: 25px;

                .aform-required {
                    vertical-align: middle;
                    background-color: $c_orange;
                    color: #fff;
                    font-size: 12px;
                    padding: 4px 8px 5px;
                    width: 40px;
                    line-height: 1;
                    display: inline-block;
                    text-align: center;
                    margin-left: 4px;
                    white-space: nowrap;
                }
            }

            select {
                width: 45%;
                background-color: #f8f8f8;
                border: none;
            }
        }

    }
}

.form_dt {
    display: block;
    font-weight: bold;

    @if $type==sp {
        font-size: 14px;
        margin-bottom: 10px;
    }

    @if $type==pc {
        font-size: 16px;

    }
}

.aform .aform-content {
    #aform-confirm-form {
        dl {
            @if $type==sp {
                border-bottom: 1px solid #f8f8f8;
                margin-bottom: 0;
            }

            @if $type==pc {}
        }
    }

    .aform-note {
        background: none;
        margin-top: 20px;
        margin-bottom: 20px;
    }

    @if $type==sp {
        width: 100%;
        margin-bottom: 20px;

        dl {
            //            margin-bottom: 40px;
            width: 100%;
            display: block;
            border-bottom: none;
        }

        dt {
            width: 100%;
            font-size: 14px;
            font-weight: bold;
            display: block;
            text-align: left;
            margin-bottom: 10px;
            background-clip: padding-box;
            position: relative;
            margin-bottom: 10px;
            margin-top: 20px;

            .aform-required {
                background-color: $c_orange;
                color: #fff;
                font-size: 12px;
                padding: 4px 8px;
                line-height: 1;
                display: inline-block;
                margin-left: 10px;
                border-radius: 3px;
                white-space: nowrap;
            }

            .aform-label {
                display: flex;
                justify-content: space-between;
                align-items: center;
                width: 99%;
            }
        }

        dd {
            display: block;
            width: 100%;

            input {
                background-color: #f8f8f8;
                border: none;
                margin-top: 5px;
            }

            .kome {
                font-size: 13px;
                margin-top: 5px;
                display: block;
            }

            .block_sp {
                display: block !important;
                margin-top: 10px;
            }

            .subtxt {
                display: inline-block;
                width: 15%;
            }
        }

        .aform-textarea,
        select.aform-prefecture {
            background-color: #f8f8f8;
            border: none;
        }

        .aform-textarea {
            width: 100%;
            padding: 10px;
            min-height: 140px;
        }

        .aform-name,
        .aform-kana,
        .aform-input-example,
            {
            margin: 10px 0;
        }

        .tel {
            text-decoration: none;
        }

        .aform-twice-note {
            display: block;
            margin: 10px 0;
        }

        .dl_contactContents {
            align-items: flex-start;

            dd {
                width: 100%;
            }
        }

        .dl_privacyPolicy {
            display: block;
            border-bottom: none;
            margin: 30px 0 0;
            padding-bottom: 10px;
            line-height: 1.4;

            dt {
                img {
                    width: 20px;
                    height: 20px;
                }
            }

            dd {
                background-color: #f8f8f8;
                font-weight: bold;
                padding: 30px;
                width: 100%;
                text-align: center;

            }

            input[type="checkbox"]+label::before {
                top: 12px;
            }

            input[type="checkbox"]+label::after {
                top: 12px;
            }
        }

        .aform-error {
            color: #cf0000;
            font-weight: bold;

            &:first-child {
                margin-top: 0;
            }

            margin-top: 10px;
            font-size: 13px;

            .validation-advice {
                padding-top: 4px;
                line-height: 1.4;
            }
        }
    }

    //sp

    @if $type==pc {
        margin-bottom: 20px;

        dl {
            font-size: 16px;
            margin: 30px 0;
            padding-bottom: 30px;
            border-bottom: 1px solid #f8f8f8;
            display: flex;
            align-items: center;
        }

        dt {
            text-align: left;
            font-weight: normal;
            background-clip: padding-box;
            float: none;
            position: relative;
            border-top: none;
            border-right: none;
            vertical-align: top;
            font-weight: bold;
            margin-right: 25px;

            .aform-required {
                vertical-align: middle;
                background-color: $c_orange;
                color: #fff;
                font-size: 12px;
                padding: 4px 8px 5px;
                width: 40px;
                line-height: 1;
                display: inline-block;
                text-align: center;
                margin-left: 4px;
                white-space: nowrap;
            }

            .aform-label {
                display: flex;
                justify-content: space-between;
                align-items: center;
                width: 250px;
            }
        }

        dd {
            display: block;
            float: none;
            border-top: none;
            border-left: none;
            width: 100%;

            input {
                margin-top: 5px;
                background-color: #f8f8f8;
                border: none;
            }

            .kome {
                display: block;
                font-size: 14px;
                margin-top: 5px;
            }

            .category {
                width: 130px;
                display: inline-block;
            }

            .subtxt {
                width: 43px;
                display: inline-block;
                text-align: right;
                padding-right: 10px;
            }
        }

        .aform-textarea,
        select.aform-prefecture {
            background-color: #f8f8f8;
            border: none;
        }

        select.aform-prefecture {
            margin-top: 10px;
        }

        .aform-textarea {
            width: 100%;
            padding: 10px;
            min-height: 150px;
        }

        .aform-name,
        .aform-kana,
        .aform-input-example,
            {
            margin: 10px 0;
        }

        .aform-name-ul,
        .aform-kana-ul {
            display: flex;

            li {
                flex: 1;

                &+li {
                    margin-left: 1em;
                }
            }
        }

        .aform-twice-note {
            display: block;
            margin: 10px 0;
        }

        .dl_contactContents {
            align-items: flex-start;

            dd {
                width: 100%;
            }
        }

        .dl_privacyPolicy {
            display: block;
            border-bottom: none;
            margin: 30px 0 0;
            padding-bottom: 10px;

            dd {
                background-color: #f8f8f8;
                font-weight: bold;
                margin: 40px auto 0;
                padding: 26px 30px;
                width: 60%;
                text-align: center;

                .pp_txt {
                    font-size: 14px;
                    line-height: 1.5;

                }
            }
        }

        .aform-error {
            color: #cf0000;
            font-weight: bold;

            &:first-child {
                margin-top: 0;
            }

            margin-top: 10px;
            font-size: 14px;

            .validation-advice {
                padding-top: 4px;
                line-height: 1.4;
            }
        }
    }

    //pc
}

.aform .aform-content .aform-button-area {
    @if $type==sp {
        margin-top: 20px;
        padding: 0;
        background: none;
        text-align: center;
        display: flex;
        flex-direction: column;
    }

    @if $type==pc {
        margin-top: 30px;
        background: none;
        padding: 0;
        text-align: center;
        display: flex;
        flex-direction: column;

        &:after {
            content: "";
            display: block;
            clear: both;
        }
    }

    input[type="submit"] {
        @if $type=="" {
            cursor: pointer;
            outline: none;
            border: none;
            display: flex;
            align-items: center;
            justify-content: center;
            border: 1px solid $c_purple;
            border-radius: 3px;
            background-color: #fff;
            background-image: url(../images/common/icon_arrow.svg);
            background-repeat: no-repeat;
            background-size: 15px 13px;
            background-position: right 16px center;
            color: $c_purple;
            margin-left: auto;
            margin-right: auto;
            font-weight: bold;
            position: relative;
            text-decoration: none;
            text-align: center;
            transition: .3s background, .3s color $ttf;
        }

        @if $type==sp {
            box-shadow: 1px 1px 0 0 $c_purple;
            margin: auto;
            padding: 12px 40px;
            width: 72%;
        }

        @if $type==pc {
            order: 1;
            box-shadow: 2px 2px 0 0 $c_purple;
            width: 288px;
            padding: 14px 40px;
            line-height: 1.2;
            transition: all .3s;

            &:hover {
                background-color: $c_purple;
                color: #fff;
                background-image: url(../images/common/icon_arrow_white.svg);
            }
        }
    }
}

.nofloat {
    @if $type==sp {
        float: none !important;
    }

    @if $type==pc {
        float: none !important;
    }
}

#aform_btn_back {
    font-size: 16px;
    order: 2;

    @if $type==sp {
        display: block;
        padding: 10px 35px;
        // background-image: url(../images/common/icon_arrow_back.svg);
        // background-repeat: no-repeat;
        // background-size: 15px 13px;
        // background-position: left 16px center;
        background-color: #fff;
        color: #666;
        text-align: center;
        vertical-align: top;
        position: relative;
        border: 1px solid #aaa;
        width: 225px;
        margin: 20px auto;
    }

    @if $type==pc {
        display: inline-block;
        padding: 12px 35px;
        border: 1px solid #aaa;
        // background-image: url("../images/common/x.png");
        // background-position: 20px center;
        // background-repeat: no-repeat;
        // background-size: 17px;
        background-color: #fff;
        color: #666 !important;
        width: 250px;
        text-align: center;
        margin: 0 10px 20px;
        vertical-align: top;
        position: relative;
        cursor: pointer;
        margin: 20px auto;

        &:hover {
            opacity: 0.7;
        }
    }
}

.aform .aform-content dl.aform-error-list {
    border: none;

    dt {
        display: block;
    }

    dd {
        display: list-item;
        margin-left: 25px;
        width: auto;
    }
}

#aform_confirm_msg {
    text-align: center;
    padding-top: 20px;
}

img[src$="icon_new_windows.gif"] {
    margin-left: 10px;
    vertical-align: middle;
}

.aform-header {
    display: none;
}

.aform select.aform-calendar {
    @if $type==sp {
        width: auto;
    }

    @if $type==pc {
        width: auto;
    }
}

.aform select.aform-calendar+label {
    @if $type==sp {
        margin-left: 2px;
        margin-right: 5px;
    }

    @if $type==pc {
        margin-left: 7px;
        margin-right: 12px;
    }
}

.aform #aform_result {
    color: #000;

    a.commonBtn {
        margin: 20px auto 30px;
    }
}

@import "_modaal";

#toc_container li:before {
    @if $type=="" {
        content: none;
    }
}