//================================================
// ★サイト内共通で使うスタイルとブロックエディタで使うスタイルをmixinで管理★
// 使用例としては、
// サイト内共通で使う「h2.title」と
// ブロックエディタで使う「.singleContents h2」を
// 共通でソース管理する　という感じ
// 共通で管理しきれないものは無理に共通化しない
// ボタンやテーブルは共通化していない
// 以下mixinとクラス「.singleContents」の中身に変更を加えたら、
// 「_gb_style.scss」にも反映する
//フロント側・・・.singleContents
// エディタ側・・・.editor-styles-wrapper.block-editor-writing-flow
//================================================
@mixin pMargin {
    @if $type==sp {
        margin-top: 30px;
        margin-bottom: 20px;
    }

    @if $type==pc {
        margin-top: 40px;
        margin-bottom: 30px;
    }
}

@mixin h2TitleMargin {
    @if $type==sp {
        margin-top: 40px;
    }

    @if $type==pc {
        margin-top: 80px;
    }
}

@mixin h3TitleMargin {
    @if $type==sp {
        margin-top: 30px;
    }

    @if $type==pc {
        margin-top: 40px;
    }
}

@mixin h4TitleMargin {
    @if $type==sp {
        margin-top: 30px;
    }

    @if $type==pc {
        margin-top: 40px;
    }
}

@mixin titleAfterPMargin {
    @if $type==sp {
        margin-top: 20px;
        margin-bottom: 20px;
    }

    @if $type==pc {
        margin-top: 30px;
        margin-bottom: 30px;
    }
}

@mixin h2Title {
    @if $type=="" {
        border-bottom: 1px solid $c_base;
    }

    @if $type==sp {
        font-size: 22px;
        padding-bottom: 15px;
    }

    @if $type==pc {
        font-size: 26px;
        padding-bottom: 20px;
    }
}

@mixin h3Title {
    @if $type=="" {
        font-weight: bold;
        border-left: 3px solid $c_base;
        position: relative;

        &:before {
            content: "";
            display: inline-block;
            width: 3px;
            height: 50%;
            background-color: #dddddd;
            position: absolute;
            top: 0;
            left: -3px;
        }
    }

    @if $type==sp {
        font-size: 18px;
        padding-left: 20px;
    }

    @if $type==pc {
        font-size: 20px;
        padding-left: 25px;
    }
}

@mixin h4Title {
    @if $type=="" {
        font-weight: 500;
        position: relative;
        padding-bottom: 15px;

        &:after {
            content: "";
            display: inline-block;
            background-color: $c_base;
            height: 1px;
            width: 20px;
            position: absolute;
            bottom: 0;
            left: 0;
            top: auto;
        }
    }

    @if $type==sp {
        font-size: 16px;
    }

    @if $type==pc {
        font-size: 18px;
    }
}

@mixin commonList {
    @if $type=="" {
        padding-left: 0 !important;
    }

    @if $type==sp {
        li {
            padding-left: 20px;
            position: relative;

            &:not(:first-of-type) {
                margin-top: 10px;
            }

            &:before {
                content: "";
                display: inline-block;
                width: 6px;
                height: 6px;
                border-radius: 50%;
                background-color: $c_base;
                position: absolute;
                left: 3px;
                top: 10px;
            }

            ul {
                margin-bottom: .8em;
            }
        }
    }

    @if $type==pc {
        li {
            padding-left: 25px;
            position: relative;

            &:not(:first-of-type) {
                margin-top: 10px;
            }

            &:before {
                content: "";
                display: inline-block;
                width: 8px;
                height: 8px;
                border-radius: 50%;
                background-color: $c_base;
                position: absolute;
                left: 0;
                top: 12px;
            }

            ul {
                margin-bottom: .8em;
                margin-top: 10px;
            }
        }
    }
}

@mixin olList {
    @if $type=="" {
        counter-reset: item;
        list-style-type: none;
        padding-left: 0;

        li {
            text-indent: -1.2em;
            padding-left: 1.2em;

            &:before {
                counter-increment: item;
                content: counter(item)'.';
                padding-right: .4em;
                font-weight: bold;
                @include fontEn;
            }

            &:not(:first-of-type) {
                margin-top: 10px;
            }
        }

        ol {
            padding-left: 1em;
        }
    }

    @if $type==sp {}

    @if $type==pc {}
}

@mixin colorBox {
    @if $type=="" {
        background-color: #f8f8f8;
    }

    @if $type==sp {
        padding: 20px;
        margin-bottom: 30px;
    }

    @if $type==pc {
        padding: 40px;
        margin-bottom: 40px;
    }

    .title {
        @if $type=="" {
            font-weight: 500;
            position: relative;
            padding-bottom: 15px;

            &:after {
                content: "";
                display: inline-block;
                background-color: $c_base;
                height: 1px;
                width: 20px;
                position: absolute;
                bottom: 0;
                left: 0;
            }
        }

        @if $type==sp {
            font-size: 16px;
            margin-bottom: 15px;
        }

        @if $type==pc {
            font-size: 18px;
            margin-bottom: 20px;
        }
    }
}

@mixin profileBox {
    @if $type=="" {
        background-color: #f8f8f8;
        width: 100%;
    }

    @if $type==sp {
        padding: 20px;
        margin-bottom: 30px;
    }

    @if $type==pc {
        padding: 40px 42px;
        margin-bottom: 40px;
    }

    .profileTitle {
        @if $type=="" {
            border-bottom: 1px solid $c_purple;
            margin: 0 0 20px;
            padding: 0 0 10px;
        }

        @if $type==sp {
            font-size: 16px;
        }

        @if $type==pc {
            font-size: 18px;
        }
    }

    .profileInner {
        .infoArea {
            @if $type=="" {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                margin-bottom: 20px;
            }

            img {
                @if $type=="" {
                    border-radius: 50%;
                }

                @if $type==sp {
                    width: 70px;
                }

                @if $type==pc {
                    width: 100px;
                }
            }
        }

        .authorGroup {
            @if $type=="" {
                margin-top: 12px;
                text-align: center;
            }
        }

        .authorPosition {
            @if $type=="" {
                color: #494a81;
                font-weight: bold;
            }

            @if $type==sp {
                font-size: 13px;
            }

            @if $type==pc {
                font-size: 15px;
            }
        }

        .authorName {
            @if $type=="" {
                font-weight: bold;
                margin: 0 0 5px;
            }

            @if $type==sp {
                font-size: 16px;
            }

            @if $type==pc {
                font-size: 18px;
            }
        }

        .authorProfile {
            @if $type=="" {
                margin: 0;
            }

            @if $type==sp {
                font-size: 14px;
            }

            @if $type==pc {
                font-size: 16px;
            }
        }
    }

    .snsIcon {
        @if $type=="" {
            display: inline-block;
            padding-left: 26px;
            text-decoration: none;
        }

        @if $type==pc {
            &:hover {
                text-decoration: underline;
            }
        }

        &:not(:last-child) {
            @if $type=="" {
                margin-right: 16px;
            }
        }

        &.twitter {
            @if $type=="" {
                background-image: url(../images/common/icon_twitter_blue.svg);
                background-repeat: no-repeat;
                background-position: center left;
                background-size: 20px auto;
            }
        }

        &.instagram {
            @if $type=="" {
                background-image: url(../images/common/icon_insta.png);
                background-repeat: no-repeat;
                background-position: center left;
                background-size: 20px auto;
            }
        }
    }
}

@mixin magazineBox {
    @if $type=="" {
        text-align: center;
        background-color: #f8f8f8;
        width: 100%;
    }

    @if $type==sp {
        padding: 20px;
        margin-bottom: 30px;
    }

    @if $type==pc {
        padding: 40px 42px;
        margin-bottom: 40px;
    }

    .title {
        @if $type=="" {
            // text-align: center;
            color: $c_purple;
            font-weight: 500;
            transition: .3s color $ttf;
        }

        @if $type==sp {
            font-size: 19px;
        }

        @if $type==pc {
            font-size: 24px;
        }

        .icon {
            &.iconMailMagazine {
                @if $type=="" {
                    position: relative;
                }

                @if $type==sp {
                    padding-left: 35px;
                }

                @if $type==pc {
                    padding-left: 40px;
                }

                &::before {
                    @if $type=="" {
                        content: "";
                        background: url(../images/common/icon_mailmagazine.svg) no-repeat center / 100%;
                        display: block;
                        position: absolute;
                        top: 50%;
                        transform: translateY(-50%);
                        left: 0;
                    }

                    @if $type==sp {
                        width: 27px;
                        height: 29px;
                    }

                    @if $type==pc {
                        width: 27px;
                        height: 29px;
                    }
                }
            }
        }
    }

    .magagineTxt {
        @if $type=="" {
            margin-bottom: 10px;
        }

        @if $type==sp {
            font-size: 14px;
            margin-top: 20px;
        }

        @if $type==pc {
            font-size: 16px;
            margin-top: 20px;
        }
    }

    .magaginePolicy {
        @if $type=="" {
            margin-top: 0;
        }

        @if $type==sp {
            font-size: 11px;
        }

        @if $type==pc {
            font-size: 12px;
        }
    }

    .mailMagazine {
        @if $type==sp {
            margin-top: 25px;
        }

        @if $type==pc {
            max-width: 425px;
            margin-inline: auto;
            // margin-top: auto;
        }

        .inputWrap {
            @if $type==pc {
                display: flex;
            }
        }

        .magazineInput {
            @if $type=="" {
                max-width: 100%;
                border: 1px solid #d1d1d1;
                border-radius: 3px;
            }

            @if $type==sp {
                height: 27.19px;
                width: 100%;
                max-width: 515px;
                padding: 5px;
            }

            @if $type==tb {
                // width: 100%;
                // max-width: 100%;
            }

            @if $type==pc {
                font-size: 16px !important;
                height: 30px;
                width: calc(100% - 105px);
                padding: 5px;
            }

            &:focus {
                @if $type=="" {
                    outline: none;
                }
            }

            &::placeholder {
                @if $type=="" {
                    color: #d1d1d1;
                }
            }
        }

        .magazineSubmit {
            @if $type=="" {
                font-weight: bold;
                background-color: #fff;
                border: 1px solid $c_base;
                color: $c_base;
                border-radius: 3px;
            }

            @if $type==sp {
                box-shadow: 1px 1px 0 0 $c_purple;
                width: 94px;
                // padding: 5px;
                margin-top: 10px;
            }

            @if $type==tb {
                // margin-top: 20px;
            }

            @if $type==pc {
                font-size: 16px !important;
                box-shadow: 2px 2px 0 0 $c_purple;
                cursor: pointer;
                width: 94px;
                // padding: 5px;
                margin-left: 7px;
                transition: .3s $ttf;

                &:hover {
                    background-color: $c_base;
                    color: #fff;
                }
            }
        }
    }


}

//固定ページの本文の大きさ変更
.page .singleContents {
    p {
        @if $type==sp {
            font-size: 14px;
        }

        @if $type==pc {
            font-size: 16px;
        }
    }
}

//================================================
// ★ブロックエディタで使うCSS★
// フロント側用のクラス「.singleContents」
//================================================
// ▼_gb_style.scssにコピペ ここから▼
.singleContents {
    .has-inline-color.has-luminous-vivid-orange-color {
        color: $c_orange !important;
    }

    .inner {
        &>*:first-child {
            margin-top: 0;
        }
    }

    //================================================
    // 余白
    //================================================
    p {
        @if $type==sp {
            font-size: 15px;
        }

        @if $type==pc {
            font-size: 18px;
        }
    }

    // * + p {
    //     @include pMargin;
    // }
    p {
        @include pMargin;
    }

    h2+p,
    h3+p,
    h4+p {
        @include titleAfterPMargin;
    }

    *+h2 {
        @include h2TitleMargin;
    }

    *+h3 {
        @include h3TitleMargin;
    }

    *+h4 {
        @include h4TitleMargin;
    }

    *+h2,
    *+h3,
    *+h4 {
        @if $type==sp {
            margin-bottom: 20px;
        }

        @if $type==pc {
            margin-bottom: 30px;
        }
    }

    //================================================
    // タイトル
    //================================================
    h2 {
        @include h2Title;
    }

    h3 {
        @include h3Title;
    }

    h4 {
        @include h4Title;
    }

    //================================================
    // リスト
    //================================================
    ul {
        @include commonList;
    }

    ol {
        @include olList;
    }

    *+ul,
    *+ol {
        @if $type==sp {
            margin-top: 30px;
            margin-bottom: 30px;
        }

        @if $type==pc {
            margin-top: 40px;
            margin-bottom: 40px;
        }
    }

    // ul:not([class]) {
    //     @include commonList;
    // }

    // ol:not([class]) {
    //     @include olList;
    // }

    // *+ul:not([class]),
    // *+ol:not([class]) {
    //     @if $type==sp {
    //         margin-top: 30px;
    //         margin-bottom: 30px;
    //     }

    //     @if $type==pc {
    //         margin-top: 40px;
    //         margin-bottom: 40px;
    //     }
    // }

    //================================================
    // 既存ブロック（オーバーライド）
    //================================================
    p+.wp-block-gallery {
        margin-top: 10px;
    }

    // ボタン
    .wp-block-button {
        @if $type==sp {
            margin-bottom: 20px;
        }

        @if $type==pc {
            margin-bottom: 40px;
        }

        a,
        .wp-block-button__link {
            @if $type=="" {
                display: flex;
                align-items: center;
                justify-content: center;
                border: 1px solid $c_purple;
                border-radius: 3px;
                background-color: #fff !important;
                color: $c_purple !important;
                margin-left: auto;
                margin-right: auto;
                font-weight: bold;
                position: relative;
                text-decoration: none;
                text-align: center;
                transition: .3s background, .3s color $ttf;
            }

            @if $type==sp {
                box-shadow: 1px 1px 0 0 $c_purple;
                margin: auto;
                padding: 12px 40px;
                width: 72%;
            }

            @if $type==pc {
                box-shadow: 2px 2px 0 0 $c_purple;
                min-width: 288px;
                padding: 14px 40px;
                line-height: 1.2;
                transition: all .3s;

                &:hover {
                    background-color: $c_purple !important;
                    color: #fff !important;

                    &::before {
                        background-image: url(../images/common/icon_arrow_white.svg);
                    }
                }
            }

            &::before {
                @if $type=="" {
                    content: "";
                    position: absolute;
                    top: 0;
                    bottom: 0;
                    width: 15px;
                    height: 13px;
                    right: 16px;
                    margin: auto;
                    background: url(../images/common/icon_arrow.svg) no-repeat;
                    background-size: contain;
                }
            }
        }

        .wp-block-button__link {
            @if $type=="" {
                background-color: $c_purple;
                border: 2px solid $c_purple;
                transition: .3s color, .3s background $ttf;
            }

            @if $type==pc {
                &:hover {
                    border-color: $c_purple;
                    background-color: #fff;
                    color: $c_purple;
                }
            }
        }

        &.btnOrange {
            .wp-block-button__link {
                @if $type=="" {
                    background-color: #f07800 !important;
                    color: #fff !important;
                    border: 1px solid #e8651a;

                    &:before {
                        background-image: url(../images/common/icon_arrow_white.svg);
                    }
                }

                @if $type==sp {
                    box-shadow: 1px 1px 0 0 #e8651a;
                }

                @if $type==pc {
                    box-shadow: 2px 2px 0 0 #e8651a;

                    &:hover {
                        border-color: #e8651a;
                        background-color: #e8651a !important;
                    }
                }
            }
        }

        &.is-style-outline {
            .wp-block-button__link {
                @if $type=="" {
                    background-color: #fff;
                }

                @if $type==pc {
                    &:hover {
                        background-color: $c_purple;
                        color: #fff;
                    }
                }
            }
        }
    }

    .wp-block-buttons {
        @if $type==sp {
            margin-bottom: 20px;
        }

        @if $type==pc {
            margin-bottom: 40px;
        }

        .wp-block-button {
            @if $type==sp {
                display: block;
                margin-bottom: 0;
                width: 100%;
            }

            @if $type==pc {
                margin-bottom: 0;
            }
        }
    }

    // テーブル
    .wp-block-table {
        @if $type=="" {
            border-top: 1px solid #c1c1d0;
        }

        thead {
            @if $type=="" {
                border-bottom: none;
            }
        }

        tr {
            th {
                @if $type=="" {
                    text-align: center;
                    font-weight: normal;
                    border: 1px solid #c1c1d0;

                    border-left: none;
                    border-top: none;
                    // background-color: #f8f8f8;
                    background-color: $c_purple;
                    color: #fff;

                    &:last-child {
                        margin-bottom: 0;
                        border-right: none;
                    }
                }

                @if $type==sp {
                    padding: 20px 20px;
                }

                @if $type==pc {
                    padding: 25px 40px;
                }
            }

            td {
                @if $type=="" {
                    border-color: #c1c1d0;
                    border-left: none;
                    border-top: none;

                    &:last-child {
                        margin-bottom: 0;
                        border-right: none;
                    }
                }

                @if $type==sp {
                    padding: 20px 20px;
                }

                @if $type==pc {
                    padding: 25px 40px;
                }
            }
        }

        &.is-style-stripes {
            tbody tr:nth-child(odd) {
                @if $type=="" {
                    background-color: #f8f8f8;
                }
            }
        }
    }

    .wp-block-image {
        @if $type==sp {
            margin-bottom: 30px;
        }

        @if $type==pc {
            margin-bottom: 40px;
        }
    }

    .wp-block-columns {
        .wp-block-image {
            @if $type=="" {
                margin-bottom: 10px;
            }
        }
    }

    // メディアとテキスト
    .wp-block-media-text .wp-block-media-text__content {
        @if $type==sp {
            margin-top: 10px;
            padding-left: 0;
            padding-right: 0;
        }

        p {
            @if $type=="" {
                margin-top: 0;
            }
        }
    }

    .wp-block-media-text {
        @if $type==sp {
            margin-bottom: 30px;
        }

        @if $type==pc {
            margin-bottom: 40px;
        }

        .wp-block-media-text__media+.wp-block-media-text__content {
            @if $type==pc {
                padding-right: 0;
            }
        }
    }

    .wp-block-media-text.has-media-on-the-right {
        .wp-block-media-text__content {
            @if $type==pc {
                padding-left: 0;
            }
        }
    }

    // グループ
    .wp-block-group {
        @if $type==sp {
            margin-bottom: 30px;
        }

        @if $type==pc {
            margin-bottom: 40px;
        }
    }

    //================================================
    // カスタムブロック
    //================================================
    .colorBox {
        @include colorBox;
    }

    /* 投稿者情報
-----------------------------------------*/
    .profileBox {
        @include profileBox;
    }

    /* メルマガ配信
-----------------------------------------*/
    .magazineBox {
        @include magazineBox;
    }
}

// ▲_gb_style.scssにコピペ ここまで▲
// ブロックエディタ用の記述ここまで



//================================================
// ★サイト内共通で使うCSS★
//================================================

//================================================
// 余白
//================================================
*+p {
    @if $type==sp {
        margin-top: 30px;
    }

    @if $type==pc {
        margin-top: 40px;
    }
}

h2+p,
h3+p,
h4+p {
    @include titleAfterPMargin;
}

*+h2.title,
*+h2.title2 {
    @include h2TitleMargin;
}

*+h3.title {
    @include h3TitleMargin;
}

*+h4.title {
    @include h4TitleMargin;
}

//================================================
// タイトル
//================================================
h2.title {
    @include h2Title;
}

h2.title2 {
    @if $type=="" {
        text-align: center;
    }

    @if $type==sp {
        font-size: 23px;

        .small {
            font-size: 14px;
        }
    }

    @if $type==pc {
        font-size: 34px;

        .small {
            font-size: 26px;
        }
    }
}

h3.title {
    @include h3Title;
}

h4.title {
    @include h4Title;
}

//================================================
// リスト
//================================================
ul.commonList {
    @include commonList;
}

ul.col2List {
    @if $type==sp {}

    @if $type==pc {
        display: flex;
        flex-wrap: wrap;
    }

    li {
        @if $type==sp {}

        @if $type==pc {
            width: 50%;
            flex-basis: 50%;

            &:not(:first-of-type) {
                margin-top: 0px;
            }

            &:nth-of-type(n + 3) {
                margin-top: 10px;
            }
        }
    }
}

//col2List

ol {
    @include olList;
}

section {
    &.bgGray {
        @if $type=="" {
            background-color: #f8f8f8;
        }

        @if $type==sp {
            padding: 50px 0;

            &.pageBottom {
                margin-bottom: -50px;
            }
        }

        @if $type==pc {
            padding: 80px 0;

            &.pageBottom {
                margin-bottom: -100px;
            }

            &.harfBottom {
                padding-bottom: 70px;
            }
        }
    }

}

//アキ調整
section+section {
    @if $type==sp {
        margin-top: 60px;
    }

    @if $type==pc {
        margin-top: 80px;
    }
}

*+.commonList,
*+.linkList,
*+.colorBox,
*+ol,
*+.commonTable {
    @if $type==sp {
        margin-top: 30px;
    }

    @if $type==pc {
        margin-top: 40px;
    }
}

*+.imgLine2,
*+.imgLine3,
*+.twoCol {
    @if $type==sp {
        margin-top: 30px;
    }

    @if $type==pc {
        margin-top: 40px;
    }
}

.catch {
    @if $type=="" {
        color: #192f60;
        font-weight: bold;
        line-height: 1.5;
    }

    @if $type==sp {
        font-size: 23px;

        +p {
            margin-top: 20px;
        }
    }

    @if $type==pc {
        font-size: 34px;
    }
}

a.icon {
    display: inline-block;
    position: relative;
    padding-right: 25px;

    &:after {
        content: "";
        display: block;
        width: 20px;
        height: 20px;
        background-repeat: no-repeat;
        background-position: 50% 0;
        position: absolute;
        right: 0px;
        top: .2em;
    }

    &[href $=pdf] {
        &:after {
            background-image: url(../images/second/icon_pdf.svg);
        }
    }

    &[href $=doc],
    &[href $=docx] {
        &:after {
            background-image: url(../images/second/icon_word.svg);
        }
    }

    &[href $=xls],
    &[href $=xlsx] {
        &:after {
            background-image: url(../images/second/icon_excel.svg);
        }
    }


    &.front {
        padding-left: 25px;
        padding-right: 0;

        &:after {
            left: 0px;
        }
    }
}

.iconBlank {
    display: inline-block;
    width: 17px;
    height: 17px;
    background: url("../images/second/icon_blank.svg") no-repeat 0 0;
    background-size: cover;
    vertical-align: middle;
    margin-left: 10px;
    position: relative;
    top: -3px;
}

.commonBtn {
    margin: auto 0 0 0;
}

.imgLine2 {
    @if $type==sp {}

    @if $type==pc {
        display: flex;
        flex-wrap: wrap;
    }

    li {
        text-align: center;

        @if $type==sp {
            margin-top: 20px;
        }

        @if $type==pc {
            width: calc(50% - 25px);

            &:nth-of-type(odd) {
                margin-right: 50px;
            }

            &:nth-of-type(n + 3) {
                margin-top: 50px;
            }
        }

        img {
            //width: 100%;
            max-width: 100%;
        }
    }

    &.small {
        @if $type==pc {
            width: 80%;
            margin-left: auto;
            margin-right: auto;
        }
    }
}

.imgLine3 {

    @if $type==sp {}

    @if $type==pc {
        display: flex;
        flex-wrap: wrap;
    }

    li {
        text-align: center;

        @if $type==sp {
            margin-top: 20px;
        }

        @if $type==pc {
            width: calc((100% / 3) - 17px);
            margin-right: 25px;

            &:nth-of-type(3n) {
                margin-right: 0;
            }

            &:nth-of-type(n + 4) {
                margin-top: 25px;
            }
        }

        img {
            max-width: 100%;
        }
    }
}

.imgInP {
    @if $type==sp {}

    @if $type==pc {
        overflow: hidden;
    }

    .img {
        @if $type==sp {
            display: block;
            margin: 0 auto;
            padding-bottom: 20px;
        }

        @if $type==pc {
            max-width: 300px;

            img {
                width: 100%;
                height: auto;
            }
        }

        .cap {
            display: block;
            text-align: left;
            font-size: 14px;
            margin-top: 10px;
        }
    }

    .img.left {
        @if $type==sp {}

        @if $type==pc {
            float: left;
            margin-right: 40px;
        }
    }

    .img.right {
        @if $type==sp {}

        @if $type==pc {
            float: right;
            margin-left: 40px;
        }
    }

    &.sp_img_btm {
        @if $type==sp {
            display: flex;
            flex-wrap: wrap;

            .img {
                display: block;
                order: 2;
                margin: 20px auto 0;
                padding-bottom: 0;
            }

            .text {
                flex-basis: 100%;
                order: 1;
            }
        }
    }
}

.twoCol {
    @if $type==sp {
        >div {
            &:nth-of-type(n + 2) {
                margin-top: 30px;
            }
        }

        >div.movie {
            a {
                display: block;
                transition: .3s;
                position: relative;

                &:before {
                    content: "";
                    display: inline-block;
                    background: url(../images/second/play.png) no-repeat;
                    background-size: cover;
                    width: 48px;
                    height: 48px;
                    position: absolute;
                    top: calc(50% - 24px);
                    left: calc(50% - 24px);
                }
            }

            img {
                box-shadow: 6px 5px 12px 2px #cccccc, -8px -9px 11px 5px #ffffff;
            }
        }
    }

    @if $type==pc {
        display: flex;
        flex-wrap: wrap;

        &.center {
            align-items: center;
        }

        >div {
            width: calc(50% - 30px);

            &:nth-of-type(odd) {
                margin-right: 60px;
            }

            &:nth-of-type(n + 3) {
                margin-top: 60px;
            }
        }

        >div.movie {
            a {
                display: block;
                transition: .3s;
                position: relative;

                &:before {
                    content: "";
                    display: inline-block;
                    background: url(../images/second/play.png) no-repeat;
                    background-size: cover;
                    width: 96px;
                    height: 96px;
                    position: absolute;
                    top: calc(50% - 48px);
                    left: calc(50% - 48px);
                }

                &:hover {
                    opacity: .7;
                }
            }

            img {
                box-shadow: 6px 5px 12px 2px #cccccc, -8px -9px 11px 5px #ffffff;
            }
        }

        &.imgAdjustCol {
            &>div {
                img {
                    @if $type==pc {
                        width: 544px;
                    }
                }
            }
        }
    }

    .cap {
        display: block;
        text-align: left;
        font-size: 14px;
        margin-top: 10px;
    }
}

.colorBox {
    @include colorBox;
}

.iframeWrap {
    position: relative;
    padding-bottom: 56.25%;

    iframe {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
    }

    @if $type==sp {
        margin-top: 30px;
    }

    @if $type==pc {
        margin-top: 40px;
    }
}

//テーブル
.commonTable {
    @if $type=="" {
        width: 100%;
        border-collapse: collapse;
        border-spacing: inherit;
        border-top: 1px solid $c_border;
    }

    td.wFix,
    th.wFix {
        @if $type==sp {
            width: 120px;
        }

        @if $type==pc {
            width: 150px;
        }
    }

    th {
        @if $type=="" {
            text-align: center;
            font-weight: normal;
            border-bottom: 1px solid $c_border;
            border-right: 1px solid $c_border;
            background-color: #494a81;
            color: #fff;

            &:last-child {
                margin-bottom: 0;
                border-right: none;
            }

            &.sub {
                background-color: #f8f8f8;
                color: $c_base;
            }
        }

        @if $type==sp {
            padding: 20px 20px;
        }

        @if $type==pc {
            padding: 25px 40px;
        }

        &.null {
            border-top: 1px solid $c_border;
            border-left: 1px solid $c_border;
            background-color: #fff;
        }
    }

    td {
        @if $type=="" {
            border-bottom: 1px solid $c_border;
            border-right: 1px solid $c_border;
            word-break: break-all;

            &:last-child {
                margin-bottom: 0;
                border-right: none;
            }
        }

        @if $type==sp {
            padding: 20px 20px;
        }

        @if $type==pc {
            padding: 25px 40px;
        }

        &.null {
            @if $type=="" {
                border-top: 1px solid $c_border;
                border-left: 1px solid $c_border;
            }
        }
    }
}

.scrollTable {
    @if $type=="" {
        overflow: auto;
    }

    @if $type==sp {
        margin-top: 30px;
    }

    @if $type==pc {
        margin-top: 40px;
    }

    table {
        @if $type==sp {
            white-space: nowrap;
        }
    }
}

.scrollText {
    font-size: 12px;
    display: block;
    text-align: right;
    margin: 30px 0 -20px;
}

// 公開日＆最終更新日
.dateArea {
    @if $type=="" {}

    @if $type==sp {
        margin-bottom: 20px;
    }

    @if $type==pc {
        margin-bottom: 25px;
    }

    .entryDate,
    .entryUpDate {
        @if $type=="" {
            display: block;
            color: #9d9ea0;
            text-align: right;
        }

        @if $type==sp {
            font-size: 12px;
            line-height: 1.3;
        }

        @if $type==pc {
            font-size: 13px;
            line-height: 1.5;
        }
    }
}


/* common class */

.pointerNone {
    cursor: default;
    text-decoration: none;
}

.taC {
    text-align: center !important;
}

.taL {
    text-align: left !important;
}

.taR {
    text-align: right !important;
}

.Center {
    margin: 0 auto !important;
}

.ovh {
    overflow: hidden !important;
}

.mw100 {
    max-width: 100% !important;
    width: auto !important;
}

.w100p {
    width: 100% !important;
}

.pT0 {
    padding-top: 0 !important;
}

.mB0 {
    margin-bottom: 0 !important;
}

.mB5 {
    margin-bottom: 5px !important;
}

.mB10 {
    margin-bottom: 10px !important;
}

.mB20 {
    margin-bottom: 20px !important;
}

.mB30 {
    margin-bottom: 30px !important;
}

.mB40 {
    margin-bottom: 40px !important;
}

.mB50 {
    margin-bottom: 50px !important;
}

.mT0 {
    margin-top: 0 !important;
}

.mT5 {
    margin-top: 5px !important;
}

.mT10 {
    margin-top: 10px !important;
}

.mT20 {
    margin-top: 20px !important;
}

.mT30 {
    margin-top: 30px !important;
}

.mT40 {
    margin-top: 40px !important;
}

.mT50 {
    margin-top: 50px !important;
}

.mL0 {
    margin-left: 0px !important;
}

.mL5 {
    margin-left: 5px !important;
}

.mL10 {
    margin-left: 10px !important;
}

.mL20 {
    margin-left: 20px !important;
}

.mL30 {
    margin-left: 30px !important;
}

.mL40 {
    margin-left: 40px !important;
}

.mL50 {
    margin-left: 50px !important;
}

.mR0 {
    margin-right: 0px !important;
}

.mR5 {
    margin-right: 5px !important;
}

.mR10 {
    margin-right: 10px !important;
}

.mR20 {
    margin-right: 20px !important;
}

.mR30 {
    margin-right: 30px !important;
}

.mR40 {
    margin-right: 40px !important;
}

.mR50 {
    margin-right: 50px !important;
}

@if $type==sp {
    .mB0 {
        margin-bottom: 0 !important;
    }

    .mB5_sp {
        margin-bottom: 5px !important;
    }

    .mB10_sp {
        margin-bottom: 10px !important;
    }

    .mB20_sp {
        margin-bottom: 20px !important;
    }

    .mB30_sp {
        margin-bottom: 30px !important;
    }

    .mB40_sp {
        margin-bottom: 40px !important;
    }

    .mB50_sp {
        margin-bottom: 50px !important;
    }

    .mT0 {
        margin-top: 0 !important;
    }

    .mT5_sp {
        margin-top: 5px !important;
    }

    .mT10_sp {
        margin-top: 10px !important;
    }

    .mT20_sp {
        margin-top: 20px !important;
    }

    .mT30_sp {
        margin-top: 30px !important;
    }

    .mT40_sp {
        margin-top: 40px !important;
    }

    .mT50_sp {
        margin-top: 50px !important;
    }

    .mL0_sp {
        margin-left: 0px !important;
    }

    .mL5_sp {
        margin-left: 5px !important;
    }

    .mL10_sp {
        margin-left: 10px !important;
    }

    .mL20_sp {
        margin-left: 20px !important;
    }

    .mL30_sp {
        margin-left: 30px !important;
    }

    .mL40_sp {
        margin-left: 40px !important;
    }

    .mL50_sp {
        margin-left: 50px !important;
    }

    .mR0_sp {
        margin-right: 0px !important;
    }

    .mR5_sp {
        margin-right: 5px !important;
    }

    .mR10_sp {
        margin-right: 10px !important;
    }

    .mR20_sp {
        margin-right: 20px !important;
    }

    .mR30_sp {
        margin-right: 30px !important;
    }

    .mR40_sp {
        margin-right: 40px !important;
    }

    .mR50_sp {
        margin-right: 50px !important;
    }

    .size12_sp {
        font-size: 12px !important;
    }

    .size14_sp {
        font-size: 14px !important;
    }

    .size16_sp {
        font-size: 16px !important;
    }

    .size18_sp {
        font-size: 18px !important;
    }

    .size20_sp {
        font-size: 20px !important;
    }

    .w50p_sp {
        width: 50% !important;
    }
}

@if $type==pc {
    .mB0_pc {
        margin-bottom: 0 !important;
    }

    .mB5_pc {
        margin-bottom: 5px !important;
    }

    .mB10_pc {
        margin-bottom: 10px !important;
    }

    .mB20_pc {
        margin-bottom: 20px !important;
    }

    .mB30_pc {
        margin-bottom: 30px !important;
    }

    .mB40_pc {
        margin-bottom: 40px !important;
    }

    .mB50_pc {
        margin-bottom: 50px !important;
    }

    .mT0_pc {
        margin-top: 0 !important;
    }

    .mT5_pc {
        margin-top: 5px !important;
    }

    .mT10_pc {
        margin-top: 10px !important;
    }

    .mT20_pc {
        margin-top: 20px !important;
    }

    .mT30_pc {
        margin-top: 30px !important;
    }

    .mT40_pc {
        margin-top: 40px !important;
    }

    .mT50_pc {
        margin-top: 50px !important;
    }

    .mL0_pc {
        margin-left: 0px !important;
    }

    .mL5_pc {
        margin-left: 5px !important;
    }

    .mL10_pc {
        margin-left: 10px !important;
    }

    .mL20_pc {
        margin-left: 20px !important;
    }

    .mL30_pc {
        margin-left: 30px !important;
    }

    .mL40_pc {
        margin-left: 40px !important;
    }

    .mL50_pc {
        margin-left: 50px !important;
    }

    .mR0_pc {
        margin-right: 0px !important;
    }

    .mR5_pc {
        margin-right: 5px !important;
    }

    .mR10_pc {
        margin-right: 10px !important;
    }

    .mR20_pc {
        margin-right: 20px !important;
    }

    .mR30_pc {
        margin-right: 30px !important;
    }

    .mR40_pc {
        margin-right: 40px !important;
    }

    .mR50_pc {
        margin-right: 50px !important;
    }

    .size12 {
        font-size: 12px !important;
    }

    .size14 {
        font-size: 14px !important;
    }

    .size16 {
        font-size: 16px !important;
    }

    .size18 {
        font-size: 18px !important;
    }

    .size20 {
        font-size: 20px !important;
    }
}

.bold {
    font-weight: bold !important;
}

.bdNone {
    border: none !important;
}

.underline {
    text-decoration: underline !important;
}

.textOrange {
    color: $c_orange !important;
}

.marker {
    background: linear-gradient(transparent 60%, #ffd4d5 60%) !important;
}

.floatL {
    float: left;
}

.floatR {
    float: right;
}

.preBox {
    background: #eff0f4;
    border: 1px solid $c_base;
    padding: 15px;
    box-sizing: border-box;
    clear: both;
    overflow: hidden;
    margin-top: 30px;
    font-family: Arial, sans-serif;
    line-height: 1.8;

    pre {
        overflow: auto;
        white-space: pre-wrap;
        word-wrap: break-word;
        text-align-last: auto;
    }
}