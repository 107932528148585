@charset "UTF-8";
/* --------------- variables --------------------- */
/* Break Point */
/* Color */
/*サイトカラー*/
/*パープル*/
/*オレンジ*/
/*オレンジ*/
/* other */
/*transition-timing-function*/
main a {
  color: #5659f1;
  text-decoration: underline;
}

h1,
h2,
h3,
h4,
h5 {
  line-height: 1.5;
}

.pageTitleWrap {
  width: 100%;
  color: #192f60;
  position: relative;
}
.pageTitleWrap .pageTitleInner {
  position: relative;
}
.pageTitleWrap .pageTitleJp {
  font-weight: bold;
}
.pageTitleWrap .pageTitleEn {
  font-weight: 500;
  font-family: "Jost", sans-serif;
  line-height: 1.3;
  text-transform: capitalize;
}
.pageTitleWrap .tag {
  display: inline-block;
  color: #9e9e9e;
  border: 1px solid #9e9e9e;
  border-radius: 3px;
  padding: 3px 3px;
  line-height: 1;
  margin-bottom: 5px;
}
.pageTitleWrap .subTitle {
  display: inline-block;
  line-height: 1;
  margin-bottom: 5px;
}

.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
}
.breadcrumb li {
  position: relative;
  color: #17184b;
}
.breadcrumb li a {
  color: #17184b;
}

#contents {
  line-height: 1.8;
}
#contents.bgGray {
  background-color: #f8f8f8;
}
#contents #recommend {
  padding-bottom: 0;
}
#contents:has(#faq) {
  padding: 0;
}

.articleList .articleListItem figure img {
  width: 100%;
}
.articleList .articleListItem > a {
  display: block;
  color: #17184b;
  text-decoration: none;
}
.articleList .articleListItem .articleListTitle {
  color: #17184b;
  font-weight: bold;
}
.articleList .articleListItem .articleListSub {
  color: #17184b;
}

.articleNav a {
  color: #17184b;
  text-align: center;
  border: 1px solid #17184b;
  border-radius: 3px;
  text-decoration: none;
  font-weight: bold;
}
.articleNav a.current {
  background-color: #17184b;
  color: #fff;
}

.pager ul {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  list-style-type: none;
}
.pager ul li {
  font-family: "Jost", sans-serif;
  color: #17184b;
  line-height: 1;
  font-weight: bold;
}
.pager ul li a {
  color: #17184b;
  text-decoration: none;
  padding: 8px 8px;
  display: inline-block;
  position: relative;
  border-bottom: 1px solid #e0e0e0;
  transition: 0.3s;
}
.pager ul .current {
  color: #17184b;
  padding: 8px 8px;
  display: inline-block;
  position: relative;
  border-bottom: 1px solid #17184b;
}
.pager ul .prev,
.pager ul .next {
  position: relative;
  border: none;
}
.pager ul .prev:before {
  content: "";
  position: absolute;
  top: -2px;
  bottom: 0;
  width: 15px;
  height: 13px;
  left: 0px;
  margin: auto;
  background: url(../images/common/icon_arrow.svg) no-repeat;
  transform: rotate(180deg);
  background-size: contain;
  transition: all 0.3s;
}
.pager ul .next:before {
  content: "";
  position: absolute;
  top: -2px;
  bottom: 0;
  width: 15px;
  height: 13px;
  right: 0px;
  margin: auto;
  background: url(../images/common/icon_arrow.svg) no-repeat;
  background-size: contain;
  transition: all 0.3s;
}

.prevNextLink {
  display: flex;
  justify-content: space-between;
  border-top: 1px solid #17184b;
}
.prevNextLink a {
  text-decoration: none;
  color: #17184b;
}
.prevNextLink .prev a {
  position: relative;
}
.prevNextLink .next a {
  position: relative;
}

.whiteBox {
  background-color: #fff;
  box-shadow: 6px 5px 12px 2px #cccccc, -8px -9px 11px 5px #ffffff;
}

.formWrap p[class*=is-error] {
  margin-top: 10px;
  margin-bottom: 0;
}
.formWrap .is-error {
  color: #ff0000;
  font-weight: bold;
}
.formWrap input[type=text],
.formWrap input[type=password],
.formWrap input[type=tel],
.formWrap input[type=email],
.formWrap select {
  width: 100%;
  border: 1px solid #d0d0d0;
  border-radius: 3px;
  padding: 5px 15px;
}
.formWrap input[type=text]::placeholder,
.formWrap input[type=password]::placeholder,
.formWrap input[type=tel]::placeholder,
.formWrap input[type=email]::placeholder,
.formWrap select::placeholder {
  color: #d0d0d0;
}
.formWrap input[type=text].is-error,
.formWrap input[type=password].is-error,
.formWrap input[type=tel].is-error,
.formWrap input[type=email].is-error,
.formWrap select.is-error {
  font-weight: normal;
}
.formWrap input[type=checkbox] {
  display: none;
}
.formWrap input[type=checkbox] + label {
  position: relative;
  display: inline-block;
  padding: 3px 3px 3px 25px;
  cursor: pointer;
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
}
.formWrap input[type=checkbox] + label::before,
.formWrap input[type=checkbox] + label::after {
  position: absolute;
  content: "";
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
}
.formWrap input[type=checkbox] + label::before {
  top: 50%;
  left: 0;
  width: 16px;
  height: 16px;
  margin-top: -8px;
  background: #fff;
  border: 1px solid #ccc;
  border-radius: 3px;
}
.formWrap input[type=checkbox] + label::after {
  opacity: 0;
  top: 50%;
  left: 3px;
  width: 10px;
  height: 5px;
  margin-top: -4px;
  border-left: 2px solid #17184b;
  border-bottom: 2px solid #17184b;
  -webkit-transform: rotate(-45deg) scale(0.5);
  transform: rotate(-45deg) scale(0.5);
}
.formWrap input[type=checkbox] + label:hover::before {
  background: #fff;
}
.formWrap input[type=checkbox]:checked + label::before {
  background: #fff;
  border: 1px solid #17184b;
}
.formWrap input[type=checkbox]:checked + label::after {
  opacity: 1;
  -webkit-transform: rotate(-45deg) scale(1);
  transform: rotate(-45deg) scale(1);
}
.formWrap input[type=submit].commonBtn {
  background-image: url(../images/common/icon_arrow_white.svg);
  background-repeat: no-repeat;
  background-position: center right 24px;
}
.formWrap dl dd {
  margin-top: 15px;
}
.formWrap .hissu {
  color: #fff;
  background-color: #f07800;
  display: inline-block;
  line-height: 1;
  padding: 3px 8px;
  margin-left: 8px;
  transform: translateY(-2px);
}
.formWrap br {
  display: none;
}
.formWrap br + label,
.formWrap label + input {
  display: block;
  margin-top: 15px;
}
.formWrap input[type=submit] {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 3px;
  background-color: #f07800;
  color: #fff;
  border: 1px solid #e8651a;
  box-shadow: 2px 2px 0 0 #e8651a;
  margin-left: auto;
  margin-right: auto;
  margin: 0 auto;
  font-weight: bold;
  position: relative;
  text-decoration: none;
  text-align: center;
  transition: 0.3s background, 0.3s color, 0.3s box-shadow cubic-bezier(0.25, 0.1, 0, 0.72);
}
.formWrap input[type=submit]::before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  width: 15px;
  height: 13px;
  right: 16px;
  margin: auto;
  background: url(../images/common/icon_arrow.svg) no-repeat;
  background-image: url(../images/common/icon_arrow_white.svg);
  background-size: contain;
  transition: all 0.3s;
}
.formWrap input[type=submit]:disabled {
  background-color: #d8d8d8;
  border-color: #b1b1b1;
  box-shadow: 2px 2px 0 0 #b1b1b1;
  color: #939395;
}

.featureList .featureListItem .featureTextWrap {
  position: relative;
  z-index: 2;
}
.featureList .featureListItem .featureTextWrap .subTitle {
  font-family: "Jost", sans-serif;
  letter-spacing: 0.1em;
  position: relative;
}
.featureList .featureListItem .featureTextWrap h2 {
  font-weight: bold;
}
#caseStudy h2 {
  text-align: center;
}
#recommend {
  margin: 0;
}
#recommend h2 {
  text-align: center;
}
#recommend.faqTop {
  background-color: #f8f8f8;
}

.solarAbout {
  color: #fff;
  background: url(../images/second/solar_bg.png) no-repeat center;
  background-size: cover;
  width: 100%;
  margin-top: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
}
.solarAbout h2 {
  font-weight: bold;
}
.solarAbout .fvBtnList li {
  position: relative;
}
.solarAbout .fvBtnList li a {
  text-decoration: none;
  color: #fff;
  padding: 0;
  display: block;
  font-weight: bold;
  text-align: center;
  border-radius: 3px;
}
.solarAbout .fvBtnList li a span {
  position: relative;
}
.solarAbout .fvBtnList li a .icon {
  margin-right: 25px;
  display: inline-block;
}
.solarAbout .fvBtnList li a .icon::before {
  content: "";
  background-repeat: no-repeat;
  background-size: contain;
  display: block;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}
.solarAbout .fvBtnList li a .icon.iconMaterial::before {
  background-image: url(../images/common/icon_document_white.svg);
}
.solarAbout .fvBtnList li a .icon.iconMail::before {
  background-image: url(../images/common/icon_mail_white.svg);
}
.solarAbout .fvBtnList li.btnMaterial a {
  background-color: #f07800;
  border: 1px solid #fff;
  transition: 0.3s background, 0.3s color cubic-bezier(0.25, 0.1, 0, 0.72);
}
.solarAbout .fvBtnList li.btnContact a {
  background-color: #17184b;
  border: 1px solid #fff;
  color: #fff;
  transition: 0.3s background, 0.3s color cubic-bezier(0.25, 0.1, 0, 0.72);
}
.solarAbout .fvBtnList li.btnBlue a {
  background-color: #17184b;
  border: 1px solid #fff;
  color: #fff;
  transition: 0.3s background, 0.3s color cubic-bezier(0.25, 0.1, 0, 0.72);
  position: relative;
}
.solarAbout .fvBtnList li.btnBlue a:before {
  content: "";
  position: absolute;
  top: 2px;
  bottom: 0;
  width: 15px;
  height: 13px;
  right: 10px;
  margin: auto;
  background: url(../images/common/icon_arrow_white.svg) no-repeat;
  background-size: contain;
  transition: all 0.3s;
}

.meritWrap h3 {
  border-bottom: 1px solid #17184b;
  position: relative;
}
.meritWrap h3 .meritNum {
  font-family: "Jost", sans-serif;
  position: absolute;
  line-height: 1;
  font-weight: 500;
  top: -3px;
  left: 0;
}
.meritWrap h3 .meritNum span {
  display: block;
}
.meritWrap .meritCont figure {
  text-align: center;
}
.meritWrap .meritCont .textWrap h4 {
  font-weight: bold;
  position: relative;
}
.teianH2 {
  background-color: #17184b;
  color: #fff;
}

.teianSystem .teianSystemItem {
  box-shadow: 6px 5px 12px 2px #cccccc, -8px -9px 11px 5px #ffffff;
}
.teianSystem .teianSystemItem h3 {
  text-align: center;
  background-color: #f8f8f8;
}
.teianSystem .teianSystemItem .teianSystemCont {
  background-color: #fff;
  text-align: center;
}

#contents .gmap iframe {
  width: 100%;
}
#contents .commonImgBox {
  display: flex;
}
#contents .cardListWrap .cardList {
  display: flex;
  justify-content: space-between;
  flex-flow: wrap;
}
#contents .cardListWrap li {
  border: 1px solid #aaaaaa;
  background-color: #fff;
}
#contents .cardListWrap a {
  display: flex;
  flex-flow: nowrap;
  background-color: #fff;
  text-decoration: none;
}
#contents .cardListWrap .txt {
  position: relative;
}
#contents .cardListWrap .txt .ttl {
  color: #151515;
  font-size: 17px;
  width: 100%;
}
#contents .cardListWrap .txt .viewmore {
  color: #192f60;
  display: inline-block;
  font-family: "Jost", sans-serif;
  font-weight: 500;
  text-align: right;
}
#contents .cardListWrap .txt .viewmore::before {
  content: "";
  display: block;
  background: #192f60;
  position: absolute;
  z-index: 2;
  transform-origin: right;
  transform: translateY(-50%) scaleX(1);
  transition: 0.3s all cubic-bezier(0.25, 0.1, 0, 0.72);
}
#contents .leadTtl {
  border-bottom: none;
  text-align: center;
}
#contents .mainTtl1 {
  font-weight: bold;
  text-align: center;
}
#contents .subTtl {
  display: block;
  color: #f07800;
  font-family: "Jost", sans-serif;
  letter-spacing: 1px;
}
#contents .rinenListWrap .rinenBorder {
  width: 100%;
}
#contents .rinenListWrap .rinenList {
  width: 100%;
}
#contents .rinenListWrap .rinenList dt {
  color: #f07800;
  font-weight: bold;
  font-size: 24px;
}
#contents .subTtl + .mainTtl1 {
  margin-top: 0;
}

.main_solar #contents,
.main_solar-test #contents {
  padding-top: 0;
}

.numberWrap .subTtl {
  color: #17184b;
  font-weight: 500;
  text-transform: capitalize;
}

.voiceList a.voiceCont {
  display: block;
  text-decoration: none;
  color: #17184b;
}
.voiceList a.voiceCont .voiceSubTitle {
  color: #f07800;
  font-weight: 500;
  letter-spacing: 0.05rem;
}
.voiceList a.voiceCont .voiceSubTitle .number {
  font-family: "Jost", sans-serif;
  font-weight: 600;
}
.voiceList a.voiceCont .voiceImg {
  overflow: hidden;
}
.voiceList a.voiceCont .voiceImg img {
  object-fit: cover;
  transition: 0.3s transform cubic-bezier(0.25, 0.1, 0, 0.72);
}
.voiceList a.voiceCont .btnWrap {
  position: relative;
  margin: 0;
  text-align: right;
}
.voiceList a.voiceCont .btnWrap::after {
  content: "";
  display: block;
  background: url(../images/common/icon_arrow_purple.svg) no-repeat center/100%;
  position: absolute;
}
.voiceList a.voiceCont .viewMore {
  position: relative;
  display: inline-block;
  font-family: "Jost", sans-serif;
  font-weight: 600;
}
.voiceList a.voiceCont .viewMore::before {
  content: "";
  display: block;
  background-color: #c1c1d0;
  width: 100%;
  height: 1px;
  position: absolute;
  left: 0;
  bottom: -3px;
}
.main_contact .headDeco {
  display: none;
}

.aform input[type=text].error,
.aform input[type=password].error,
.aform input[type=tel].error,
.aform input[type=email].error,
.aform select.error,
.aform textarea.error {
  background-color: #fff1f2;
}
.aform input[type=radio] {
  display: none;
}
.aform input[type=radio] + label {
  position: relative;
  display: inline-block;
  padding: 3px 3px 3px 25px;
  cursor: pointer;
  margin-right: 20px;
}
.aform input[type=radio] + label::before,
.aform input[type=radio] + label::after {
  position: absolute;
  content: "";
  top: 50%;
  border-radius: 100%;
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
}
.aform input[type=radio] + label::before {
  left: 0;
  width: 17px;
  height: 17px;
  margin-top: -10px;
  background: #fff;
  border: 1px solid #ccc;
}
.aform input[type=radio] + label:hover::before {
  background: #fff;
}
.aform input[type=radio] + label::after {
  opacity: 0;
  left: 4px;
  width: 9px;
  height: 9px;
  margin-top: -6px;
  background: #5dbf5d;
  -webkit-transform: scale(2);
  transform: scale(2);
}
.aform input[type=radio]:checked + label::before {
  background: #fff;
  border: 1px solid #5dbf5d;
}
.aform input[type=radio]:checked + label::after {
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1);
}
.aform input[type=checkbox] {
  opacity: 0;
  width: 1px;
  height: 1px;
}
.aform input[type=checkbox] + label {
  position: relative;
  display: inline-block;
  padding: 3px 3px 3px 40px;
  cursor: pointer;
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
}
.aform input[type=checkbox] + label::before,
.aform input[type=checkbox] + label::after {
  position: absolute;
  content: "";
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
}
.aform input[type=checkbox] + label::before {
  top: 50%;
  left: 0;
  width: 20px;
  height: 20px;
  margin-top: -11px;
  background: #fff;
  border: 2px solid #ccc;
  border-radius: 3px;
}
.aform input[type=checkbox] + label::after {
  opacity: 0;
  border-left: 2px solid #17184b;
  border-bottom: 2px solid #17184b;
  -webkit-transform: rotate(-45deg) scale(0.5);
  transform: rotate(-45deg) scale(0.5);
}
.aform input[type=checkbox] + label:hover::before {
  background: #fff;
}
.aform input[type=checkbox]:checked + label::before {
  background: #fff;
  border: 1px solid #ccc;
}
.aform input[type=checkbox]:checked + label::after {
  opacity: 1;
  -webkit-transform: rotate(-45deg) scale(1);
  transform: rotate(-45deg) scale(1);
}
.form_dt {
  display: block;
  font-weight: bold;
}

.aform .aform-content .aform-note {
  background: none;
  margin-top: 20px;
  margin-bottom: 20px;
}

.aform .aform-content .aform-button-area input[type=submit] {
  cursor: pointer;
  outline: none;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #17184b;
  border-radius: 3px;
  background-color: #fff;
  background-image: url(../images/common/icon_arrow.svg);
  background-repeat: no-repeat;
  background-size: 15px 13px;
  background-position: right 16px center;
  color: #17184b;
  margin-left: auto;
  margin-right: auto;
  font-weight: bold;
  position: relative;
  text-decoration: none;
  text-align: center;
  transition: 0.3s background, 0.3s color cubic-bezier(0.25, 0.1, 0, 0.72);
}

#aform_btn_back {
  font-size: 16px;
  order: 2;
}

.aform .aform-content dl.aform-error-list {
  border: none;
}
.aform .aform-content dl.aform-error-list dt {
  display: block;
}
.aform .aform-content dl.aform-error-list dd {
  display: list-item;
  margin-left: 25px;
  width: auto;
}

#aform_confirm_msg {
  text-align: center;
  padding-top: 20px;
}

img[src$="icon_new_windows.gif"] {
  margin-left: 10px;
  vertical-align: middle;
}

.aform-header {
  display: none;
}

.aform #aform_result {
  color: #000;
}
.aform #aform_result a.commonBtn {
  margin: 20px auto 30px;
}

/*!
	Modaal - accessible modals - v0.4.4
	by Humaan, for all humans.
	http://humaan.com
 */
.modaal-noscroll {
  overflow: hidden;
}

.modaal-accessible-hide {
  position: absolute !important;
  clip: rect(1px 1px 1px 1px); /* IE6, IE7 */
  clip: rect(1px, 1px, 1px, 1px);
  padding: 0 !important;
  border: 0 !important;
  height: 1px !important;
  width: 1px !important;
  overflow: hidden;
}

.modaal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999;
  opacity: 0;
}

.modaal-wrapper {
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;
  overflow: auto;
  opacity: 1;
  box-sizing: border-box;
  -webkit-overflow-scrolling: touch;
  transition: all 0.3s ease-in-out;
}
.modaal-wrapper * {
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-backface-visibility: hidden;
}
.modaal-wrapper .modaal-close {
  border: none;
  background: transparent;
  padding: 0;
  -webkit-appearance: none;
}
.modaal-wrapper.modaal-start_none {
  display: none;
  opacity: 1;
}
.modaal-wrapper.modaal-start_fade {
  opacity: 0;
}
.modaal-wrapper *[tabindex="0"] {
  outline: none !important;
}
.modaal-wrapper.modaal-fullscreen {
  overflow: hidden;
}

.modaal-outer-wrapper {
  display: table;
  position: relative;
  width: 100%;
  height: 100%;
}
.modaal-fullscreen .modaal-outer-wrapper {
  display: block;
}

.modaal-inner-wrapper {
  display: table-cell;
  width: 100%;
  height: 100%;
  position: relative;
  vertical-align: middle;
  text-align: center;
  padding: 80px 25px;
}
.modaal-fullscreen .modaal-inner-wrapper {
  padding: 0;
  display: block;
  vertical-align: top;
}

.modaal-container {
  position: relative;
  display: inline-block;
  width: 100%;
  margin: auto;
  text-align: left;
  color: #000;
  max-width: 1000px;
  border-radius: 0px;
  background: #fff;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
  cursor: auto;
}
.modaal-container.is_loading {
  height: 100px;
  width: 100px;
  overflow: hidden;
}
.modaal-fullscreen .modaal-container {
  max-width: none;
  height: 100%;
  overflow: auto;
}

.modaal-close {
  position: fixed;
  right: 20px;
  top: 20px;
  color: #fff;
  cursor: pointer;
  opacity: 1;
  width: 50px;
  height: 50px;
  background: rgba(0, 0, 0, 0);
  border-radius: 100%;
  transition: all 0.2s ease-in-out;
}
.modaal-close:focus, .modaal-close:hover {
  outline: none;
  background: #fff;
}
.modaal-close:focus:before, .modaal-close:focus:after, .modaal-close:hover:before, .modaal-close:hover:after {
  background: #b93d0c;
}
.modaal-close span {
  position: absolute !important;
  clip: rect(1px 1px 1px 1px); /* IE6, IE7 */
  clip: rect(1px, 1px, 1px, 1px);
  padding: 0 !important;
  border: 0 !important;
  height: 1px !important;
  width: 1px !important;
  overflow: hidden;
}
.modaal-close:before, .modaal-close:after {
  display: block;
  content: " ";
  position: absolute;
  top: 14px;
  left: 23px;
  width: 4px;
  height: 22px;
  border-radius: 4px;
  background: #fff;
  transition: background 0.2s ease-in-out;
}
.modaal-close:before {
  transform: rotate(-45deg);
}
.modaal-close:after {
  transform: rotate(45deg);
}
.modaal-fullscreen .modaal-close {
  background: #afb7bc;
  right: 10px;
  top: 10px;
}

.modaal-content-container {
  padding: 30px;
}

.modaal-confirm-wrap {
  padding: 30px 0 0;
  text-align: center;
  font-size: 0;
}

.modaal-confirm-btn {
  font-size: 14px;
  display: inline-block;
  margin: 0 10px;
  vertical-align: middle;
  cursor: pointer;
  border: none;
  background: transparent;
}
.modaal-confirm-btn.modaal-ok {
  padding: 10px 15px;
  color: #fff;
  background: #555;
  border-radius: 3px;
  transition: background 0.2s ease-in-out;
}
.modaal-confirm-btn.modaal-ok:hover {
  background: #2f2f2f;
}
.modaal-confirm-btn.modaal-cancel {
  text-decoration: underline;
}
.modaal-confirm-btn.modaal-cancel:hover {
  text-decoration: none;
  color: #2f2f2f;
}

@keyframes instaReveal {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-o-keyframes instaReveal {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-moz-keyframes instaReveal {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-webkit-keyframes instaReveal {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-ms-keyframes instaReveal {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.modaal-instagram .modaal-container {
  width: auto;
  background: transparent;
  box-shadow: none !important;
}
.modaal-instagram .modaal-content-container {
  padding: 0;
  background: transparent;
}
.modaal-instagram .modaal-content-container > blockquote {
  width: 1px !important;
  height: 1px !important;
  opacity: 0 !important;
}
.modaal-instagram iframe {
  opacity: 0;
  margin: -6px !important;
  border-radius: 0 !important;
  width: 1000px !important;
  max-width: 800px !important;
  box-shadow: none !important;
  animation: instaReveal 1s linear forwards;
}

.modaal-image .modaal-inner-wrapper {
  padding-left: 140px;
  padding-right: 140px;
}
.modaal-image .modaal-container {
  width: auto;
  max-width: 100%;
}

.modaal-gallery-wrap {
  position: relative;
  color: #fff;
}

.modaal-gallery-item {
  display: none;
}
.modaal-gallery-item img {
  display: block;
}
.modaal-gallery-item.is_active {
  display: block;
}

.modaal-gallery-label {
  position: absolute;
  left: 0;
  width: 100%;
  margin: 20px 0 0;
  font-size: 18px;
  text-align: center;
  color: #fff;
}
.modaal-gallery-label:focus {
  outline: none;
}

.modaal-gallery-control {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  opacity: 1;
  cursor: pointer;
  color: #fff;
  width: 50px;
  height: 50px;
  background: rgba(0, 0, 0, 0);
  border: none;
  border-radius: 100%;
  transition: all 0.2s ease-in-out;
}
.modaal-gallery-control.is_hidden {
  opacity: 0;
  cursor: default;
}
.modaal-gallery-control:focus, .modaal-gallery-control:hover {
  outline: none;
  background: #fff;
}
.modaal-gallery-control:focus:before, .modaal-gallery-control:focus:after, .modaal-gallery-control:hover:before, .modaal-gallery-control:hover:after {
  background: #afb7bc;
}
.modaal-gallery-control span {
  position: absolute !important;
  clip: rect(1px 1px 1px 1px); /* IE6, IE7 */
  clip: rect(1px, 1px, 1px, 1px);
  padding: 0 !important;
  border: 0 !important;
  height: 1px !important;
  width: 1px !important;
  overflow: hidden;
}
.modaal-gallery-control:before, .modaal-gallery-control:after {
  display: block;
  content: " ";
  position: absolute;
  top: 16px;
  left: 25px;
  width: 4px;
  height: 18px;
  border-radius: 4px;
  background: #fff;
  transition: background 0.2s ease-in-out;
}
.modaal-gallery-control:before {
  margin: -5px 0 0;
  transform: rotate(-45deg);
}
.modaal-gallery-control:after {
  margin: 5px 0 0;
  transform: rotate(45deg);
}

.modaal-gallery-next-inner {
  left: 100%;
  margin-left: 40px;
}
.modaal-gallery-next-outer {
  right: 45px;
}

.modaal-gallery-prev:before, .modaal-gallery-prev:after {
  left: 22px;
}
.modaal-gallery-prev:before {
  margin: 5px 0 0;
  transform: rotate(-45deg);
}
.modaal-gallery-prev:after {
  margin: -5px 0 0;
  transform: rotate(45deg);
}
.modaal-gallery-prev-inner {
  right: 100%;
  margin-right: 40px;
}
.modaal-gallery-prev-outer {
  left: 45px;
}

.modaal-video-wrap {
  margin: auto 50px;
  position: relative;
}

.modaal-video-container {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden;
  max-width: 100%;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  background: #000;
  max-width: 1300px;
  margin-left: auto;
  margin-right: auto;
}
.modaal-video-container iframe,
.modaal-video-container object,
.modaal-video-container embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.modaal-iframe .modaal-content {
  width: 100%;
  height: 100%;
}

.modaal-iframe-elem {
  width: 100%;
  height: 100%;
  display: block;
}

@media only screen and (min-width: 1400px) {
  .modaal-video-container {
    padding-bottom: 0;
    height: 731px;
  }
}
@media only screen and (max-width: 1140px) {
  .modaal-image .modaal-inner-wrapper {
    padding-left: 25px;
    padding-right: 25px;
  }
  .modaal-gallery-control {
    top: auto;
    bottom: 20px;
    transform: none;
    background: rgba(0, 0, 0, 0.7);
  }
  .modaal-gallery-control:before, .modaal-gallery-control:after {
    background: #fff;
  }
  .modaal-gallery-next {
    left: auto;
    right: 20px;
  }
  .modaal-gallery-prev {
    left: 20px;
    right: auto;
  }
}
@media screen and (max-width: 900px) {
  .modaal-instagram iframe {
    width: 500px !important;
  }
}
@media screen and (max-height: 1100px) {
  .modaal-instagram iframe {
    width: 700px !important;
  }
}
@media screen and (max-height: 1000px) {
  .modaal-inner-wrapper {
    padding-top: 60px;
    padding-bottom: 60px;
  }
  .modaal-instagram iframe {
    width: 600px !important;
  }
}
@media screen and (max-height: 900px) {
  .modaal-instagram iframe {
    width: 500px !important;
  }
  .modaal-video-container {
    max-width: 900px;
    max-height: 510px;
  }
}
@media only screen and (max-width: 600px) {
  .modaal-instagram iframe {
    width: 280px !important;
  }
}
@media only screen and (max-height: 820px) {
  .modaal-gallery-label {
    display: none;
  }
}
.modaal-loading-spinner {
  background: none;
  position: absolute;
  width: 200px;
  height: 200px;
  top: 50%;
  left: 50%;
  margin: -100px 0 0 -100px;
  transform: scale(0.25);
}

@-ms-keyframes modaal-loading-spinner {
  0% {
    opacity: 1;
    -ms-transform: scale(1.5);
    -moz-transform: scale(1.5);
    -webkit-transform: scale(1.5);
    -o-transform: scale(1.5);
    transform: scale(1.5);
  }
  100% {
    opacity: 0.1;
    -ms-transform: scale(1);
    -moz-transform: scale(1);
    -webkit-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
  }
}
@-moz-keyframes modaal-loading-spinner {
  0% {
    opacity: 1;
    -ms-transform: scale(1.5);
    -moz-transform: scale(1.5);
    -webkit-transform: scale(1.5);
    -o-transform: scale(1.5);
    transform: scale(1.5);
  }
  100% {
    opacity: 0.1;
    -ms-transform: scale(1);
    -moz-transform: scale(1);
    -webkit-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
  }
}
@-webkit-keyframes modaal-loading-spinner {
  0% {
    opacity: 1;
    -ms-transform: scale(1.5);
    -moz-transform: scale(1.5);
    -webkit-transform: scale(1.5);
    -o-transform: scale(1.5);
    transform: scale(1.5);
  }
  100% {
    opacity: 0.1;
    -ms-transform: scale(1);
    -moz-transform: scale(1);
    -webkit-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
  }
}
@-o-keyframes modaal-loading-spinner {
  0% {
    opacity: 1;
    -ms-transform: scale(1.5);
    -moz-transform: scale(1.5);
    -webkit-transform: scale(1.5);
    -o-transform: scale(1.5);
    transform: scale(1.5);
  }
  100% {
    opacity: 0.1;
    -ms-transform: scale(1);
    -moz-transform: scale(1);
    -webkit-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
  }
}
@keyframes modaal-loading-spinner {
  0% {
    opacity: 1;
    -ms-transform: scale(1.5);
    -moz-transform: scale(1.5);
    -webkit-transform: scale(1.5);
    -o-transform: scale(1.5);
    transform: scale(1.5);
  }
  100% {
    opacity: 0.1;
    -ms-transform: scale(1);
    -moz-transform: scale(1);
    -webkit-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
  }
}
.modaal-loading-spinner > div {
  width: 24px;
  height: 24px;
  margin-left: 4px;
  margin-top: 4px;
  position: absolute;
}

.modaal-loading-spinner > div > div {
  width: 100%;
  height: 100%;
  border-radius: 15px;
  background: #fff;
}

.modaal-loading-spinner > div:nth-of-type(1) > div {
  -ms-animation: modaal-loading-spinner 1s linear infinite;
  -moz-animation: modaal-loading-spinner 1s linear infinite;
  -webkit-animation: modaal-loading-spinner 1s linear infinite;
  -o-animation: modaal-loading-spinner 1s linear infinite;
  animation: modaal-loading-spinner 1s linear infinite;
  -ms-animation-delay: 0s;
  -moz-animation-delay: 0s;
  -webkit-animation-delay: 0s;
  -o-animation-delay: 0s;
  animation-delay: 0s;
}

.modaal-loading-spinner > div:nth-of-type(2) > div, .modaal-loading-spinner > div:nth-of-type(3) > div {
  -ms-animation: modaal-loading-spinner 1s linear infinite;
  -moz-animation: modaal-loading-spinner 1s linear infinite;
  -webkit-animation: modaal-loading-spinner 1s linear infinite;
  -o-animation: modaal-loading-spinner 1s linear infinite;
}

.modaal-loading-spinner > div:nth-of-type(1) {
  -ms-transform: translate(84px, 84px) rotate(45deg) translate(70px, 0);
  -moz-transform: translate(84px, 84px) rotate(45deg) translate(70px, 0);
  -webkit-transform: translate(84px, 84px) rotate(45deg) translate(70px, 0);
  -o-transform: translate(84px, 84px) rotate(45deg) translate(70px, 0);
  transform: translate(84px, 84px) rotate(45deg) translate(70px, 0);
}

.modaal-loading-spinner > div:nth-of-type(2) > div {
  animation: modaal-loading-spinner 1s linear infinite;
  -ms-animation-delay: 0.12s;
  -moz-animation-delay: 0.12s;
  -webkit-animation-delay: 0.12s;
  -o-animation-delay: 0.12s;
  animation-delay: 0.12s;
}

.modaal-loading-spinner > div:nth-of-type(2) {
  -ms-transform: translate(84px, 84px) rotate(90deg) translate(70px, 0);
  -moz-transform: translate(84px, 84px) rotate(90deg) translate(70px, 0);
  -webkit-transform: translate(84px, 84px) rotate(90deg) translate(70px, 0);
  -o-transform: translate(84px, 84px) rotate(90deg) translate(70px, 0);
  transform: translate(84px, 84px) rotate(90deg) translate(70px, 0);
}

.modaal-loading-spinner > div:nth-of-type(3) > div {
  animation: modaal-loading-spinner 1s linear infinite;
  -ms-animation-delay: 0.25s;
  -moz-animation-delay: 0.25s;
  -webkit-animation-delay: 0.25s;
  -o-animation-delay: 0.25s;
  animation-delay: 0.25s;
}

.modaal-loading-spinner > div:nth-of-type(4) > div, .modaal-loading-spinner > div:nth-of-type(5) > div {
  -ms-animation: modaal-loading-spinner 1s linear infinite;
  -moz-animation: modaal-loading-spinner 1s linear infinite;
  -webkit-animation: modaal-loading-spinner 1s linear infinite;
  -o-animation: modaal-loading-spinner 1s linear infinite;
}

.modaal-loading-spinner > div:nth-of-type(3) {
  -ms-transform: translate(84px, 84px) rotate(135deg) translate(70px, 0);
  -moz-transform: translate(84px, 84px) rotate(135deg) translate(70px, 0);
  -webkit-transform: translate(84px, 84px) rotate(135deg) translate(70px, 0);
  -o-transform: translate(84px, 84px) rotate(135deg) translate(70px, 0);
  transform: translate(84px, 84px) rotate(135deg) translate(70px, 0);
}

.modaal-loading-spinner > div:nth-of-type(4) > div {
  animation: modaal-loading-spinner 1s linear infinite;
  -ms-animation-delay: 0.37s;
  -moz-animation-delay: 0.37s;
  -webkit-animation-delay: 0.37s;
  -o-animation-delay: 0.37s;
  animation-delay: 0.37s;
}

.modaal-loading-spinner > div:nth-of-type(4) {
  -ms-transform: translate(84px, 84px) rotate(180deg) translate(70px, 0);
  -moz-transform: translate(84px, 84px) rotate(180deg) translate(70px, 0);
  -webkit-transform: translate(84px, 84px) rotate(180deg) translate(70px, 0);
  -o-transform: translate(84px, 84px) rotate(180deg) translate(70px, 0);
  transform: translate(84px, 84px) rotate(180deg) translate(70px, 0);
}

.modaal-loading-spinner > div:nth-of-type(5) > div {
  animation: modaal-loading-spinner 1s linear infinite;
  -ms-animation-delay: 0.5s;
  -moz-animation-delay: 0.5s;
  -webkit-animation-delay: 0.5s;
  -o-animation-delay: 0.5s;
  animation-delay: 0.5s;
}

.modaal-loading-spinner > div:nth-of-type(6) > div, .modaal-loading-spinner > div:nth-of-type(7) > div {
  -ms-animation: modaal-loading-spinner 1s linear infinite;
  -moz-animation: modaal-loading-spinner 1s linear infinite;
  -webkit-animation: modaal-loading-spinner 1s linear infinite;
  -o-animation: modaal-loading-spinner 1s linear infinite;
}

.modaal-loading-spinner > div:nth-of-type(5) {
  -ms-transform: translate(84px, 84px) rotate(225deg) translate(70px, 0);
  -moz-transform: translate(84px, 84px) rotate(225deg) translate(70px, 0);
  -webkit-transform: translate(84px, 84px) rotate(225deg) translate(70px, 0);
  -o-transform: translate(84px, 84px) rotate(225deg) translate(70px, 0);
  transform: translate(84px, 84px) rotate(225deg) translate(70px, 0);
}

.modaal-loading-spinner > div:nth-of-type(6) > div {
  animation: modaal-loading-spinner 1s linear infinite;
  -ms-animation-delay: 0.62s;
  -moz-animation-delay: 0.62s;
  -webkit-animation-delay: 0.62s;
  -o-animation-delay: 0.62s;
  animation-delay: 0.62s;
}

.modaal-loading-spinner > div:nth-of-type(6) {
  -ms-transform: translate(84px, 84px) rotate(270deg) translate(70px, 0);
  -moz-transform: translate(84px, 84px) rotate(270deg) translate(70px, 0);
  -webkit-transform: translate(84px, 84px) rotate(270deg) translate(70px, 0);
  -o-transform: translate(84px, 84px) rotate(270deg) translate(70px, 0);
  transform: translate(84px, 84px) rotate(270deg) translate(70px, 0);
}

.modaal-loading-spinner > div:nth-of-type(7) > div {
  animation: modaal-loading-spinner 1s linear infinite;
  -ms-animation-delay: 0.75s;
  -moz-animation-delay: 0.75s;
  -webkit-animation-delay: 0.75s;
  -o-animation-delay: 0.75s;
  animation-delay: 0.75s;
}

.modaal-loading-spinner > div:nth-of-type(7) {
  -ms-transform: translate(84px, 84px) rotate(315deg) translate(70px, 0);
  -moz-transform: translate(84px, 84px) rotate(315deg) translate(70px, 0);
  -webkit-transform: translate(84px, 84px) rotate(315deg) translate(70px, 0);
  -o-transform: translate(84px, 84px) rotate(315deg) translate(70px, 0);
  transform: translate(84px, 84px) rotate(315deg) translate(70px, 0);
}

.modaal-loading-spinner > div:nth-of-type(8) > div {
  -ms-animation: modaal-loading-spinner 1s linear infinite;
  -moz-animation: modaal-loading-spinner 1s linear infinite;
  -webkit-animation: modaal-loading-spinner 1s linear infinite;
  -o-animation: modaal-loading-spinner 1s linear infinite;
  animation: modaal-loading-spinner 1s linear infinite;
  -ms-animation-delay: 0.87s;
  -moz-animation-delay: 0.87s;
  -webkit-animation-delay: 0.87s;
  -o-animation-delay: 0.87s;
  animation-delay: 0.87s;
}

.modaal-loading-spinner > div:nth-of-type(8) {
  -ms-transform: translate(84px, 84px) rotate(360deg) translate(70px, 0);
  -moz-transform: translate(84px, 84px) rotate(360deg) translate(70px, 0);
  -webkit-transform: translate(84px, 84px) rotate(360deg) translate(70px, 0);
  -o-transform: translate(84px, 84px) rotate(360deg) translate(70px, 0);
  transform: translate(84px, 84px) rotate(360deg) translate(70px, 0);
}

#toc_container li:before {
  content: none;
}

.singleContents {
  /* 投稿者情報
  -----------------------------------------*/
  /* メルマガ配信
  -----------------------------------------*/
}
.singleContents .has-inline-color.has-luminous-vivid-orange-color {
  color: #f07800 !important;
}
.singleContents .inner > *:first-child {
  margin-top: 0;
}
.singleContents h2 {
  border-bottom: 1px solid #17184b;
}
.singleContents h3 {
  font-weight: bold;
  border-left: 3px solid #17184b;
  position: relative;
}
.singleContents h3:before {
  content: "";
  display: inline-block;
  width: 3px;
  height: 50%;
  background-color: #dddddd;
  position: absolute;
  top: 0;
  left: -3px;
}
.singleContents h4 {
  font-weight: 500;
  position: relative;
  padding-bottom: 15px;
}
.singleContents h4:after {
  content: "";
  display: inline-block;
  background-color: #17184b;
  height: 1px;
  width: 20px;
  position: absolute;
  bottom: 0;
  left: 0;
  top: auto;
}
.singleContents ul {
  padding-left: 0 !important;
}
.singleContents ol {
  counter-reset: item;
  list-style-type: none;
  padding-left: 0;
}
.singleContents ol li {
  text-indent: -1.2em;
  padding-left: 1.2em;
}
.singleContents ol li:before {
  counter-increment: item;
  content: counter(item) ".";
  padding-right: 0.4em;
  font-weight: bold;
  font-family: "Jost", sans-serif;
}
.singleContents ol li:not(:first-of-type) {
  margin-top: 10px;
}
.singleContents ol ol {
  padding-left: 1em;
}
.singleContents p + .wp-block-gallery {
  margin-top: 10px;
}
.singleContents .wp-block-button a,
.singleContents .wp-block-button .wp-block-button__link {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #17184b;
  border-radius: 3px;
  background-color: #fff !important;
  color: #17184b !important;
  margin-left: auto;
  margin-right: auto;
  font-weight: bold;
  position: relative;
  text-decoration: none;
  text-align: center;
  transition: 0.3s background, 0.3s color cubic-bezier(0.25, 0.1, 0, 0.72);
}
.singleContents .wp-block-button a::before,
.singleContents .wp-block-button .wp-block-button__link::before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  width: 15px;
  height: 13px;
  right: 16px;
  margin: auto;
  background: url(../images/common/icon_arrow.svg) no-repeat;
  background-size: contain;
}
.singleContents .wp-block-button .wp-block-button__link {
  background-color: #17184b;
  border: 2px solid #17184b;
  transition: 0.3s color, 0.3s background cubic-bezier(0.25, 0.1, 0, 0.72);
}
.singleContents .wp-block-button.btnOrange .wp-block-button__link {
  background-color: #f07800 !important;
  color: #fff !important;
  border: 1px solid #e8651a;
}
.singleContents .wp-block-button.btnOrange .wp-block-button__link:before {
  background-image: url(../images/common/icon_arrow_white.svg);
}
.singleContents .wp-block-button.is-style-outline .wp-block-button__link {
  background-color: #fff;
}
.singleContents .wp-block-table {
  border-top: 1px solid #c1c1d0;
}
.singleContents .wp-block-table thead {
  border-bottom: none;
}
.singleContents .wp-block-table tr th {
  text-align: center;
  font-weight: normal;
  border: 1px solid #c1c1d0;
  border-left: none;
  border-top: none;
  background-color: #17184b;
  color: #fff;
}
.singleContents .wp-block-table tr th:last-child {
  margin-bottom: 0;
  border-right: none;
}
.singleContents .wp-block-table tr td {
  border-color: #c1c1d0;
  border-left: none;
  border-top: none;
}
.singleContents .wp-block-table tr td:last-child {
  margin-bottom: 0;
  border-right: none;
}
.singleContents .wp-block-table.is-style-stripes tbody tr:nth-child(odd) {
  background-color: #f8f8f8;
}
.singleContents .wp-block-columns .wp-block-image {
  margin-bottom: 10px;
}
.singleContents .wp-block-media-text .wp-block-media-text__content p {
  margin-top: 0;
}
.singleContents .colorBox {
  background-color: #f8f8f8;
}
.singleContents .colorBox .title {
  font-weight: 500;
  position: relative;
  padding-bottom: 15px;
}
.singleContents .colorBox .title:after {
  content: "";
  display: inline-block;
  background-color: #17184b;
  height: 1px;
  width: 20px;
  position: absolute;
  bottom: 0;
  left: 0;
}
.singleContents .profileBox {
  background-color: #f8f8f8;
  width: 100%;
}
.singleContents .profileBox .profileTitle {
  border-bottom: 1px solid #17184b;
  margin: 0 0 20px;
  padding: 0 0 10px;
}
.singleContents .profileBox .profileInner .infoArea {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
}
.singleContents .profileBox .profileInner .infoArea img {
  border-radius: 50%;
}
.singleContents .profileBox .profileInner .authorGroup {
  margin-top: 12px;
  text-align: center;
}
.singleContents .profileBox .profileInner .authorPosition {
  color: #494a81;
  font-weight: bold;
}
.singleContents .profileBox .profileInner .authorName {
  font-weight: bold;
  margin: 0 0 5px;
}
.singleContents .profileBox .profileInner .authorProfile {
  margin: 0;
}
.singleContents .profileBox .snsIcon {
  display: inline-block;
  padding-left: 26px;
  text-decoration: none;
}
.singleContents .profileBox .snsIcon:not(:last-child) {
  margin-right: 16px;
}
.singleContents .profileBox .snsIcon.twitter {
  background-image: url(../images/common/icon_twitter_blue.svg);
  background-repeat: no-repeat;
  background-position: center left;
  background-size: 20px auto;
}
.singleContents .profileBox .snsIcon.instagram {
  background-image: url(../images/common/icon_insta.png);
  background-repeat: no-repeat;
  background-position: center left;
  background-size: 20px auto;
}
.singleContents .magazineBox {
  text-align: center;
  background-color: #f8f8f8;
  width: 100%;
}
.singleContents .magazineBox .title {
  color: #17184b;
  font-weight: 500;
  transition: 0.3s color cubic-bezier(0.25, 0.1, 0, 0.72);
}
.singleContents .magazineBox .title .icon.iconMailMagazine {
  position: relative;
}
.singleContents .magazineBox .title .icon.iconMailMagazine::before {
  content: "";
  background: url(../images/common/icon_mailmagazine.svg) no-repeat center/100%;
  display: block;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
}
.singleContents .magazineBox .magagineTxt {
  margin-bottom: 10px;
}
.singleContents .magazineBox .magaginePolicy {
  margin-top: 0;
}
.singleContents .magazineBox .mailMagazine .magazineInput {
  max-width: 100%;
  border: 1px solid #d1d1d1;
  border-radius: 3px;
}
.singleContents .magazineBox .mailMagazine .magazineInput:focus {
  outline: none;
}
.singleContents .magazineBox .mailMagazine .magazineInput::placeholder {
  color: #d1d1d1;
}
.singleContents .magazineBox .mailMagazine .magazineSubmit {
  font-weight: bold;
  background-color: #fff;
  border: 1px solid #17184b;
  color: #17184b;
  border-radius: 3px;
}

h2.title {
  border-bottom: 1px solid #17184b;
}

h2.title2 {
  text-align: center;
}

h3.title {
  font-weight: bold;
  border-left: 3px solid #17184b;
  position: relative;
}
h3.title:before {
  content: "";
  display: inline-block;
  width: 3px;
  height: 50%;
  background-color: #dddddd;
  position: absolute;
  top: 0;
  left: -3px;
}

h4.title {
  font-weight: 500;
  position: relative;
  padding-bottom: 15px;
}
h4.title:after {
  content: "";
  display: inline-block;
  background-color: #17184b;
  height: 1px;
  width: 20px;
  position: absolute;
  bottom: 0;
  left: 0;
  top: auto;
}

ul.commonList {
  padding-left: 0 !important;
}

ol {
  counter-reset: item;
  list-style-type: none;
  padding-left: 0;
}
ol li {
  text-indent: -1.2em;
  padding-left: 1.2em;
}
ol li:before {
  counter-increment: item;
  content: counter(item) ".";
  padding-right: 0.4em;
  font-weight: bold;
  font-family: "Jost", sans-serif;
}
ol li:not(:first-of-type) {
  margin-top: 10px;
}
ol ol {
  padding-left: 1em;
}

section.bgGray {
  background-color: #f8f8f8;
}

.catch {
  color: #192f60;
  font-weight: bold;
  line-height: 1.5;
}

a.icon {
  display: inline-block;
  position: relative;
  padding-right: 25px;
}
a.icon:after {
  content: "";
  display: block;
  width: 20px;
  height: 20px;
  background-repeat: no-repeat;
  background-position: 50% 0;
  position: absolute;
  right: 0px;
  top: 0.2em;
}
a.icon[href$=pdf]:after {
  background-image: url(../images/second/icon_pdf.svg);
}
a.icon[href$=doc]:after, a.icon[href$=docx]:after {
  background-image: url(../images/second/icon_word.svg);
}
a.icon[href$=xls]:after, a.icon[href$=xlsx]:after {
  background-image: url(../images/second/icon_excel.svg);
}
a.icon.front {
  padding-left: 25px;
  padding-right: 0;
}
a.icon.front:after {
  left: 0px;
}

.iconBlank {
  display: inline-block;
  width: 17px;
  height: 17px;
  background: url("../images/second/icon_blank.svg") no-repeat 0 0;
  background-size: cover;
  vertical-align: middle;
  margin-left: 10px;
  position: relative;
  top: -3px;
}

.commonBtn {
  margin: auto 0 0 0;
}

.imgLine2 li {
  text-align: center;
}
.imgLine2 li img {
  max-width: 100%;
}
.imgLine3 li {
  text-align: center;
}
.imgLine3 li img {
  max-width: 100%;
}

.imgInP .img .cap {
  display: block;
  text-align: left;
  font-size: 14px;
  margin-top: 10px;
}
.twoCol .cap {
  display: block;
  text-align: left;
  font-size: 14px;
  margin-top: 10px;
}

.colorBox {
  background-color: #f8f8f8;
}
.colorBox .title {
  font-weight: 500;
  position: relative;
  padding-bottom: 15px;
}
.colorBox .title:after {
  content: "";
  display: inline-block;
  background-color: #17184b;
  height: 1px;
  width: 20px;
  position: absolute;
  bottom: 0;
  left: 0;
}

.iframeWrap {
  position: relative;
  padding-bottom: 56.25%;
}
.iframeWrap iframe {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

.commonTable {
  width: 100%;
  border-collapse: collapse;
  border-spacing: inherit;
  border-top: 1px solid #c1c1d0;
}
.commonTable th {
  text-align: center;
  font-weight: normal;
  border-bottom: 1px solid #c1c1d0;
  border-right: 1px solid #c1c1d0;
  background-color: #494a81;
  color: #fff;
}
.commonTable th:last-child {
  margin-bottom: 0;
  border-right: none;
}
.commonTable th.sub {
  background-color: #f8f8f8;
  color: #17184b;
}
.commonTable th.null {
  border-top: 1px solid #c1c1d0;
  border-left: 1px solid #c1c1d0;
  background-color: #fff;
}
.commonTable td {
  border-bottom: 1px solid #c1c1d0;
  border-right: 1px solid #c1c1d0;
  word-break: break-all;
}
.commonTable td:last-child {
  margin-bottom: 0;
  border-right: none;
}
.commonTable td.null {
  border-top: 1px solid #c1c1d0;
  border-left: 1px solid #c1c1d0;
}

.scrollTable {
  overflow: auto;
}
.scrollText {
  font-size: 12px;
  display: block;
  text-align: right;
  margin: 30px 0 -20px;
}

.dateArea .entryDate,
.dateArea .entryUpDate {
  display: block;
  color: #9d9ea0;
  text-align: right;
}

/* common class */
.pointerNone {
  cursor: default;
  text-decoration: none;
}

.taC {
  text-align: center !important;
}

.taL {
  text-align: left !important;
}

.taR {
  text-align: right !important;
}

.Center {
  margin: 0 auto !important;
}

.ovh {
  overflow: hidden !important;
}

.mw100 {
  max-width: 100% !important;
  width: auto !important;
}

.w100p {
  width: 100% !important;
}

.pT0 {
  padding-top: 0 !important;
}

.mB0 {
  margin-bottom: 0 !important;
}

.mB5 {
  margin-bottom: 5px !important;
}

.mB10 {
  margin-bottom: 10px !important;
}

.mB20 {
  margin-bottom: 20px !important;
}

.mB30 {
  margin-bottom: 30px !important;
}

.mB40 {
  margin-bottom: 40px !important;
}

.mB50 {
  margin-bottom: 50px !important;
}

.mT0 {
  margin-top: 0 !important;
}

.mT5 {
  margin-top: 5px !important;
}

.mT10 {
  margin-top: 10px !important;
}

.mT20 {
  margin-top: 20px !important;
}

.mT30 {
  margin-top: 30px !important;
}

.mT40 {
  margin-top: 40px !important;
}

.mT50 {
  margin-top: 50px !important;
}

.mL0 {
  margin-left: 0px !important;
}

.mL5 {
  margin-left: 5px !important;
}

.mL10 {
  margin-left: 10px !important;
}

.mL20 {
  margin-left: 20px !important;
}

.mL30 {
  margin-left: 30px !important;
}

.mL40 {
  margin-left: 40px !important;
}

.mL50 {
  margin-left: 50px !important;
}

.mR0 {
  margin-right: 0px !important;
}

.mR5 {
  margin-right: 5px !important;
}

.mR10 {
  margin-right: 10px !important;
}

.mR20 {
  margin-right: 20px !important;
}

.mR30 {
  margin-right: 30px !important;
}

.mR40 {
  margin-right: 40px !important;
}

.mR50 {
  margin-right: 50px !important;
}

.bold {
  font-weight: bold !important;
}

.bdNone {
  border: none !important;
}

.underline {
  text-decoration: underline !important;
}

.textOrange {
  color: #f07800 !important;
}

.marker {
  background: linear-gradient(transparent 60%, #ffd4d5 60%) !important;
}

.floatL {
  float: left;
}

.floatR {
  float: right;
}

.preBox {
  background: #eff0f4;
  border: 1px solid #17184b;
  padding: 15px;
  box-sizing: border-box;
  clear: both;
  overflow: hidden;
  margin-top: 30px;
  font-family: Arial, sans-serif;
  line-height: 1.8;
}
.preBox pre {
  overflow: auto;
  white-space: pre-wrap;
  word-wrap: break-word;
  text-align-last: auto;
}

@media screen and (max-width: 768px) {
  h1,
  h2,
  h3,
  h4,
  h5 {
    line-height: 1.5;
  }
  .secSecond {
    margin-top: 50px;
  }
  .pageTitleWrap {
    border-bottom: 2px solid #dddddd;
  }
  .pageTitleWrap:after {
    content: "";
    display: inline-block;
    width: 65px;
    height: 2px;
    background-color: #192f60;
    position: absolute;
    bottom: -2px;
    left: 0;
  }
  .pageTitleWrap.post .pageTitleJp {
    font-size: 22px;
  }
  .pageTitleWrap .pageTitleInner {
    padding: 35px 20px 0px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }
  .pageTitleWrap .pageTitleJp {
    font-size: 23px;
  }
  .pageTitleWrap .pageTitleEn {
    font-size: 11px;
  }
  .pageTitleWrap .tag {
    font-size: 12px;
  }
  .pageTitleWrap .subTitle {
    font-size: 13px;
    margin-left: 10px;
  }
  .breadcrumb {
    padding: 20px 0 8px 20px;
  }
  .breadcrumb li {
    font-size: 10px;
  }
  .breadcrumb li + li {
    margin-left: 30px;
  }
  .breadcrumb li + li:before {
    content: "／";
    position: absolute;
    top: 1px;
    left: -20px;
  }
  #contents {
    padding: 50px 0;
  }
  .inner {
    padding: 0 20px;
  }
  .inner.wide {
    padding: 0;
  }
  .inner.col2 > div:first-of-type {
    margin-bottom: 40px;
  }
  * + .articleList {
    margin-top: 30px;
  }
  .articleList .articleListItem + .articleListItem {
    margin-top: 35px;
  }
  .articleList .articleListItem .articleListTitle {
    margin-top: 10px;
    font-size: 14px;
  }
  .articleList .articleListItem .articleListSub {
    font-size: 12px;
    margin-top: 15px;
  }
  .articleNav {
    display: flex;
    flex-wrap: wrap;
  }
  .articleNav a {
    box-shadow: 1px 1px 0 0 #17184b;
    width: calc(50% - 10px);
    font-size: 12px;
    padding: 5px 10px;
  }
  .articleNav a:nth-of-type(even) {
    margin-left: 20px;
  }
  .articleNav a:nth-of-type(n + 3) {
    margin-top: 15px;
  }
  .pager {
    margin-top: 40px;
  }
  .pager ul li {
    font-size: 14px;
    margin: 0 7px;
  }
  .pager ul .prev,
  .pager ul .next {
    position: relative;
    border: none;
    font-size: 13px;
  }
  .pager ul .prev {
    margin-right: 5px;
    padding-left: 25px;
  }
  .pager ul .next {
    margin-left: 5px;
    padding-right: 25px;
  }
  .prevNextLink {
    margin-top: 40px;
    padding-top: 20px;
    font-size: 12px;
  }
  .prevNextLink .prev,
  .prevNextLink .next {
    min-width: 95px;
  }
  .prevNextLink .prev a {
    padding-left: 35px;
  }
  .prevNextLink .prev a:before {
    content: "";
    display: inline-block;
    background: url(../images/common/icon_arrow_more_left.svg) no-repeat;
    width: 20px;
    height: 20px;
    background-size: cover;
    transition: 0.3s background cubic-bezier(0.25, 0.1, 0, 0.72);
    position: absolute;
    top: 0;
    left: 0;
    transform: rotate(180deg);
  }
  .prevNextLink .next a {
    padding-right: 35px;
  }
  .prevNextLink .next a:before {
    content: "";
    display: inline-block;
    background: url(../images/common/icon_arrow_more.svg) no-repeat;
    width: 20px;
    height: 20px;
    background-size: cover;
    transition: 0.3s background cubic-bezier(0.25, 0.1, 0, 0.72);
    position: absolute;
    top: 0;
    right: 0;
  }
  .whiteBox {
    padding: 40px 20px;
  }
  .formWrap .is-error {
    font-size: 14px;
  }
  .formWrap input[type=checkbox] {
    display: none;
  }
  .formWrap input[type=checkbox] + label {
    position: relative;
    display: inline-block;
    padding: 3px 3px 3px 25px;
    cursor: pointer;
    -webkit-transition: all 0.2s;
    transition: all 0.2s;
    margin-bottom: 5px;
  }
  .formWrap input[type=checkbox] + label::before,
  .formWrap input[type=checkbox] + label::after {
    position: absolute;
    content: "";
    -webkit-transition: all 0.2s;
    transition: all 0.2s;
  }
  .formWrap input[type=checkbox] + label::before {
    top: 50%;
    left: 0;
    width: 16px;
    height: 16px;
    margin-top: -8px;
    background: #fff;
    border: 1px solid #ccc;
    border-radius: 3px;
  }
  .formWrap input[type=checkbox] + label::after {
    opacity: 0;
    top: 50%;
    left: 3px;
    width: 10px;
    height: 5px;
    margin-top: -4px;
    border-left: 2px solid #17184b;
    border-bottom: 2px solid #17184b;
    -webkit-transform: rotate(-45deg) scale(0.5);
    transform: rotate(-45deg) scale(0.5);
  }
  .formWrap input[type=checkbox] + label:hover::before {
    background: #fff;
  }
  .formWrap input[type=checkbox]:checked + label::before {
    background: #fff;
    border: 1px solid #17184b;
  }
  .formWrap input[type=checkbox]:checked + label::after {
    opacity: 1;
    -webkit-transform: rotate(-45deg) scale(1);
    transform: rotate(-45deg) scale(1);
  }
  .formWrap input[type=submit].commonBtn {
    background-image: url(../images/common/icon_arrow_white.svg);
    background-repeat: no-repeat;
    background-position: center right 24px;
  }
  .formWrap dl + dl {
    margin-top: 20px;
  }
  .formWrap .hissu {
    font-size: 10px;
  }
  .formWrap br {
    display: none;
  }
  .formWrap input[type=submit] {
    margin-top: 30px;
    padding: 12px 40px;
    width: 72%;
  }
  .featureList {
    margin-top: 60px;
  }
  .featureList .featureListItem:nth-of-type(odd) .featureTextWrap {
    color: #fff;
    background-color: rgba(23, 24, 75, 0.95);
  }
  .featureList .featureListItem:nth-of-type(odd) .featureTextWrap .subTitle:before {
    background-color: #fff;
  }
  .featureList .featureListItem:nth-of-type(odd) ul.commonList li:before {
    background-color: #fff;
  }
  .featureList .featureListItem:nth-of-type(even) .featureTextWrap {
    background-color: rgba(255, 255, 255, 0.95);
  }
  .featureList .featureListItem:nth-of-type(even) .featureTextWrap .subTitle:before {
    background-color: #17184b;
  }
  .featureList .featureListItem figure {
    margin-bottom: 0;
  }
  .featureList .featureListItem .featureTextWrap {
    padding: 35px 20px;
  }
  .featureList .featureListItem .featureTextWrap .subTitle {
    padding-left: 35px;
  }
  .featureList .featureListItem .featureTextWrap .subTitle:before {
    content: "";
    display: inline-block;
    width: 24px;
    height: 1px;
    position: absolute;
    top: 50%;
    left: 0;
  }
  .featureList .featureListItem .featureTextWrap h2 {
    font-size: 19px;
    margin-top: 10px;
  }
  #caseStudy .slideList {
    width: calc(100% + 40px);
    margin-left: -20px;
  }
  #caseStudy h2 {
    font-size: 23px;
  }
  #caseStudy .btnWrap {
    margin-top: 40px;
  }
  #recommend {
    padding: 60px 0;
  }
  #recommend .slideList {
    width: calc(100% + 40px);
    margin-left: -20px;
  }
  #recommend h2 {
    font-size: 23px;
  }
  #recommend .btnWrap {
    margin-top: 40px;
  }
  #recommend.faqTop {
    background-color: #f8f8f8;
    padding: 45px 0 50px !important;
  }
  .solarAbout {
    min-height: 370px;
  }
  .solarAbout h2 {
    font-size: 23px;
  }
  .solarAbout h2 + p {
    font-size: 13px;
    margin-top: 20px;
    font-weight: bold;
    line-height: 2;
  }
  .solarAbout .fvBtnList {
    margin-top: 30px;
  }
  .solarAbout .fvBtnList li + li {
    margin-top: 20px;
  }
  .solarAbout .fvBtnList li a {
    font-size: 14px;
    width: 270px;
    margin: auto;
    padding: 10px 10px;
  }
  .solarAbout .fvBtnList li a .icon.iconMaterial::before {
    width: 14px;
    height: 18px;
  }
  .solarAbout .fvBtnList li a .icon.iconMail::before {
    background-image: url(../images/common/icon_mail_white.svg);
    width: 18px;
    height: 12px;
  }
  .meritWrap h3 {
    font-size: 19px;
    margin-top: 40px;
    padding: 0 0 15px 35px;
  }
  .meritWrap h3 .meritNum {
    font-size: 10px;
  }
  .meritWrap h3 .meritNum span {
    font-size: 19px;
  }
  .meritWrap .meritCont {
    margin-top: 20px;
  }
  .meritWrap .meritCont figure img {
    width: 125px;
  }
  .meritWrap .meritCont .textWrap * + h4 {
    margin-top: 25px;
  }
  .meritWrap .meritCont .textWrap h4 {
    font-size: 14px;
    padding-left: 20px;
  }
  .meritWrap .meritCont .textWrap h4:before {
    content: "";
    display: inline-block;
    width: 15px;
    height: 2px;
    background-color: #17184b;
    position: absolute;
    top: calc(50% - 1px);
    left: 0;
  }
  .meritWrap .meritCont .textWrap p {
    margin-top: 10px;
  }
  .teianH2 {
    padding: 30px 20px;
    margin-top: 60px;
  }
  .teianSystem {
    margin-top: 40px;
  }
  .teianSystem .teianSystemItem {
    border-radius: 5px;
  }
  .teianSystem .teianSystemItem + .teianSystemItem {
    margin-top: 20px;
  }
  .teianSystem .teianSystemItem h3 {
    border-radius: 5px 5px 0 0;
    padding: 10px 10px;
    font-size: 16px;
  }
  .teianSystem .teianSystemItem h3 span {
    font-size: 20px;
  }
  .teianSystem .teianSystemItem .teianSystemCont {
    padding: 10px 10px;
    border-radius: 0 0 5px 5px;
  }
  .btnCol .commonBtn {
    margin: 0 auto;
  }
  .btnCol .commonBtn + .commonBtn {
    margin-top: 20px;
  }
  .sindanImg {
    text-align: center;
    margin-top: 30px;
  }
  #contents .gmap {
    margin-top: 20px;
  }
  #contents h2 + .commonImgBox,
  #contents h3 + .commonImgBox,
  #contents h4 + .commonImgBox {
    margin: 20px 0 40px;
  }
  #contents .commonImgBox {
    flex-direction: column-reverse;
    margin: 30px 0 40px;
    margin: 0 0 40px;
  }
  #contents .commonImgBox .txt {
    margin-top: 30px;
  }
  #contents .commonImgBox .img {
    text-align: center;
  }
  #contents .commonImgBox .img img {
    width: 100%;
    max-width: 400px;
    margin: auto;
  }
  #contents .commonImgBox.imgBtm {
    flex-direction: column;
  }
  #contents .commonImgBox.imgBtm .img {
    margin-top: 30px;
  }
  #contents .cardListWrap .cardList {
    max-width: 600px;
    margin: auto;
    position: relative;
    z-index: 2;
  }
  #contents .cardListWrap li {
    width: 100%;
    overflow: hidden;
  }
  #contents .cardListWrap li + li {
    margin-top: 20px;
  }
  #contents .cardListWrap a {
    height: 37vw;
  }
  #contents .cardListWrap .img {
    position: relative;
    width: 45%;
  }
  #contents .cardListWrap img {
    max-width: 100%;
    width: 100%;
    transition: 0.3s ease-in-out;
    border-radius: 5px 0 0 5px;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
  }
  #contents .cardListWrap .txt {
    width: 55%;
    text-align: center;
  }
  #contents .cardListWrap .txt .ttl {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    font-size: 17px;
    padding: 0 4px;
  }
  #contents .cardListWrap .txt .viewmore {
    font-size: 11px;
    position: absolute;
    right: 8px;
    bottom: 8px;
    z-index: 2;
  }
  #contents .cardListWrap .txt .viewmore::before {
    width: 18px;
    height: 1px;
    top: 50%;
    right: 60px;
  }
  #contents .leadTtl {
    font-size: 25px;
    margin: 40px 0 24px;
  }
  #contents .mainTtl1 {
    font-size: 25px;
    margin: 36px 0 20px;
  }
  #contents .subTtl {
    font-size: 15px;
  }
  #contents .rinenListWrap {
    margin: 0 0 60px;
  }
  #contents .rinenListWrap .rinenBorder {
    border-bottom: 1px solid #17184b;
  }
  #contents .rinenListWrap .rinenList {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 0;
    max-width: 330px;
    margin: auto;
    padding: 10px 0;
    position: relative;
  }
  #contents .rinenListWrap .rinenList dt {
    margin-left: 30px;
    width: 30%;
  }
  #contents .rinenListWrap .rinenList dt img {
    width: 42px;
    height: auto;
    vertical-align: middle;
  }
  #contents .rinenListWrap .rinenList dd {
    font-size: 14px;
    line-height: 2;
    width: 70%;
  }
  #contents .rinenListWrap.txtListWrap .rinenList {
    display: block;
    padding: 10px 0;
    text-align: center;
  }
  #contents .rinenListWrap.txtListWrap .rinenList dt {
    font-size: 18px;
    width: 100%;
    margin-left: 0;
  }
  #contents .rinenListWrap.txtListWrap .rinenList dd {
    font-size: 16px;
    width: 100%;
  }
  .numberWrap .subTtl {
    font-size: 13px;
  }
  .voiceList a.voiceCont {
    max-width: 600px;
    margin: 0 auto 50px;
  }
  .voiceList a.voiceCont:last-of-type {
    margin-bottom: 40px;
  }
  .voiceList a.voiceCont .voiceSubTitle {
    text-align: center;
    font-size: 12px;
    margin-bottom: 15px;
  }
  .voiceList a.voiceCont .voiceSubTitle .number {
    font-family: "Jost", sans-serif;
    font-weight: 600;
  }
  .voiceList a.voiceCont .voiceSubTitle .cut {
    margin: 0 8px;
  }
  .voiceList a.voiceCont h3 {
    font-size: 17px;
    line-height: 1.59;
    margin-bottom: 15px;
  }
  .voiceList a.voiceCont .desc {
    line-height: 1.86;
    margin-bottom: 20px;
  }
  .voiceList a.voiceCont .voiceImg {
    margin-bottom: 20px;
    box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.15);
  }
  .voiceList a.voiceCont .voiceImg img {
    object-fit: cover;
    transition: 0.3s transform cubic-bezier(0.25, 0.1, 0, 0.72);
  }
  .voiceList a.voiceCont .btnWrap::after {
    width: 11px;
    height: 9px;
    top: 8px;
    right: 2.5px;
  }
  .voiceList a.voiceCont .viewMore {
    font-size: 13px;
    padding-right: 24px;
  }
  .main_contact .headDeco {
    display: none;
  }
  .aform input[type=text],
  .aform input[type=password],
  .aform input[type=tel],
  .aform input[type=email],
  .aform select,
  .aform textarea {
    border: 1px solid #a4a4a4;
    border-radius: 4px;
    padding: 10px;
    width: 100%;
    background-color: #fff;
  }
  .aform input[type=text].error,
  .aform input[type=password].error,
  .aform input[type=tel].error,
  .aform input[type=email].error,
  .aform select.error,
  .aform textarea.error {
    background-color: #fff1f2;
  }
  .aform input[type=text].size-s,
  .aform input[type=password].size-s,
  .aform input[type=tel].size-s,
  .aform input[type=email].size-s,
  .aform select.size-s {
    width: 22%;
  }
  .aform input[type=text].size-sm,
  .aform input[type=password].size-sm,
  .aform input[type=tel].size-sm,
  .aform input[type=email].size-sm,
  .aform select.size-sm {
    width: 20%;
  }
  .aform input[type=text].size-m,
  .aform input[type=password].size-m,
  .aform input[type=tel].size-m,
  .aform input[type=email].size-m,
  .aform select.size-m {
    width: 27%;
  }
  .aform input[type=text].size-ml,
  .aform input[type=password].size-ml,
  .aform input[type=tel].size-ml,
  .aform input[type=email].size-ml,
  .aform select.size-ml {
    width: 50%;
  }
  .aform input[type=text].size-l,
  .aform input[type=password].size-l,
  .aform input[type=tel].size-l,
  .aform input[type=email].size-l,
  .aform select.size-l {
    width: 75%;
  }
  .aform input[type=text].size-special,
  .aform input[type=password].size-special,
  .aform input[type=tel].size-special,
  .aform input[type=email].size-special,
  .aform select.size-special {
    width: 85%;
    margin-bottom: 10px;
  }
  .aform input[type=text].year,
  .aform input[type=password].year,
  .aform input[type=tel].year,
  .aform input[type=email].year,
  .aform select.year {
    width: 23%;
    margin-bottom: 10px;
  }
  .aform input[type=text].month,
  .aform input[type=password].month,
  .aform input[type=tel].month,
  .aform input[type=email].month,
  .aform select.month {
    width: 17%;
    margin-bottom: 10px;
  }
  .aform select.year {
    width: 23%;
    margin-bottom: 10px;
  }
  .aform select.month {
    width: 17%;
    margin-bottom: 10px;
  }
  .aform button {
    border: 1px solid #ccc;
    padding: 4px 8px;
    background-color: #f6f6f6;
    box-shadow: 0px 1px 1px 0px #cccccc;
    font-size: 14px;
  }
  .aform button:hover {
    opacity: 0.7;
    cursor: pointer;
  }
  .aform input[type=radio] {
    display: none;
  }
  .aform input[type=radio] + label {
    position: relative;
    display: inline-block;
    padding: 3px 3px 3px 25px;
    cursor: pointer;
    margin-right: 20px;
    margin-bottom: 5px;
  }
  .aform input[type=radio] + label::before,
  .aform input[type=radio] + label::after {
    position: absolute;
    content: "";
    top: 50%;
    border-radius: 100%;
    -webkit-transition: all 0.2s;
    transition: all 0.2s;
  }
  .aform input[type=radio] + label::before {
    left: 0;
    width: 17px;
    height: 17px;
    margin-top: -10px;
    background: #fff;
    border: 1px solid #ccc;
  }
  .aform input[type=radio] + label:hover::before {
    background: #fff;
  }
  .aform input[type=radio] + label::after {
    opacity: 0;
    left: 4px;
    width: 9px;
    height: 9px;
    margin-top: -6px;
    background: #5dbf5d;
    -webkit-transform: scale(2);
    transform: scale(2);
  }
  .aform input[type=radio]:checked + label::before {
    background: #fff;
    border: 1px solid #5dbf5d;
  }
  .aform input[type=radio]:checked + label::after {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  .aform input[type=checkbox] {
    opacity: 0;
    width: 1px;
    height: 1px;
  }
  .aform input[type=checkbox] + label {
    position: relative;
    display: inline-block;
    padding: 3px 3px 3px 40px;
    cursor: pointer;
    -webkit-transition: all 0.2s;
    transition: all 0.2s;
    margin-bottom: 5px;
  }
  .aform input[type=checkbox] + label::before,
  .aform input[type=checkbox] + label::after {
    position: absolute;
    content: "";
    -webkit-transition: all 0.2s;
    transition: all 0.2s;
  }
  .aform input[type=checkbox] + label::before {
    top: 50%;
    left: 0;
    width: 20px;
    height: 20px;
    margin-top: -11px;
    background: #fff;
    border: 2px solid #ccc;
    border-radius: 3px;
  }
  .aform input[type=checkbox] + label::after {
    opacity: 0;
    border-left: 2px solid #17184b;
    border-bottom: 2px solid #17184b;
    -webkit-transform: rotate(-45deg) scale(0.5);
    transform: rotate(-45deg) scale(0.5);
    top: calc(50% - 7px);
    left: 5px;
    width: 11px;
    height: 7px;
  }
  .aform input[type=checkbox] + label:hover::before {
    background: #fff;
  }
  .aform input[type=checkbox]:checked + label::before {
    background: #fff;
    border: 1px solid #ccc;
  }
  .aform input[type=checkbox]:checked + label::after {
    opacity: 1;
    -webkit-transform: rotate(-45deg) scale(1);
    transform: rotate(-45deg) scale(1);
  }
  .aform.selectType {
    width: 100%;
    margin-bottom: 50px;
  }
  .aform.selectType .form_dt {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 99%;
  }
  .aform.selectType .form_dt .aform-required {
    background-color: #f07800;
    color: #fff;
    font-size: 12px;
    padding: 4px 8px;
    line-height: 1;
    display: inline-block;
    margin-left: 10px;
    border-radius: 3px;
    white-space: nowrap;
  }
  .aform.selectType select {
    width: 100%;
    background-color: #f8f8f8;
    border: none;
  }
  .form_dt {
    display: block;
    font-weight: bold;
    font-size: 14px;
    margin-bottom: 10px;
  }
  .aform .aform-content {
    width: 100%;
    margin-bottom: 20px;
  }
  .aform .aform-content #aform-confirm-form dl {
    border-bottom: 1px solid #f8f8f8;
    margin-bottom: 0;
  }
  .aform .aform-content .aform-note {
    background: none;
    margin-top: 20px;
    margin-bottom: 20px;
  }
  .aform .aform-content dl {
    width: 100%;
    display: block;
    border-bottom: none;
  }
  .aform .aform-content dt {
    width: 100%;
    font-size: 14px;
    font-weight: bold;
    display: block;
    text-align: left;
    margin-bottom: 10px;
    background-clip: padding-box;
    position: relative;
    margin-bottom: 10px;
    margin-top: 20px;
  }
  .aform .aform-content dt .aform-required {
    background-color: #f07800;
    color: #fff;
    font-size: 12px;
    padding: 4px 8px;
    line-height: 1;
    display: inline-block;
    margin-left: 10px;
    border-radius: 3px;
    white-space: nowrap;
  }
  .aform .aform-content dt .aform-label {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 99%;
  }
  .aform .aform-content dd {
    display: block;
    width: 100%;
  }
  .aform .aform-content dd input {
    background-color: #f8f8f8;
    border: none;
    margin-top: 5px;
  }
  .aform .aform-content dd .kome {
    font-size: 13px;
    margin-top: 5px;
    display: block;
  }
  .aform .aform-content dd .block_sp {
    display: block !important;
    margin-top: 10px;
  }
  .aform .aform-content dd .subtxt {
    display: inline-block;
    width: 15%;
  }
  .aform .aform-content .aform-textarea,
  .aform .aform-content select.aform-prefecture {
    background-color: #f8f8f8;
    border: none;
  }
  .aform .aform-content .aform-textarea {
    width: 100%;
    padding: 10px;
    min-height: 140px;
  }
  .aform .aform-content .aform-name,
  .aform .aform-content .aform-kana,
  .aform .aform-content .aform-input-example {
    margin: 10px 0;
  }
  .aform .aform-content .tel {
    text-decoration: none;
  }
  .aform .aform-content .aform-twice-note {
    display: block;
    margin: 10px 0;
  }
  .aform .aform-content .dl_contactContents {
    align-items: flex-start;
  }
  .aform .aform-content .dl_contactContents dd {
    width: 100%;
  }
  .aform .aform-content .dl_privacyPolicy {
    display: block;
    border-bottom: none;
    margin: 30px 0 0;
    padding-bottom: 10px;
    line-height: 1.4;
  }
  .aform .aform-content .dl_privacyPolicy dt img {
    width: 20px;
    height: 20px;
  }
  .aform .aform-content .dl_privacyPolicy dd {
    background-color: #f8f8f8;
    font-weight: bold;
    padding: 30px;
    width: 100%;
    text-align: center;
  }
  .aform .aform-content .dl_privacyPolicy input[type=checkbox] + label::before {
    top: 12px;
  }
  .aform .aform-content .dl_privacyPolicy input[type=checkbox] + label::after {
    top: 12px;
  }
  .aform .aform-content .aform-error {
    color: #cf0000;
    font-weight: bold;
    margin-top: 10px;
    font-size: 13px;
  }
  .aform .aform-content .aform-error:first-child {
    margin-top: 0;
  }
  .aform .aform-content .aform-error .validation-advice {
    padding-top: 4px;
    line-height: 1.4;
  }
  .aform .aform-content .aform-button-area {
    margin-top: 20px;
    padding: 0;
    background: none;
    text-align: center;
    display: flex;
    flex-direction: column;
  }
  .aform .aform-content .aform-button-area input[type=submit] {
    box-shadow: 1px 1px 0 0 #17184b;
    margin: auto;
    padding: 12px 40px;
    width: 72%;
  }
  .nofloat {
    float: none !important;
  }
  #aform_btn_back {
    font-size: 16px;
    order: 2;
    display: block;
    padding: 10px 35px;
    background-color: #fff;
    color: #666;
    text-align: center;
    vertical-align: top;
    position: relative;
    border: 1px solid #aaa;
    width: 225px;
    margin: 20px auto;
  }
  .aform .aform-content dl.aform-error-list {
    border: none;
  }
  .aform .aform-content dl.aform-error-list dt {
    display: block;
  }
  .aform .aform-content dl.aform-error-list dd {
    display: list-item;
    margin-left: 25px;
    width: auto;
  }
  #aform_confirm_msg {
    text-align: center;
    padding-top: 20px;
  }
  img[src$="icon_new_windows.gif"] {
    margin-left: 10px;
    vertical-align: middle;
  }
  .aform-header {
    display: none;
  }
  .aform select.aform-calendar {
    width: auto;
  }
  .aform select.aform-calendar + label {
    margin-left: 2px;
    margin-right: 5px;
  }
  .aform #aform_result {
    color: #000;
  }
  .aform #aform_result a.commonBtn {
    margin: 20px auto 30px;
  }
  /*!
  	Modaal - accessible modals - v0.4.4
  	by Humaan, for all humans.
  	http://humaan.com
   */
  .modaal-noscroll {
    overflow: hidden;
  }
  .modaal-accessible-hide {
    position: absolute !important;
    clip: rect(1px 1px 1px 1px); /* IE6, IE7 */
    clip: rect(1px, 1px, 1px, 1px);
    padding: 0 !important;
    border: 0 !important;
    height: 1px !important;
    width: 1px !important;
    overflow: hidden;
  }
  .modaal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 999;
    opacity: 0;
  }
  .modaal-wrapper {
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 9999;
    overflow: auto;
    opacity: 1;
    box-sizing: border-box;
    -webkit-overflow-scrolling: touch;
    transition: all 0.3s ease-in-out;
  }
  .modaal-wrapper * {
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-backface-visibility: hidden;
  }
  .modaal-wrapper .modaal-close {
    border: none;
    background: transparent;
    padding: 0;
    -webkit-appearance: none;
  }
  .modaal-wrapper.modaal-start_none {
    display: none;
    opacity: 1;
  }
  .modaal-wrapper.modaal-start_fade {
    opacity: 0;
  }
  .modaal-wrapper *[tabindex="0"] {
    outline: none !important;
  }
  .modaal-wrapper.modaal-fullscreen {
    overflow: hidden;
  }
  .modaal-outer-wrapper {
    display: table;
    position: relative;
    width: 100%;
    height: 100%;
  }
  .modaal-fullscreen .modaal-outer-wrapper {
    display: block;
  }
  .modaal-inner-wrapper {
    display: table-cell;
    width: 100%;
    height: 100%;
    position: relative;
    vertical-align: middle;
    text-align: center;
    padding: 80px 25px;
  }
  .modaal-fullscreen .modaal-inner-wrapper {
    padding: 0;
    display: block;
    vertical-align: top;
  }
  .modaal-container {
    position: relative;
    display: inline-block;
    width: 100%;
    margin: auto;
    text-align: left;
    color: #000;
    max-width: 1000px;
    border-radius: 0px;
    background: #fff;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
    cursor: auto;
  }
  .modaal-container.is_loading {
    height: 100px;
    width: 100px;
    overflow: hidden;
  }
  .modaal-fullscreen .modaal-container {
    max-width: none;
    height: 100%;
    overflow: auto;
  }
  .modaal-close {
    position: fixed;
    right: 20px;
    top: 20px;
    color: #fff;
    cursor: pointer;
    opacity: 1;
    width: 50px;
    height: 50px;
    background: rgba(0, 0, 0, 0);
    border-radius: 100%;
    transition: all 0.2s ease-in-out;
  }
  .modaal-close:focus, .modaal-close:hover {
    outline: none;
    background: #fff;
  }
  .modaal-close:focus:before, .modaal-close:focus:after, .modaal-close:hover:before, .modaal-close:hover:after {
    background: #b93d0c;
  }
  .modaal-close span {
    position: absolute !important;
    clip: rect(1px 1px 1px 1px); /* IE6, IE7 */
    clip: rect(1px, 1px, 1px, 1px);
    padding: 0 !important;
    border: 0 !important;
    height: 1px !important;
    width: 1px !important;
    overflow: hidden;
  }
  .modaal-close:before, .modaal-close:after {
    display: block;
    content: " ";
    position: absolute;
    top: 14px;
    left: 23px;
    width: 4px;
    height: 22px;
    border-radius: 4px;
    background: #fff;
    transition: background 0.2s ease-in-out;
  }
  .modaal-close:before {
    transform: rotate(-45deg);
  }
  .modaal-close:after {
    transform: rotate(45deg);
  }
  .modaal-fullscreen .modaal-close {
    background: #afb7bc;
    right: 10px;
    top: 10px;
  }
  .modaal-content-container {
    padding: 30px;
  }
  .modaal-confirm-wrap {
    padding: 30px 0 0;
    text-align: center;
    font-size: 0;
  }
  .modaal-confirm-btn {
    font-size: 14px;
    display: inline-block;
    margin: 0 10px;
    vertical-align: middle;
    cursor: pointer;
    border: none;
    background: transparent;
  }
  .modaal-confirm-btn.modaal-ok {
    padding: 10px 15px;
    color: #fff;
    background: #555;
    border-radius: 3px;
    transition: background 0.2s ease-in-out;
  }
  .modaal-confirm-btn.modaal-ok:hover {
    background: #2f2f2f;
  }
  .modaal-confirm-btn.modaal-cancel {
    text-decoration: underline;
  }
  .modaal-confirm-btn.modaal-cancel:hover {
    text-decoration: none;
    color: #2f2f2f;
  }
  @keyframes instaReveal {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  @-o-keyframes instaReveal {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  @-moz-keyframes instaReveal {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  @-webkit-keyframes instaReveal {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  @-ms-keyframes instaReveal {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  .modaal-instagram .modaal-container {
    width: auto;
    background: transparent;
    box-shadow: none !important;
  }
  .modaal-instagram .modaal-content-container {
    padding: 0;
    background: transparent;
  }
  .modaal-instagram .modaal-content-container > blockquote {
    width: 1px !important;
    height: 1px !important;
    opacity: 0 !important;
  }
  .modaal-instagram iframe {
    opacity: 0;
    margin: -6px !important;
    border-radius: 0 !important;
    width: 1000px !important;
    max-width: 800px !important;
    box-shadow: none !important;
    animation: instaReveal 1s linear forwards;
  }
  .modaal-image .modaal-inner-wrapper {
    padding-left: 140px;
    padding-right: 140px;
  }
  .modaal-image .modaal-container {
    width: auto;
    max-width: 100%;
  }
  .modaal-gallery-wrap {
    position: relative;
    color: #fff;
  }
  .modaal-gallery-item {
    display: none;
  }
  .modaal-gallery-item img {
    display: block;
  }
  .modaal-gallery-item.is_active {
    display: block;
  }
  .modaal-gallery-label {
    position: absolute;
    left: 0;
    width: 100%;
    margin: 20px 0 0;
    font-size: 18px;
    text-align: center;
    color: #fff;
  }
  .modaal-gallery-label:focus {
    outline: none;
  }
  .modaal-gallery-control {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    opacity: 1;
    cursor: pointer;
    color: #fff;
    width: 50px;
    height: 50px;
    background: rgba(0, 0, 0, 0);
    border: none;
    border-radius: 100%;
    transition: all 0.2s ease-in-out;
  }
  .modaal-gallery-control.is_hidden {
    opacity: 0;
    cursor: default;
  }
  .modaal-gallery-control:focus, .modaal-gallery-control:hover {
    outline: none;
    background: #fff;
  }
  .modaal-gallery-control:focus:before, .modaal-gallery-control:focus:after, .modaal-gallery-control:hover:before, .modaal-gallery-control:hover:after {
    background: #afb7bc;
  }
  .modaal-gallery-control span {
    position: absolute !important;
    clip: rect(1px 1px 1px 1px); /* IE6, IE7 */
    clip: rect(1px, 1px, 1px, 1px);
    padding: 0 !important;
    border: 0 !important;
    height: 1px !important;
    width: 1px !important;
    overflow: hidden;
  }
  .modaal-gallery-control:before, .modaal-gallery-control:after {
    display: block;
    content: " ";
    position: absolute;
    top: 16px;
    left: 25px;
    width: 4px;
    height: 18px;
    border-radius: 4px;
    background: #fff;
    transition: background 0.2s ease-in-out;
  }
  .modaal-gallery-control:before {
    margin: -5px 0 0;
    transform: rotate(-45deg);
  }
  .modaal-gallery-control:after {
    margin: 5px 0 0;
    transform: rotate(45deg);
  }
  .modaal-gallery-next-inner {
    left: 100%;
    margin-left: 40px;
  }
  .modaal-gallery-next-outer {
    right: 45px;
  }
  .modaal-gallery-prev:before, .modaal-gallery-prev:after {
    left: 22px;
  }
  .modaal-gallery-prev:before {
    margin: 5px 0 0;
    transform: rotate(-45deg);
  }
  .modaal-gallery-prev:after {
    margin: -5px 0 0;
    transform: rotate(45deg);
  }
  .modaal-gallery-prev-inner {
    right: 100%;
    margin-right: 40px;
  }
  .modaal-gallery-prev-outer {
    left: 45px;
  }
  .modaal-video-wrap {
    margin: auto 50px;
    position: relative;
  }
  .modaal-video-container {
    position: relative;
    padding-bottom: 56.25%;
    height: 0;
    overflow: hidden;
    max-width: 100%;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    background: #000;
    max-width: 1300px;
    margin-left: auto;
    margin-right: auto;
  }
  .modaal-video-container iframe,
  .modaal-video-container object,
  .modaal-video-container embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  .modaal-iframe .modaal-content {
    width: 100%;
    height: 100%;
  }
  .modaal-iframe-elem {
    width: 100%;
    height: 100%;
    display: block;
  }
  /* common class */
}
@media only screen and (max-width: 768px) and (min-width: 1400px) {
  .modaal-video-container {
    padding-bottom: 0;
    height: 731px;
  }
}
@media only screen and (max-width: 768px) and (max-width: 1140px) {
  .modaal-image .modaal-inner-wrapper {
    padding-left: 25px;
    padding-right: 25px;
  }
  .modaal-gallery-control {
    top: auto;
    bottom: 20px;
    transform: none;
    background: rgba(0, 0, 0, 0.7);
  }
  .modaal-gallery-control:before, .modaal-gallery-control:after {
    background: #fff;
  }
  .modaal-gallery-next {
    left: auto;
    right: 20px;
  }
  .modaal-gallery-prev {
    left: 20px;
    right: auto;
  }
}
@media screen and (max-width: 768px) and (max-width: 900px) {
  .modaal-instagram iframe {
    width: 500px !important;
  }
}
@media screen and (max-width: 768px) and (max-height: 1100px) {
  .modaal-instagram iframe {
    width: 700px !important;
  }
}
@media screen and (max-width: 768px) and (max-height: 1000px) {
  .modaal-inner-wrapper {
    padding-top: 60px;
    padding-bottom: 60px;
  }
  .modaal-instagram iframe {
    width: 600px !important;
  }
}
@media screen and (max-width: 768px) and (max-height: 900px) {
  .modaal-instagram iframe {
    width: 500px !important;
  }
  .modaal-video-container {
    max-width: 900px;
    max-height: 510px;
  }
}
@media only screen and (max-width: 768px) and (max-width: 600px) {
  .modaal-instagram iframe {
    width: 280px !important;
  }
}
@media only screen and (max-width: 768px) and (max-height: 820px) {
  .modaal-gallery-label {
    display: none;
  }
}
@media screen and (max-width: 768px) {
  .modaal-loading-spinner {
    background: none;
    position: absolute;
    width: 200px;
    height: 200px;
    top: 50%;
    left: 50%;
    margin: -100px 0 0 -100px;
    transform: scale(0.25);
  }
  @-ms-keyframes modaal-loading-spinner {
    0% {
      opacity: 1;
      -ms-transform: scale(1.5);
      -moz-transform: scale(1.5);
      -webkit-transform: scale(1.5);
      -o-transform: scale(1.5);
      transform: scale(1.5);
    }
    100% {
      opacity: 0.1;
      -ms-transform: scale(1);
      -moz-transform: scale(1);
      -webkit-transform: scale(1);
      -o-transform: scale(1);
      transform: scale(1);
    }
  }
  @-moz-keyframes modaal-loading-spinner {
    0% {
      opacity: 1;
      -ms-transform: scale(1.5);
      -moz-transform: scale(1.5);
      -webkit-transform: scale(1.5);
      -o-transform: scale(1.5);
      transform: scale(1.5);
    }
    100% {
      opacity: 0.1;
      -ms-transform: scale(1);
      -moz-transform: scale(1);
      -webkit-transform: scale(1);
      -o-transform: scale(1);
      transform: scale(1);
    }
  }
  @-webkit-keyframes modaal-loading-spinner {
    0% {
      opacity: 1;
      -ms-transform: scale(1.5);
      -moz-transform: scale(1.5);
      -webkit-transform: scale(1.5);
      -o-transform: scale(1.5);
      transform: scale(1.5);
    }
    100% {
      opacity: 0.1;
      -ms-transform: scale(1);
      -moz-transform: scale(1);
      -webkit-transform: scale(1);
      -o-transform: scale(1);
      transform: scale(1);
    }
  }
  @-o-keyframes modaal-loading-spinner {
    0% {
      opacity: 1;
      -ms-transform: scale(1.5);
      -moz-transform: scale(1.5);
      -webkit-transform: scale(1.5);
      -o-transform: scale(1.5);
      transform: scale(1.5);
    }
    100% {
      opacity: 0.1;
      -ms-transform: scale(1);
      -moz-transform: scale(1);
      -webkit-transform: scale(1);
      -o-transform: scale(1);
      transform: scale(1);
    }
  }
  @keyframes modaal-loading-spinner {
    0% {
      opacity: 1;
      -ms-transform: scale(1.5);
      -moz-transform: scale(1.5);
      -webkit-transform: scale(1.5);
      -o-transform: scale(1.5);
      transform: scale(1.5);
    }
    100% {
      opacity: 0.1;
      -ms-transform: scale(1);
      -moz-transform: scale(1);
      -webkit-transform: scale(1);
      -o-transform: scale(1);
      transform: scale(1);
    }
  }
  .modaal-loading-spinner > div {
    width: 24px;
    height: 24px;
    margin-left: 4px;
    margin-top: 4px;
    position: absolute;
  }
  .modaal-loading-spinner > div > div {
    width: 100%;
    height: 100%;
    border-radius: 15px;
    background: #fff;
  }
  .modaal-loading-spinner > div:nth-of-type(1) > div {
    -ms-animation: modaal-loading-spinner 1s linear infinite;
    -moz-animation: modaal-loading-spinner 1s linear infinite;
    -webkit-animation: modaal-loading-spinner 1s linear infinite;
    -o-animation: modaal-loading-spinner 1s linear infinite;
    animation: modaal-loading-spinner 1s linear infinite;
    -ms-animation-delay: 0s;
    -moz-animation-delay: 0s;
    -webkit-animation-delay: 0s;
    -o-animation-delay: 0s;
    animation-delay: 0s;
  }
  .modaal-loading-spinner > div:nth-of-type(2) > div, .modaal-loading-spinner > div:nth-of-type(3) > div {
    -ms-animation: modaal-loading-spinner 1s linear infinite;
    -moz-animation: modaal-loading-spinner 1s linear infinite;
    -webkit-animation: modaal-loading-spinner 1s linear infinite;
    -o-animation: modaal-loading-spinner 1s linear infinite;
  }
  .modaal-loading-spinner > div:nth-of-type(1) {
    -ms-transform: translate(84px, 84px) rotate(45deg) translate(70px, 0);
    -moz-transform: translate(84px, 84px) rotate(45deg) translate(70px, 0);
    -webkit-transform: translate(84px, 84px) rotate(45deg) translate(70px, 0);
    -o-transform: translate(84px, 84px) rotate(45deg) translate(70px, 0);
    transform: translate(84px, 84px) rotate(45deg) translate(70px, 0);
  }
  .modaal-loading-spinner > div:nth-of-type(2) > div {
    animation: modaal-loading-spinner 1s linear infinite;
    -ms-animation-delay: 0.12s;
    -moz-animation-delay: 0.12s;
    -webkit-animation-delay: 0.12s;
    -o-animation-delay: 0.12s;
    animation-delay: 0.12s;
  }
  .modaal-loading-spinner > div:nth-of-type(2) {
    -ms-transform: translate(84px, 84px) rotate(90deg) translate(70px, 0);
    -moz-transform: translate(84px, 84px) rotate(90deg) translate(70px, 0);
    -webkit-transform: translate(84px, 84px) rotate(90deg) translate(70px, 0);
    -o-transform: translate(84px, 84px) rotate(90deg) translate(70px, 0);
    transform: translate(84px, 84px) rotate(90deg) translate(70px, 0);
  }
  .modaal-loading-spinner > div:nth-of-type(3) > div {
    animation: modaal-loading-spinner 1s linear infinite;
    -ms-animation-delay: 0.25s;
    -moz-animation-delay: 0.25s;
    -webkit-animation-delay: 0.25s;
    -o-animation-delay: 0.25s;
    animation-delay: 0.25s;
  }
  .modaal-loading-spinner > div:nth-of-type(4) > div, .modaal-loading-spinner > div:nth-of-type(5) > div {
    -ms-animation: modaal-loading-spinner 1s linear infinite;
    -moz-animation: modaal-loading-spinner 1s linear infinite;
    -webkit-animation: modaal-loading-spinner 1s linear infinite;
    -o-animation: modaal-loading-spinner 1s linear infinite;
  }
  .modaal-loading-spinner > div:nth-of-type(3) {
    -ms-transform: translate(84px, 84px) rotate(135deg) translate(70px, 0);
    -moz-transform: translate(84px, 84px) rotate(135deg) translate(70px, 0);
    -webkit-transform: translate(84px, 84px) rotate(135deg) translate(70px, 0);
    -o-transform: translate(84px, 84px) rotate(135deg) translate(70px, 0);
    transform: translate(84px, 84px) rotate(135deg) translate(70px, 0);
  }
  .modaal-loading-spinner > div:nth-of-type(4) > div {
    animation: modaal-loading-spinner 1s linear infinite;
    -ms-animation-delay: 0.37s;
    -moz-animation-delay: 0.37s;
    -webkit-animation-delay: 0.37s;
    -o-animation-delay: 0.37s;
    animation-delay: 0.37s;
  }
  .modaal-loading-spinner > div:nth-of-type(4) {
    -ms-transform: translate(84px, 84px) rotate(180deg) translate(70px, 0);
    -moz-transform: translate(84px, 84px) rotate(180deg) translate(70px, 0);
    -webkit-transform: translate(84px, 84px) rotate(180deg) translate(70px, 0);
    -o-transform: translate(84px, 84px) rotate(180deg) translate(70px, 0);
    transform: translate(84px, 84px) rotate(180deg) translate(70px, 0);
  }
  .modaal-loading-spinner > div:nth-of-type(5) > div {
    animation: modaal-loading-spinner 1s linear infinite;
    -ms-animation-delay: 0.5s;
    -moz-animation-delay: 0.5s;
    -webkit-animation-delay: 0.5s;
    -o-animation-delay: 0.5s;
    animation-delay: 0.5s;
  }
  .modaal-loading-spinner > div:nth-of-type(6) > div, .modaal-loading-spinner > div:nth-of-type(7) > div {
    -ms-animation: modaal-loading-spinner 1s linear infinite;
    -moz-animation: modaal-loading-spinner 1s linear infinite;
    -webkit-animation: modaal-loading-spinner 1s linear infinite;
    -o-animation: modaal-loading-spinner 1s linear infinite;
  }
  .modaal-loading-spinner > div:nth-of-type(5) {
    -ms-transform: translate(84px, 84px) rotate(225deg) translate(70px, 0);
    -moz-transform: translate(84px, 84px) rotate(225deg) translate(70px, 0);
    -webkit-transform: translate(84px, 84px) rotate(225deg) translate(70px, 0);
    -o-transform: translate(84px, 84px) rotate(225deg) translate(70px, 0);
    transform: translate(84px, 84px) rotate(225deg) translate(70px, 0);
  }
  .modaal-loading-spinner > div:nth-of-type(6) > div {
    animation: modaal-loading-spinner 1s linear infinite;
    -ms-animation-delay: 0.62s;
    -moz-animation-delay: 0.62s;
    -webkit-animation-delay: 0.62s;
    -o-animation-delay: 0.62s;
    animation-delay: 0.62s;
  }
  .modaal-loading-spinner > div:nth-of-type(6) {
    -ms-transform: translate(84px, 84px) rotate(270deg) translate(70px, 0);
    -moz-transform: translate(84px, 84px) rotate(270deg) translate(70px, 0);
    -webkit-transform: translate(84px, 84px) rotate(270deg) translate(70px, 0);
    -o-transform: translate(84px, 84px) rotate(270deg) translate(70px, 0);
    transform: translate(84px, 84px) rotate(270deg) translate(70px, 0);
  }
  .modaal-loading-spinner > div:nth-of-type(7) > div {
    animation: modaal-loading-spinner 1s linear infinite;
    -ms-animation-delay: 0.75s;
    -moz-animation-delay: 0.75s;
    -webkit-animation-delay: 0.75s;
    -o-animation-delay: 0.75s;
    animation-delay: 0.75s;
  }
  .modaal-loading-spinner > div:nth-of-type(7) {
    -ms-transform: translate(84px, 84px) rotate(315deg) translate(70px, 0);
    -moz-transform: translate(84px, 84px) rotate(315deg) translate(70px, 0);
    -webkit-transform: translate(84px, 84px) rotate(315deg) translate(70px, 0);
    -o-transform: translate(84px, 84px) rotate(315deg) translate(70px, 0);
    transform: translate(84px, 84px) rotate(315deg) translate(70px, 0);
  }
  .modaal-loading-spinner > div:nth-of-type(8) > div {
    -ms-animation: modaal-loading-spinner 1s linear infinite;
    -moz-animation: modaal-loading-spinner 1s linear infinite;
    -webkit-animation: modaal-loading-spinner 1s linear infinite;
    -o-animation: modaal-loading-spinner 1s linear infinite;
    animation: modaal-loading-spinner 1s linear infinite;
    -ms-animation-delay: 0.87s;
    -moz-animation-delay: 0.87s;
    -webkit-animation-delay: 0.87s;
    -o-animation-delay: 0.87s;
    animation-delay: 0.87s;
  }
  .modaal-loading-spinner > div:nth-of-type(8) {
    -ms-transform: translate(84px, 84px) rotate(360deg) translate(70px, 0);
    -moz-transform: translate(84px, 84px) rotate(360deg) translate(70px, 0);
    -webkit-transform: translate(84px, 84px) rotate(360deg) translate(70px, 0);
    -o-transform: translate(84px, 84px) rotate(360deg) translate(70px, 0);
    transform: translate(84px, 84px) rotate(360deg) translate(70px, 0);
  }
  .page .singleContents p {
    font-size: 14px;
  }
  .singleContents {
    /* 投稿者情報
    -----------------------------------------*/
    /* メルマガ配信
    -----------------------------------------*/
  }
  .singleContents .has-inline-color.has-luminous-vivid-orange-color {
    color: #f07800 !important;
  }
  .singleContents .inner > *:first-child {
    margin-top: 0;
  }
  .singleContents p {
    font-size: 15px;
  }
  .singleContents p {
    margin-top: 30px;
    margin-bottom: 20px;
  }
  .singleContents h2 + p,
  .singleContents h3 + p,
  .singleContents h4 + p {
    margin-top: 20px;
    margin-bottom: 20px;
  }
  .singleContents * + h2 {
    margin-top: 40px;
  }
  .singleContents * + h3 {
    margin-top: 30px;
  }
  .singleContents * + h4 {
    margin-top: 30px;
  }
  .singleContents * + h2,
  .singleContents * + h3,
  .singleContents * + h4 {
    margin-bottom: 20px;
  }
  .singleContents h2 {
    font-size: 22px;
    padding-bottom: 15px;
  }
  .singleContents h3 {
    font-size: 18px;
    padding-left: 20px;
  }
  .singleContents h4 {
    font-size: 16px;
  }
  .singleContents ul li {
    padding-left: 20px;
    position: relative;
  }
  .singleContents ul li:not(:first-of-type) {
    margin-top: 10px;
  }
  .singleContents ul li:before {
    content: "";
    display: inline-block;
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background-color: #17184b;
    position: absolute;
    left: 3px;
    top: 10px;
  }
  .singleContents ul li ul {
    margin-bottom: 0.8em;
  }
  .singleContents * + ul,
  .singleContents * + ol {
    margin-top: 30px;
    margin-bottom: 30px;
  }
  .singleContents p + .wp-block-gallery {
    margin-top: 10px;
  }
  .singleContents .wp-block-button {
    margin-bottom: 20px;
  }
  .singleContents .wp-block-button a,
  .singleContents .wp-block-button .wp-block-button__link {
    box-shadow: 1px 1px 0 0 #17184b;
    margin: auto;
    padding: 12px 40px;
    width: 72%;
  }
  .singleContents .wp-block-button.btnOrange .wp-block-button__link {
    box-shadow: 1px 1px 0 0 #e8651a;
  }
  .singleContents .wp-block-buttons {
    margin-bottom: 20px;
  }
  .singleContents .wp-block-buttons .wp-block-button {
    display: block;
    margin-bottom: 0;
    width: 100%;
  }
  .singleContents .wp-block-table tr th {
    padding: 20px 20px;
  }
  .singleContents .wp-block-table tr td {
    padding: 20px 20px;
  }
  .singleContents .wp-block-image {
    margin-bottom: 30px;
  }
  .singleContents .wp-block-media-text .wp-block-media-text__content {
    margin-top: 10px;
    padding-left: 0;
    padding-right: 0;
  }
  .singleContents .wp-block-media-text {
    margin-bottom: 30px;
  }
  .singleContents .wp-block-group {
    margin-bottom: 30px;
  }
  .singleContents .colorBox {
    padding: 20px;
    margin-bottom: 30px;
  }
  .singleContents .colorBox .title {
    font-size: 16px;
    margin-bottom: 15px;
  }
  .singleContents .profileBox {
    padding: 20px;
    margin-bottom: 30px;
  }
  .singleContents .profileBox .profileTitle {
    font-size: 16px;
  }
  .singleContents .profileBox .profileInner .infoArea img {
    width: 70px;
  }
  .singleContents .profileBox .profileInner .authorPosition {
    font-size: 13px;
  }
  .singleContents .profileBox .profileInner .authorName {
    font-size: 16px;
  }
  .singleContents .profileBox .profileInner .authorProfile {
    font-size: 14px;
  }
  .singleContents .magazineBox {
    padding: 20px;
    margin-bottom: 30px;
  }
  .singleContents .magazineBox .title {
    font-size: 19px;
  }
  .singleContents .magazineBox .title .icon.iconMailMagazine {
    padding-left: 35px;
  }
  .singleContents .magazineBox .title .icon.iconMailMagazine::before {
    width: 27px;
    height: 29px;
  }
  .singleContents .magazineBox .magagineTxt {
    font-size: 14px;
    margin-top: 20px;
  }
  .singleContents .magazineBox .magaginePolicy {
    font-size: 11px;
  }
  .singleContents .magazineBox .mailMagazine {
    margin-top: 25px;
  }
  .singleContents .magazineBox .mailMagazine .magazineInput {
    height: 27.19px;
    width: 100%;
    max-width: 515px;
    padding: 5px;
  }
  .singleContents .magazineBox .mailMagazine .magazineSubmit {
    box-shadow: 1px 1px 0 0 #17184b;
    width: 94px;
    margin-top: 10px;
  }
  * + p {
    margin-top: 30px;
  }
  h2 + p,
  h3 + p,
  h4 + p {
    margin-top: 20px;
    margin-bottom: 20px;
  }
  * + h2.title,
  * + h2.title2 {
    margin-top: 40px;
  }
  * + h3.title {
    margin-top: 30px;
  }
  * + h4.title {
    margin-top: 30px;
  }
  h2.title {
    font-size: 22px;
    padding-bottom: 15px;
  }
  h2.title2 {
    font-size: 23px;
  }
  h2.title2 .small {
    font-size: 14px;
  }
  h3.title {
    font-size: 18px;
    padding-left: 20px;
  }
  h4.title {
    font-size: 16px;
  }
  ul.commonList li {
    padding-left: 20px;
    position: relative;
  }
  ul.commonList li:not(:first-of-type) {
    margin-top: 10px;
  }
  ul.commonList li:before {
    content: "";
    display: inline-block;
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background-color: #17184b;
    position: absolute;
    left: 3px;
    top: 10px;
  }
  ul.commonList li ul {
    margin-bottom: 0.8em;
  }
  section.bgGray {
    padding: 50px 0;
  }
  section.bgGray.pageBottom {
    margin-bottom: -50px;
  }
  section + section {
    margin-top: 60px;
  }
  * + .commonList,
  * + .linkList,
  * + .colorBox,
  * + ol,
  * + .commonTable {
    margin-top: 30px;
  }
  * + .imgLine2,
  * + .imgLine3,
  * + .twoCol {
    margin-top: 30px;
  }
  .catch {
    font-size: 23px;
  }
  .catch + p {
    margin-top: 20px;
  }
  a.icon {
    display: inline-block;
    position: relative;
    padding-right: 25px;
  }
  a.icon:after {
    content: "";
    display: block;
    width: 20px;
    height: 20px;
    background-repeat: no-repeat;
    background-position: 50% 0;
    position: absolute;
    right: 0px;
    top: 0.2em;
  }
  a.icon[href$=pdf]:after {
    background-image: url(../images/second/icon_pdf.svg);
  }
  a.icon[href$=doc]:after, a.icon[href$=docx]:after {
    background-image: url(../images/second/icon_word.svg);
  }
  a.icon[href$=xls]:after, a.icon[href$=xlsx]:after {
    background-image: url(../images/second/icon_excel.svg);
  }
  a.icon.front {
    padding-left: 25px;
    padding-right: 0;
  }
  a.icon.front:after {
    left: 0px;
  }
  .iconBlank {
    display: inline-block;
    width: 17px;
    height: 17px;
    background: url("../images/second/icon_blank.svg") no-repeat 0 0;
    background-size: cover;
    vertical-align: middle;
    margin-left: 10px;
    position: relative;
    top: -3px;
  }
  .commonBtn {
    margin: auto 0 0 0;
  }
  .imgLine2 li {
    text-align: center;
    margin-top: 20px;
  }
  .imgLine2 li img {
    max-width: 100%;
  }
  .imgLine3 li {
    text-align: center;
    margin-top: 20px;
  }
  .imgLine3 li img {
    max-width: 100%;
  }
  .imgInP .img {
    display: block;
    margin: 0 auto;
    padding-bottom: 20px;
  }
  .imgInP .img .cap {
    display: block;
    text-align: left;
    font-size: 14px;
    margin-top: 10px;
  }
  .imgInP.sp_img_btm {
    display: flex;
    flex-wrap: wrap;
  }
  .imgInP.sp_img_btm .img {
    display: block;
    order: 2;
    margin: 20px auto 0;
    padding-bottom: 0;
  }
  .imgInP.sp_img_btm .text {
    flex-basis: 100%;
    order: 1;
  }
  .twoCol > div:nth-of-type(n + 2) {
    margin-top: 30px;
  }
  .twoCol > div.movie a {
    display: block;
    transition: 0.3s;
    position: relative;
  }
  .twoCol > div.movie a:before {
    content: "";
    display: inline-block;
    background: url(../images/second/play.png) no-repeat;
    background-size: cover;
    width: 48px;
    height: 48px;
    position: absolute;
    top: calc(50% - 24px);
    left: calc(50% - 24px);
  }
  .twoCol > div.movie img {
    box-shadow: 6px 5px 12px 2px #cccccc, -8px -9px 11px 5px #ffffff;
  }
  .twoCol .cap {
    display: block;
    text-align: left;
    font-size: 14px;
    margin-top: 10px;
  }
  .colorBox {
    padding: 20px;
    margin-bottom: 30px;
  }
  .colorBox .title {
    font-size: 16px;
    margin-bottom: 15px;
  }
  .iframeWrap {
    position: relative;
    padding-bottom: 56.25%;
    margin-top: 30px;
  }
  .iframeWrap iframe {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
  }
  .commonTable td.wFix,
  .commonTable th.wFix {
    width: 120px;
  }
  .commonTable th {
    padding: 20px 20px;
  }
  .commonTable th.null {
    border-top: 1px solid #c1c1d0;
    border-left: 1px solid #c1c1d0;
    background-color: #fff;
  }
  .commonTable td {
    padding: 20px 20px;
  }
  .scrollTable {
    margin-top: 30px;
  }
  .scrollTable table {
    white-space: nowrap;
  }
  .scrollText {
    font-size: 12px;
    display: block;
    text-align: right;
    margin: 30px 0 -20px;
  }
  .dateArea {
    margin-bottom: 20px;
  }
  .dateArea .entryDate,
  .dateArea .entryUpDate {
    font-size: 12px;
    line-height: 1.3;
  }
  .pointerNone {
    cursor: default;
    text-decoration: none;
  }
  .taC {
    text-align: center !important;
  }
  .taL {
    text-align: left !important;
  }
  .taR {
    text-align: right !important;
  }
  .Center {
    margin: 0 auto !important;
  }
  .ovh {
    overflow: hidden !important;
  }
  .mw100 {
    max-width: 100% !important;
    width: auto !important;
  }
  .w100p {
    width: 100% !important;
  }
  .pT0 {
    padding-top: 0 !important;
  }
  .mB0 {
    margin-bottom: 0 !important;
  }
  .mB5 {
    margin-bottom: 5px !important;
  }
  .mB10 {
    margin-bottom: 10px !important;
  }
  .mB20 {
    margin-bottom: 20px !important;
  }
  .mB30 {
    margin-bottom: 30px !important;
  }
  .mB40 {
    margin-bottom: 40px !important;
  }
  .mB50 {
    margin-bottom: 50px !important;
  }
  .mT0 {
    margin-top: 0 !important;
  }
  .mT5 {
    margin-top: 5px !important;
  }
  .mT10 {
    margin-top: 10px !important;
  }
  .mT20 {
    margin-top: 20px !important;
  }
  .mT30 {
    margin-top: 30px !important;
  }
  .mT40 {
    margin-top: 40px !important;
  }
  .mT50 {
    margin-top: 50px !important;
  }
  .mL0 {
    margin-left: 0px !important;
  }
  .mL5 {
    margin-left: 5px !important;
  }
  .mL10 {
    margin-left: 10px !important;
  }
  .mL20 {
    margin-left: 20px !important;
  }
  .mL30 {
    margin-left: 30px !important;
  }
  .mL40 {
    margin-left: 40px !important;
  }
  .mL50 {
    margin-left: 50px !important;
  }
  .mR0 {
    margin-right: 0px !important;
  }
  .mR5 {
    margin-right: 5px !important;
  }
  .mR10 {
    margin-right: 10px !important;
  }
  .mR20 {
    margin-right: 20px !important;
  }
  .mR30 {
    margin-right: 30px !important;
  }
  .mR40 {
    margin-right: 40px !important;
  }
  .mR50 {
    margin-right: 50px !important;
  }
  .mB0 {
    margin-bottom: 0 !important;
  }
  .mB5_sp {
    margin-bottom: 5px !important;
  }
  .mB10_sp {
    margin-bottom: 10px !important;
  }
  .mB20_sp {
    margin-bottom: 20px !important;
  }
  .mB30_sp {
    margin-bottom: 30px !important;
  }
  .mB40_sp {
    margin-bottom: 40px !important;
  }
  .mB50_sp {
    margin-bottom: 50px !important;
  }
  .mT0 {
    margin-top: 0 !important;
  }
  .mT5_sp {
    margin-top: 5px !important;
  }
  .mT10_sp {
    margin-top: 10px !important;
  }
  .mT20_sp {
    margin-top: 20px !important;
  }
  .mT30_sp {
    margin-top: 30px !important;
  }
  .mT40_sp {
    margin-top: 40px !important;
  }
  .mT50_sp {
    margin-top: 50px !important;
  }
  .mL0_sp {
    margin-left: 0px !important;
  }
  .mL5_sp {
    margin-left: 5px !important;
  }
  .mL10_sp {
    margin-left: 10px !important;
  }
  .mL20_sp {
    margin-left: 20px !important;
  }
  .mL30_sp {
    margin-left: 30px !important;
  }
  .mL40_sp {
    margin-left: 40px !important;
  }
  .mL50_sp {
    margin-left: 50px !important;
  }
  .mR0_sp {
    margin-right: 0px !important;
  }
  .mR5_sp {
    margin-right: 5px !important;
  }
  .mR10_sp {
    margin-right: 10px !important;
  }
  .mR20_sp {
    margin-right: 20px !important;
  }
  .mR30_sp {
    margin-right: 30px !important;
  }
  .mR40_sp {
    margin-right: 40px !important;
  }
  .mR50_sp {
    margin-right: 50px !important;
  }
  .size12_sp {
    font-size: 12px !important;
  }
  .size14_sp {
    font-size: 14px !important;
  }
  .size16_sp {
    font-size: 16px !important;
  }
  .size18_sp {
    font-size: 18px !important;
  }
  .size20_sp {
    font-size: 20px !important;
  }
  .w50p_sp {
    width: 50% !important;
  }
  .bold {
    font-weight: bold !important;
  }
  .bdNone {
    border: none !important;
  }
  .underline {
    text-decoration: underline !important;
  }
  .textOrange {
    color: #f07800 !important;
  }
  .marker {
    background: linear-gradient(transparent 60%, #ffd4d5 60%) !important;
  }
  .floatL {
    float: left;
  }
  .floatR {
    float: right;
  }
  .preBox {
    background: #eff0f4;
    border: 1px solid #17184b;
    padding: 15px;
    box-sizing: border-box;
    clear: both;
    overflow: hidden;
    margin-top: 30px;
    font-family: Arial, sans-serif;
    line-height: 1.8;
  }
  .preBox pre {
    overflow: auto;
    white-space: pre-wrap;
    word-wrap: break-word;
    text-align-last: auto;
  }
}
@media print, screen and (min-width: 769px) {
  main a:hover {
    text-decoration: none;
  }
  h1,
  h2,
  h3,
  h4,
  h5 {
    line-height: 1.5;
  }
  .secSecond {
    margin-top: 80px;
  }
  .pageTitleWrap {
    min-height: 162px;
    border-bottom: 3px solid #dddddd;
  }
  .pageTitleWrap:after {
    content: "";
    display: inline-block;
    width: 130px;
    height: 3px;
    background-color: #192f60;
    position: absolute;
    bottom: -3px;
    left: 0;
  }
  .pageTitleWrap.post .pageTitleInner {
    max-width: 780px;
    padding: 40px 0 10px;
  }
  .pageTitleWrap .pageTitleInner {
    width: 90%;
    max-width: 1400px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    min-height: 162px;
    height: 100%;
  }
  .pageTitleWrap .pageTitleJp {
    font-size: 34px;
  }
  .pageTitleWrap .pageTitleEn {
    font-size: 16px;
  }
  .pageTitleWrap .tag {
    font-size: 13px;
  }
  .pageTitleWrap .subTitle {
    font-size: 14px;
    margin-left: 20px;
  }
  .breadcrumb {
    padding: 15px 0 15px 40px;
  }
  .breadcrumb li {
    font-size: 12px;
  }
  .breadcrumb li + li {
    margin-left: 35px;
  }
  .breadcrumb li + li:before {
    content: "／";
    position: absolute;
    top: 1px;
    left: -22px;
  }
  #contents {
    padding: 80px 0;
  }
  .inner {
    width: 90%;
    max-width: 1400px;
    margin: 0 auto;
  }
  .inner.narrow {
    max-width: 780px;
  }
  .inner.middle {
    max-width: 980px;
  }
  .inner.wide {
    max-width: none;
    width: 100%;
  }
  .inner.col2 {
    display: flex;
  }
  .inner.col2 > div {
    width: 50%;
  }
  .inner.col2 > div:first-of-type {
    padding-right: 4vw;
  }
  * + .articleList {
    margin-top: 40px;
  }
  .articleList {
    display: flex;
    flex-wrap: wrap;
  }
  .articleList .articleListItem {
    width: calc(33.3333333333% - 27px);
  }
  .articleList .articleListItem:not(:nth-of-type(3n + 1)) {
    margin-left: 40px;
  }
  .articleList .articleListItem:nth-of-type(n + 4) {
    margin-top: 60px;
  }
  .articleList .articleListItem figure {
    overflow: hidden;
  }
  .articleList .articleListItem figure img {
    transition: 0.3s transform cubic-bezier(0.25, 0.1, 0, 0.72);
  }
  .articleList .articleListItem > a:hover img {
    transform: scale(1.1);
  }
  .articleList .articleListItem .articleListTitle {
    margin-top: 10px;
    font-size: 16px;
  }
  .articleList .articleListItem .articleListSub {
    font-size: 13px;
    margin-top: 15px;
  }
  .articleNav a {
    display: inline-block;
    padding: 5px 10px;
    min-width: 154px;
    box-shadow: 2px 2px 0 0 #17184b;
    margin-right: 20px;
    font-size: 14px;
    transition: 0.3s;
  }
  .articleNav a:hover {
    background-color: #17184b;
    color: #fff;
  }
  .pager {
    margin-top: 60px;
  }
  .pager ul li {
    font-size: 16px;
    margin: 0 10px;
  }
  .pager ul li a:hover {
    border-bottom: 1px solid #17184b;
  }
  .pager ul .prev,
  .pager ul .next {
    position: relative;
    border: none;
    font-size: 15px;
  }
  .pager ul .prev:hover,
  .pager ul .next:hover {
    border: none;
  }
  .pager ul .prev {
    margin-right: 30px;
    padding-left: 25px;
  }
  .pager ul .prev:hover:before {
    left: -5px;
  }
  .pager ul .next {
    margin-left: 30px;
    padding-right: 25px;
  }
  .pager ul .next:hover:before {
    right: -5px;
  }
  .prevNextLink {
    margin-top: 80px;
    padding-top: 30px;
  }
  .prevNextLink .prev,
  .prevNextLink .next {
    min-width: 125px;
  }
  .prevNextLink .prev a {
    padding-left: 45px;
  }
  .prevNextLink .prev a:before {
    content: "";
    display: inline-block;
    background: url(../images/common/icon_arrow_more_left.svg) no-repeat;
    width: 24px;
    height: 24px;
    background-size: cover;
    transition: 0.3s background cubic-bezier(0.25, 0.1, 0, 0.72);
    position: absolute;
    top: 0;
    left: 0;
    transform: rotate(180deg);
  }
  .prevNextLink .prev a:hover:before {
    background: url(../images/common/icon_arrow_more_left_hov.svg) no-repeat;
    background-size: cover;
  }
  .prevNextLink .ichiran:hover {
    text-decoration: underline;
  }
  .prevNextLink .next a {
    padding-right: 45px;
  }
  .prevNextLink .next a:before {
    content: "";
    display: inline-block;
    background: url(../images/common/icon_arrow_more.svg) no-repeat;
    width: 24px;
    height: 24px;
    background-size: cover;
    transition: 0.3s background cubic-bezier(0.25, 0.1, 0, 0.72);
    position: absolute;
    top: 0;
    right: 0;
  }
  .prevNextLink .next a:hover:before {
    background: url(../images/common/icon_arrow_more_hov.svg) no-repeat;
    background-size: cover;
  }
  .whiteBox {
    padding: 60px;
  }
  .formWrap .is-error {
    font-size: 15px;
  }
  .formWrap input[type=checkbox] {
    display: none;
  }
  .formWrap input[type=checkbox] + label {
    position: relative;
    display: inline-block;
    padding: 3px 3px 3px 25px;
    cursor: pointer;
    -webkit-transition: all 0.2s;
    transition: all 0.2s;
  }
  .formWrap input[type=checkbox] + label::before,
  .formWrap input[type=checkbox] + label::after {
    position: absolute;
    content: "";
    -webkit-transition: all 0.2s;
    transition: all 0.2s;
  }
  .formWrap input[type=checkbox] + label::before {
    top: 50%;
    left: 0;
    width: 16px;
    height: 16px;
    margin-top: -8px;
    background: #fff;
    border: 1px solid #ccc;
    border-radius: 3px;
  }
  .formWrap input[type=checkbox] + label::after {
    opacity: 0;
    top: 50%;
    left: 3px;
    width: 10px;
    height: 5px;
    margin-top: -4px;
    border-left: 2px solid #17184b;
    border-bottom: 2px solid #17184b;
    -webkit-transform: rotate(-45deg) scale(0.5);
    transform: rotate(-45deg) scale(0.5);
  }
  .formWrap input[type=checkbox] + label:hover::before {
    background: #fff;
  }
  .formWrap input[type=checkbox]:checked + label::before {
    background: #fff;
    border: 1px solid #17184b;
  }
  .formWrap input[type=checkbox]:checked + label::after {
    opacity: 1;
    -webkit-transform: rotate(-45deg) scale(1);
    transform: rotate(-45deg) scale(1);
  }
  .formWrap input[type=submit].commonBtn {
    background-image: url(../images/common/icon_arrow_white.svg);
    background-repeat: no-repeat;
    background-position: center right 24px;
  }
  .formWrap dl + dl {
    margin-top: 25px;
  }
  .formWrap .hissu {
    font-size: 11px;
  }
  .formWrap br {
    display: none;
  }
  .formWrap input[type=submit] {
    margin-top: 40px;
    width: 288px;
    padding: 14px 40px;
    line-height: 1.2;
    transition: all 0.3s;
  }
  .formWrap input[type=submit]:not(:disabled) {
    cursor: pointer;
  }
  .formWrap input[type=submit]:not(:disabled):hover {
    background-color: #f07800;
    color: #fff;
  }
  .formWrap input[type=submit]:not(:disabled):hover::before {
    background-image: url(../images/common/icon_arrow_white.svg);
  }
  .featureList {
    margin-top: 80px;
  }
  .featureList .featureListItem {
    display: flex;
    min-height: 564px;
  }
  .featureList .featureListItem.feature1 {
    background: url(../images/second/feature_img_1.png) no-repeat center left;
    background-size: cover;
  }
  .featureList .featureListItem.feature2 {
    background: url(../images/second/feature_img_2.png) no-repeat center right;
    background-size: cover;
  }
  .featureList .featureListItem.feature3 {
    background: url(../images/second/feature_img_3.png) no-repeat center left;
    background-size: cover;
  }
  .featureList .featureListItem.feature4 {
    background: url(../images/second/feature_img_4.png) no-repeat center right;
    background-size: cover;
  }
  .featureList .featureListItem:nth-of-type(odd) .featureTextWrap {
    color: #fff;
    background-color: rgba(23, 24, 75, 0.95);
    margin: 0 0 0 auto;
  }
  .featureList .featureListItem:nth-of-type(odd) .featureTextWrap .subTitle:before {
    background-color: #fff;
  }
  .featureList .featureListItem:nth-of-type(odd) ul.commonList li:before {
    background-color: #fff;
  }
  .featureList .featureListItem:nth-of-type(even) .featureTextWrap {
    background-color: rgba(255, 255, 255, 0.95);
    margin: 0 auto 0 0;
  }
  .featureList .featureListItem:nth-of-type(even) .featureTextWrap .subTitle:before {
    background-color: #17184b;
  }
  .featureList .featureListItem figure {
    width: 50%;
    display: none;
  }
  .featureList .featureListItem .featureTextWrap {
    width: 50%;
    padding: 4vw 8vw;
  }
  .featureList .featureListItem .featureTextWrap .subTitle {
    padding-left: 50px;
  }
  .featureList .featureListItem .featureTextWrap .subTitle:before {
    content: "";
    display: inline-block;
    width: 33px;
    height: 1px;
    position: absolute;
    top: 50%;
    left: 0;
  }
  .featureList .featureListItem .featureTextWrap h2 {
    font-size: 30px;
    margin-top: 10px;
  }
  #caseStudy .read {
    margin-top: 40px;
    text-align: center;
  }
  #caseStudy h2 {
    font-size: 34px;
  }
  #recommend {
    padding: 80px 0;
  }
  #recommend .read {
    margin-top: 40px;
    text-align: center;
  }
  #recommend h2 {
    font-size: 34px;
  }
  #recommend.faqTop {
    background-color: #f8f8f8;
    padding: 90px 0 100px !important;
  }
  .solarAbout {
    min-height: 371px;
  }
  .solarAbout h2 {
    font-size: 34px;
  }
  .solarAbout h2 + p {
    font-size: 16px;
    margin-top: 40px;
    font-weight: bold;
    line-height: 2;
  }
  .solarAbout .fvBtnList {
    margin-top: 40px;
    display: flex;
    justify-content: center;
  }
  .solarAbout .fvBtnList li {
    display: inline-block;
  }
  .solarAbout .fvBtnList li + li {
    margin-left: 20px;
  }
  .solarAbout .fvBtnList li a {
    font-size: 16px;
    width: 250px;
    padding: 10px 10px;
  }
  .solarAbout .fvBtnList li a .icon.iconMaterial::before {
    width: 16px;
    height: 21px;
  }
  .solarAbout .fvBtnList li a .icon.iconMail {
    margin-right: 35px;
  }
  .solarAbout .fvBtnList li a .icon.iconMail::before {
    background-image: url(../images/common/icon_mail_white.svg);
    width: 22px;
    height: 17px;
  }
  .solarAbout .fvBtnList li.btnMaterial a:hover {
    background-color: #fff;
    color: #f07800;
    border-color: #f07800;
  }
  .solarAbout .fvBtnList li.btnMaterial a:hover .iconMaterial::before {
    background-image: url(../images/common/icon_document_orange.svg);
  }
  .solarAbout .fvBtnList li.btnContact a:hover {
    background-color: #fff;
    border-color: #17184b;
    color: #17184b;
  }
  .solarAbout .fvBtnList li.btnContact a:hover .iconMail::before {
    background-image: url(../images/common/icon_mail.svg);
  }
  .solarAbout .fvBtnList li.btnBlue a:hover {
    background-color: #fff;
    border-color: #17184b;
    color: #17184b;
  }
  .solarAbout .fvBtnList li.btnBlue a:hover:before {
    background: url(../images/common/icon_arrow.svg) no-repeat;
    background-size: contain;
  }
  .meritWrap h3 {
    font-size: 26px;
    margin-top: 50px;
    padding: 0 0 20px 50px;
  }
  .meritWrap h3 .meritNum {
    font-size: 14px;
  }
  .meritWrap h3 .meritNum span {
    font-size: 28px;
  }
  .meritWrap .meritCont {
    margin-top: 30px;
    display: flex;
  }
  .meritWrap .meritCont figure {
    width: 245px;
  }
  .meritWrap .meritCont figure img {
    margin-top: -15px;
  }
  .meritWrap .meritCont .textWrap {
    width: calc(100% - 245px);
    padding-left: 30px;
  }
  .meritWrap .meritCont .textWrap * + h4 {
    margin-top: 20px;
  }
  .meritWrap .meritCont .textWrap h4 {
    font-size: 16px;
    padding-left: 30px;
  }
  .meritWrap .meritCont .textWrap h4:before {
    content: "";
    display: inline-block;
    width: 22px;
    height: 2px;
    background-color: #17184b;
    position: absolute;
    top: calc(50% - 1px);
    left: 0;
  }
  .meritWrap .meritCont .textWrap p {
    margin-top: 15px;
  }
  .teianH2 {
    padding: 60px 0;
    margin-top: 80px;
  }
  .teianSystem {
    margin-top: 70px;
  }
  .teianSystem .inner {
    display: flex;
    justify-content: space-between;
  }
  .teianSystem .teianSystemItem {
    width: calc(33.3333333333% - 14px);
    border-radius: 10px;
  }
  .teianSystem .teianSystemItem h3 {
    border-radius: 10px 10px 0 0;
    padding: 20px 20px;
    font-size: 20px;
  }
  .teianSystem .teianSystemItem h3 span {
    font-size: 26px;
  }
  .teianSystem .teianSystemItem .teianSystemCont {
    padding: 20px 20px;
    border-radius: 0 0 10px 10px;
  }
  .btnCol {
    display: flex;
    justify-content: center;
  }
  .btnCol .commonBtn + .commonBtn {
    margin-left: 20px;
  }
  .sindanImg {
    text-align: center;
    margin-top: 60px;
  }
  #contents .gmap {
    margin-top: 30px;
  }
  #contents h2 + .commonImgBox,
  #contents h3 + .commonImgBox,
  #contents h4 + .commonImgBox {
    margin: 30px 0 40px;
  }
  #contents .commonImgBox {
    align-items: flex-start;
    justify-content: space-between;
    flex-flow: nowrap;
    margin: 70px 0 40px;
  }
  #contents .commonImgBox .txt {
    width: 70%;
  }
  #contents .commonImgBox .img {
    width: 30%;
    margin-left: 60px;
  }
  #contents .commonImgBox .img img {
    width: 100%;
  }
  #contents .commonImgBox.imgLeft {
    flex-direction: row-reverse;
  }
  #contents .commonImgBox.imgLeft .img {
    margin-left: 0;
    margin-right: 60px;
  }
  #contents .commonImgBox.imgBig {
    align-items: center;
  }
  #contents .commonImgBox.imgBig .img {
    width: 70%;
  }
  #contents .commonImgBox.imgBig .img .txt {
    width: 30%;
  }
  #contents .commonImgBox.imgRight {
    flex-direction: row;
  }
  #contents .commonImgBox.imgRight .img {
    margin-left: 60px;
  }
  #contents .cardListWrap li {
    padding: 20px;
    width: calc(50% - 10px);
  }
  #contents .cardListWrap li:nth-child(n+3) {
    margin-top: 20px;
  }
  #contents .cardListWrap a:hover .viewmore::before {
    transform: translateY(-50%) scaleX(6);
  }
  #contents .cardListWrap .img {
    width: 214px;
    margin-right: 10px;
  }
  #contents .cardListWrap img {
    width: 100%;
  }
  #contents .cardListWrap .txt {
    width: calc(100% - 214px - 10px);
  }
  #contents .cardListWrap .txt .ttl {
    font-size: 20px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
  }
  #contents .cardListWrap .txt .viewmore {
    font-size: 16px;
    position: absolute;
    bottom: 0;
    right: 0;
  }
  #contents .cardListWrap .txt .viewmore::before {
    width: 18px;
    height: 1px;
    top: 50%;
    left: -30px;
  }
  #contents .leadTtl {
    font-size: 34px;
    line-height: 1.5;
    margin: 30px 0 50px;
  }
  #contents .mainTtl1 {
    font-size: 30px;
    margin: 50px 0 30px;
  }
  #contents .subTtl {
    font-size: 18px;
  }
  #contents .rinenListWrap {
    display: flex;
    justify-content: space-between;
    margin: 40px 0 70px;
  }
  #contents .rinenListWrap .rinenBorder {
    border-left: 1px solid #17184b;
  }
  #contents .rinenListWrap .rinenBorder:last-child {
    border-right: 1px solid #17184b;
  }
  #contents .rinenListWrap .rinenList {
    text-align: center;
  }
  #contents .rinenListWrap .rinenList dt {
    display: flex;
    justify-content: center;
  }
  #contents .rinenListWrap .rinenList dt img {
    width: 48px;
    height: auto;
  }
  #contents .rinenListWrap .rinenList dd {
    font-size: 16px;
    line-height: 2;
    margin-top: 36px;
  }
  #contents .rinenListWrap.txtListWrap .rinenList {
    padding-bottom: 30px;
  }
  #contents .rinenListWrap.txtListWrap .rinenList dd {
    font-size: 18px;
    margin-top: 24px;
  }
  .numberWrap .subTtl {
    font-size: 15px;
  }
  .voiceList a.voiceCont {
    margin-top: 80px;
    display: flex;
    justify-content: space-between;
    gap: 0 20px;
  }
  .voiceList a.voiceCont:hover .voiceImg img {
    transform: scale(1.1);
  }
  .voiceList a.voiceCont:hover .btnWrap::after {
    right: 2px;
  }
  .voiceList a.voiceCont:hover .viewMore::after {
    transform: scale(1, 1);
  }
  .voiceList a.voiceCont:nth-of-type(even) {
    flex-direction: row-reverse;
  }
  .voiceList a.voiceCont .descWrap {
    width: 50%;
  }
  .voiceList a.voiceCont .voiceSubTitle {
    font-size: 14px;
    margin-bottom: 20px;
  }
  .voiceList a.voiceCont .voiceSubTitle .number {
    font-family: "Jost", sans-serif;
    font-weight: 600;
  }
  .voiceList a.voiceCont .voiceSubTitle .cut {
    margin: 0 10px;
  }
  .voiceList a.voiceCont h3 {
    font-size: 29px;
    line-height: 1.72;
    margin-bottom: 20px;
  }
  .voiceList a.voiceCont .desc {
    line-height: 2;
    margin-bottom: 25px;
  }
  .voiceList a.voiceCont .voiceImg {
    width: 43.7%;
    height: 100%;
    margin-top: 14px;
    box-shadow: 0px 5px 20px 0px rgba(0, 0, 0, 0.15);
  }
  .voiceList a.voiceCont .voiceImg img {
    object-fit: cover;
    transition: 0.3s transform cubic-bezier(0.25, 0.1, 0, 0.72);
  }
  .voiceList a.voiceCont .btnWrap::after {
    width: 15px;
    height: 13px;
    top: 9px;
    right: 7px;
    transition: 0.3s;
  }
  .voiceList a.voiceCont .viewMore {
    font-size: 14px;
    padding-right: 40px;
  }
  .voiceList a.voiceCont .viewMore::after {
    content: "";
    display: block;
    background-color: #17184b;
    width: 100%;
    height: 1px;
    position: absolute;
    left: 0;
    bottom: -3px;
    transform: scale(0, 1);
    transform-origin: left top;
    transition: 0.3s;
  }
  .main_contact .headDeco {
    display: none;
  }
  .aform input[type=text],
  .aform input[type=password],
  .aform input[type=tel],
  .aform input[type=email],
  .aform select,
  .aform textarea {
    border: 1px solid #a4a4a4;
    border-radius: 4px;
    padding: 10px;
    width: 100%;
    background-color: #fff;
  }
  .aform input[type=text].error,
  .aform input[type=password].error,
  .aform input[type=tel].error,
  .aform input[type=email].error,
  .aform select.error,
  .aform textarea.error {
    background-color: #fff1f2;
  }
  .aform input[type=text].size-s,
  .aform input[type=password].size-s,
  .aform input[type=tel].size-s,
  .aform input[type=email].size-s,
  .aform select.size-s {
    width: 12%;
  }
  .aform input[type=text].size-sm,
  .aform input[type=password].size-sm,
  .aform input[type=tel].size-sm,
  .aform input[type=email].size-sm,
  .aform select.size-sm {
    width: 15%;
  }
  .aform input[type=text].size-m,
  .aform input[type=password].size-m,
  .aform input[type=tel].size-m,
  .aform input[type=email].size-m,
  .aform select.size-m {
    width: 35%;
  }
  .aform input[type=text].size-ml,
  .aform input[type=password].size-ml,
  .aform input[type=tel].size-ml,
  .aform input[type=email].size-ml,
  .aform select.size-ml {
    width: 43%;
  }
  .aform input[type=text].size-l,
  .aform input[type=password].size-l,
  .aform input[type=tel].size-l,
  .aform input[type=email].size-l,
  .aform select.size-l {
    width: 65%;
  }
  .aform input[type=text].size-special,
  .aform input[type=password].size-special,
  .aform input[type=tel].size-special,
  .aform input[type=email].size-special,
  .aform select.size-special {
    width: 43%;
  }
  .aform input[type=text].year,
  .aform input[type=password].year,
  .aform input[type=tel].year,
  .aform input[type=email].year,
  .aform select.year {
    width: 80px;
  }
  .aform input[type=text].month,
  .aform input[type=password].month,
  .aform input[type=tel].month,
  .aform input[type=email].month,
  .aform select.month {
    width: 70px;
  }
  .aform select.year {
    width: 80px;
  }
  .aform select.month {
    width: 70px;
  }
  .aform button {
    border: 1px solid #ccc;
    padding: 4px 8px;
    background-color: #f6f6f6;
    box-shadow: 0px 1px 1px 0px #cccccc;
    font-size: 14px;
  }
  .aform button:hover {
    opacity: 0.7;
    cursor: pointer;
  }
  .aform input[type=radio] {
    display: none;
  }
  .aform input[type=radio] + label {
    position: relative;
    display: inline-block;
    padding: 3px 3px 3px 25px;
    cursor: pointer;
    margin-right: 20px;
  }
  .aform input[type=radio] + label::before,
  .aform input[type=radio] + label::after {
    position: absolute;
    content: "";
    top: 50%;
    border-radius: 100%;
    -webkit-transition: all 0.2s;
    transition: all 0.2s;
  }
  .aform input[type=radio] + label::before {
    left: 0;
    width: 17px;
    height: 17px;
    margin-top: -10px;
    background: #fff;
    border: 1px solid #ccc;
  }
  .aform input[type=radio] + label:hover::before {
    background: #fff;
  }
  .aform input[type=radio] + label::after {
    opacity: 0;
    left: 4px;
    width: 9px;
    height: 9px;
    margin-top: -6px;
    background: #5dbf5d;
    -webkit-transform: scale(2);
    transform: scale(2);
  }
  .aform input[type=radio]:checked + label::before {
    background: #fff;
    border: 1px solid #5dbf5d;
  }
  .aform input[type=radio]:checked + label::after {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  .aform input[type=checkbox] {
    opacity: 0;
    width: 1px;
    height: 1px;
  }
  .aform input[type=checkbox] + label {
    position: relative;
    display: inline-block;
    padding: 3px 3px 3px 40px;
    cursor: pointer;
    -webkit-transition: all 0.2s;
    transition: all 0.2s;
  }
  .aform input[type=checkbox] + label::before,
  .aform input[type=checkbox] + label::after {
    position: absolute;
    content: "";
    -webkit-transition: all 0.2s;
    transition: all 0.2s;
  }
  .aform input[type=checkbox] + label::before {
    top: 50%;
    left: 0;
    width: 20px;
    height: 20px;
    margin-top: -11px;
    background: #fff;
    border: 2px solid #ccc;
    border-radius: 3px;
  }
  .aform input[type=checkbox] + label::after {
    opacity: 0;
    border-left: 2px solid #17184b;
    border-bottom: 2px solid #17184b;
    -webkit-transform: rotate(-45deg) scale(0.5);
    transform: rotate(-45deg) scale(0.5);
    top: 50%;
    left: 5px;
    width: 11px;
    height: 7px;
    margin-top: -6px;
  }
  .aform input[type=checkbox] + label:hover::before {
    background: #fff;
  }
  .aform input[type=checkbox]:checked + label::before {
    background: #fff;
    border: 1px solid #ccc;
  }
  .aform input[type=checkbox]:checked + label::after {
    opacity: 1;
    -webkit-transform: rotate(-45deg) scale(1);
    transform: rotate(-45deg) scale(1);
  }
  .aform.selectType {
    display: flex;
  }
  .aform.selectType .form_dt {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 250px;
    margin-right: 25px;
  }
  .aform.selectType .form_dt .aform-required {
    vertical-align: middle;
    background-color: #f07800;
    color: #fff;
    font-size: 12px;
    padding: 4px 8px 5px;
    width: 40px;
    line-height: 1;
    display: inline-block;
    text-align: center;
    margin-left: 4px;
    white-space: nowrap;
  }
  .aform.selectType select {
    width: 45%;
    background-color: #f8f8f8;
    border: none;
  }
  .form_dt {
    display: block;
    font-weight: bold;
    font-size: 16px;
  }
  .aform .aform-content {
    margin-bottom: 20px;
  }
  .aform .aform-content .aform-note {
    background: none;
    margin-top: 20px;
    margin-bottom: 20px;
  }
  .aform .aform-content dl {
    font-size: 16px;
    margin: 30px 0;
    padding-bottom: 30px;
    border-bottom: 1px solid #f8f8f8;
    display: flex;
    align-items: center;
  }
  .aform .aform-content dt {
    text-align: left;
    font-weight: normal;
    background-clip: padding-box;
    float: none;
    position: relative;
    border-top: none;
    border-right: none;
    vertical-align: top;
    font-weight: bold;
    margin-right: 25px;
  }
  .aform .aform-content dt .aform-required {
    vertical-align: middle;
    background-color: #f07800;
    color: #fff;
    font-size: 12px;
    padding: 4px 8px 5px;
    width: 40px;
    line-height: 1;
    display: inline-block;
    text-align: center;
    margin-left: 4px;
    white-space: nowrap;
  }
  .aform .aform-content dt .aform-label {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 250px;
  }
  .aform .aform-content dd {
    display: block;
    float: none;
    border-top: none;
    border-left: none;
    width: 100%;
  }
  .aform .aform-content dd input {
    margin-top: 5px;
    background-color: #f8f8f8;
    border: none;
  }
  .aform .aform-content dd .kome {
    display: block;
    font-size: 14px;
    margin-top: 5px;
  }
  .aform .aform-content dd .category {
    width: 130px;
    display: inline-block;
  }
  .aform .aform-content dd .subtxt {
    width: 43px;
    display: inline-block;
    text-align: right;
    padding-right: 10px;
  }
  .aform .aform-content .aform-textarea,
  .aform .aform-content select.aform-prefecture {
    background-color: #f8f8f8;
    border: none;
  }
  .aform .aform-content select.aform-prefecture {
    margin-top: 10px;
  }
  .aform .aform-content .aform-textarea {
    width: 100%;
    padding: 10px;
    min-height: 150px;
  }
  .aform .aform-content .aform-name,
  .aform .aform-content .aform-kana,
  .aform .aform-content .aform-input-example {
    margin: 10px 0;
  }
  .aform .aform-content .aform-name-ul,
  .aform .aform-content .aform-kana-ul {
    display: flex;
  }
  .aform .aform-content .aform-name-ul li,
  .aform .aform-content .aform-kana-ul li {
    flex: 1;
  }
  .aform .aform-content .aform-name-ul li + li,
  .aform .aform-content .aform-kana-ul li + li {
    margin-left: 1em;
  }
  .aform .aform-content .aform-twice-note {
    display: block;
    margin: 10px 0;
  }
  .aform .aform-content .dl_contactContents {
    align-items: flex-start;
  }
  .aform .aform-content .dl_contactContents dd {
    width: 100%;
  }
  .aform .aform-content .dl_privacyPolicy {
    display: block;
    border-bottom: none;
    margin: 30px 0 0;
    padding-bottom: 10px;
  }
  .aform .aform-content .dl_privacyPolicy dd {
    background-color: #f8f8f8;
    font-weight: bold;
    margin: 40px auto 0;
    padding: 26px 30px;
    width: 60%;
    text-align: center;
  }
  .aform .aform-content .dl_privacyPolicy dd .pp_txt {
    font-size: 14px;
    line-height: 1.5;
  }
  .aform .aform-content .aform-error {
    color: #cf0000;
    font-weight: bold;
    margin-top: 10px;
    font-size: 14px;
  }
  .aform .aform-content .aform-error:first-child {
    margin-top: 0;
  }
  .aform .aform-content .aform-error .validation-advice {
    padding-top: 4px;
    line-height: 1.4;
  }
  .aform .aform-content .aform-button-area {
    margin-top: 30px;
    background: none;
    padding: 0;
    text-align: center;
    display: flex;
    flex-direction: column;
  }
  .aform .aform-content .aform-button-area:after {
    content: "";
    display: block;
    clear: both;
  }
  .aform .aform-content .aform-button-area input[type=submit] {
    order: 1;
    box-shadow: 2px 2px 0 0 #17184b;
    width: 288px;
    padding: 14px 40px;
    line-height: 1.2;
    transition: all 0.3s;
  }
  .aform .aform-content .aform-button-area input[type=submit]:hover {
    background-color: #17184b;
    color: #fff;
    background-image: url(../images/common/icon_arrow_white.svg);
  }
  .nofloat {
    float: none !important;
  }
  #aform_btn_back {
    font-size: 16px;
    order: 2;
    display: inline-block;
    padding: 12px 35px;
    border: 1px solid #aaa;
    background-color: #fff;
    color: #666 !important;
    width: 250px;
    text-align: center;
    margin: 0 10px 20px;
    vertical-align: top;
    position: relative;
    cursor: pointer;
    margin: 20px auto;
  }
  #aform_btn_back:hover {
    opacity: 0.7;
  }
  .aform .aform-content dl.aform-error-list {
    border: none;
  }
  .aform .aform-content dl.aform-error-list dt {
    display: block;
  }
  .aform .aform-content dl.aform-error-list dd {
    display: list-item;
    margin-left: 25px;
    width: auto;
  }
  #aform_confirm_msg {
    text-align: center;
    padding-top: 20px;
  }
  img[src$="icon_new_windows.gif"] {
    margin-left: 10px;
    vertical-align: middle;
  }
  .aform-header {
    display: none;
  }
  .aform select.aform-calendar {
    width: auto;
  }
  .aform select.aform-calendar + label {
    margin-left: 7px;
    margin-right: 12px;
  }
  .aform #aform_result {
    color: #000;
  }
  .aform #aform_result a.commonBtn {
    margin: 20px auto 30px;
  }
  /*!
  	Modaal - accessible modals - v0.4.4
  	by Humaan, for all humans.
  	http://humaan.com
   */
  .modaal-noscroll {
    overflow: hidden;
  }
  .modaal-accessible-hide {
    position: absolute !important;
    clip: rect(1px 1px 1px 1px); /* IE6, IE7 */
    clip: rect(1px, 1px, 1px, 1px);
    padding: 0 !important;
    border: 0 !important;
    height: 1px !important;
    width: 1px !important;
    overflow: hidden;
  }
  .modaal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 999;
    opacity: 0;
  }
  .modaal-wrapper {
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 9999;
    overflow: auto;
    opacity: 1;
    box-sizing: border-box;
    -webkit-overflow-scrolling: touch;
    transition: all 0.3s ease-in-out;
  }
  .modaal-wrapper * {
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-backface-visibility: hidden;
  }
  .modaal-wrapper .modaal-close {
    border: none;
    background: transparent;
    padding: 0;
    -webkit-appearance: none;
  }
  .modaal-wrapper.modaal-start_none {
    display: none;
    opacity: 1;
  }
  .modaal-wrapper.modaal-start_fade {
    opacity: 0;
  }
  .modaal-wrapper *[tabindex="0"] {
    outline: none !important;
  }
  .modaal-wrapper.modaal-fullscreen {
    overflow: hidden;
  }
  .modaal-outer-wrapper {
    display: table;
    position: relative;
    width: 100%;
    height: 100%;
  }
  .modaal-fullscreen .modaal-outer-wrapper {
    display: block;
  }
  .modaal-inner-wrapper {
    display: table-cell;
    width: 100%;
    height: 100%;
    position: relative;
    vertical-align: middle;
    text-align: center;
    padding: 80px 25px;
  }
  .modaal-fullscreen .modaal-inner-wrapper {
    padding: 0;
    display: block;
    vertical-align: top;
  }
  .modaal-container {
    position: relative;
    display: inline-block;
    width: 100%;
    margin: auto;
    text-align: left;
    color: #000;
    max-width: 1000px;
    border-radius: 0px;
    background: #fff;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
    cursor: auto;
  }
  .modaal-container.is_loading {
    height: 100px;
    width: 100px;
    overflow: hidden;
  }
  .modaal-fullscreen .modaal-container {
    max-width: none;
    height: 100%;
    overflow: auto;
  }
  .modaal-close {
    position: fixed;
    right: 20px;
    top: 20px;
    color: #fff;
    cursor: pointer;
    opacity: 1;
    width: 50px;
    height: 50px;
    background: rgba(0, 0, 0, 0);
    border-radius: 100%;
    transition: all 0.2s ease-in-out;
  }
  .modaal-close:focus, .modaal-close:hover {
    outline: none;
    background: #fff;
  }
  .modaal-close:focus:before, .modaal-close:focus:after, .modaal-close:hover:before, .modaal-close:hover:after {
    background: #b93d0c;
  }
  .modaal-close span {
    position: absolute !important;
    clip: rect(1px 1px 1px 1px); /* IE6, IE7 */
    clip: rect(1px, 1px, 1px, 1px);
    padding: 0 !important;
    border: 0 !important;
    height: 1px !important;
    width: 1px !important;
    overflow: hidden;
  }
  .modaal-close:before, .modaal-close:after {
    display: block;
    content: " ";
    position: absolute;
    top: 14px;
    left: 23px;
    width: 4px;
    height: 22px;
    border-radius: 4px;
    background: #fff;
    transition: background 0.2s ease-in-out;
  }
  .modaal-close:before {
    transform: rotate(-45deg);
  }
  .modaal-close:after {
    transform: rotate(45deg);
  }
  .modaal-fullscreen .modaal-close {
    background: #afb7bc;
    right: 10px;
    top: 10px;
  }
  .modaal-content-container {
    padding: 30px;
  }
  .modaal-confirm-wrap {
    padding: 30px 0 0;
    text-align: center;
    font-size: 0;
  }
  .modaal-confirm-btn {
    font-size: 14px;
    display: inline-block;
    margin: 0 10px;
    vertical-align: middle;
    cursor: pointer;
    border: none;
    background: transparent;
  }
  .modaal-confirm-btn.modaal-ok {
    padding: 10px 15px;
    color: #fff;
    background: #555;
    border-radius: 3px;
    transition: background 0.2s ease-in-out;
  }
  .modaal-confirm-btn.modaal-ok:hover {
    background: #2f2f2f;
  }
  .modaal-confirm-btn.modaal-cancel {
    text-decoration: underline;
  }
  .modaal-confirm-btn.modaal-cancel:hover {
    text-decoration: none;
    color: #2f2f2f;
  }
  @keyframes instaReveal {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  @-o-keyframes instaReveal {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  @-moz-keyframes instaReveal {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  @-webkit-keyframes instaReveal {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  @-ms-keyframes instaReveal {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  .modaal-instagram .modaal-container {
    width: auto;
    background: transparent;
    box-shadow: none !important;
  }
  .modaal-instagram .modaal-content-container {
    padding: 0;
    background: transparent;
  }
  .modaal-instagram .modaal-content-container > blockquote {
    width: 1px !important;
    height: 1px !important;
    opacity: 0 !important;
  }
  .modaal-instagram iframe {
    opacity: 0;
    margin: -6px !important;
    border-radius: 0 !important;
    width: 1000px !important;
    max-width: 800px !important;
    box-shadow: none !important;
    animation: instaReveal 1s linear forwards;
  }
  .modaal-image .modaal-inner-wrapper {
    padding-left: 140px;
    padding-right: 140px;
  }
  .modaal-image .modaal-container {
    width: auto;
    max-width: 100%;
  }
  .modaal-gallery-wrap {
    position: relative;
    color: #fff;
  }
  .modaal-gallery-item {
    display: none;
  }
  .modaal-gallery-item img {
    display: block;
  }
  .modaal-gallery-item.is_active {
    display: block;
  }
  .modaal-gallery-label {
    position: absolute;
    left: 0;
    width: 100%;
    margin: 20px 0 0;
    font-size: 18px;
    text-align: center;
    color: #fff;
  }
  .modaal-gallery-label:focus {
    outline: none;
  }
  .modaal-gallery-control {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    opacity: 1;
    cursor: pointer;
    color: #fff;
    width: 50px;
    height: 50px;
    background: rgba(0, 0, 0, 0);
    border: none;
    border-radius: 100%;
    transition: all 0.2s ease-in-out;
  }
  .modaal-gallery-control.is_hidden {
    opacity: 0;
    cursor: default;
  }
  .modaal-gallery-control:focus, .modaal-gallery-control:hover {
    outline: none;
    background: #fff;
  }
  .modaal-gallery-control:focus:before, .modaal-gallery-control:focus:after, .modaal-gallery-control:hover:before, .modaal-gallery-control:hover:after {
    background: #afb7bc;
  }
  .modaal-gallery-control span {
    position: absolute !important;
    clip: rect(1px 1px 1px 1px); /* IE6, IE7 */
    clip: rect(1px, 1px, 1px, 1px);
    padding: 0 !important;
    border: 0 !important;
    height: 1px !important;
    width: 1px !important;
    overflow: hidden;
  }
  .modaal-gallery-control:before, .modaal-gallery-control:after {
    display: block;
    content: " ";
    position: absolute;
    top: 16px;
    left: 25px;
    width: 4px;
    height: 18px;
    border-radius: 4px;
    background: #fff;
    transition: background 0.2s ease-in-out;
  }
  .modaal-gallery-control:before {
    margin: -5px 0 0;
    transform: rotate(-45deg);
  }
  .modaal-gallery-control:after {
    margin: 5px 0 0;
    transform: rotate(45deg);
  }
  .modaal-gallery-next-inner {
    left: 100%;
    margin-left: 40px;
  }
  .modaal-gallery-next-outer {
    right: 45px;
  }
  .modaal-gallery-prev:before, .modaal-gallery-prev:after {
    left: 22px;
  }
  .modaal-gallery-prev:before {
    margin: 5px 0 0;
    transform: rotate(-45deg);
  }
  .modaal-gallery-prev:after {
    margin: -5px 0 0;
    transform: rotate(45deg);
  }
  .modaal-gallery-prev-inner {
    right: 100%;
    margin-right: 40px;
  }
  .modaal-gallery-prev-outer {
    left: 45px;
  }
  .modaal-video-wrap {
    margin: auto 50px;
    position: relative;
  }
  .modaal-video-container {
    position: relative;
    padding-bottom: 56.25%;
    height: 0;
    overflow: hidden;
    max-width: 100%;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    background: #000;
    max-width: 1300px;
    margin-left: auto;
    margin-right: auto;
  }
  .modaal-video-container iframe,
  .modaal-video-container object,
  .modaal-video-container embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  .modaal-iframe .modaal-content {
    width: 100%;
    height: 100%;
  }
  .modaal-iframe-elem {
    width: 100%;
    height: 100%;
    display: block;
  }
  /* common class */
}
@media only screen and (min-width: 769px) and (min-width: 1400px) {
  .modaal-video-container {
    padding-bottom: 0;
    height: 731px;
  }
}
@media only screen and (min-width: 769px) and (max-width: 1140px) {
  .modaal-image .modaal-inner-wrapper {
    padding-left: 25px;
    padding-right: 25px;
  }
  .modaal-gallery-control {
    top: auto;
    bottom: 20px;
    transform: none;
    background: rgba(0, 0, 0, 0.7);
  }
  .modaal-gallery-control:before, .modaal-gallery-control:after {
    background: #fff;
  }
  .modaal-gallery-next {
    left: auto;
    right: 20px;
  }
  .modaal-gallery-prev {
    left: 20px;
    right: auto;
  }
}
@media screen and (min-width: 769px) and (max-width: 900px) {
  .modaal-instagram iframe {
    width: 500px !important;
  }
}
@media screen and (min-width: 769px) and (max-height: 1100px) {
  .modaal-instagram iframe {
    width: 700px !important;
  }
}
@media screen and (min-width: 769px) and (max-height: 1000px) {
  .modaal-inner-wrapper {
    padding-top: 60px;
    padding-bottom: 60px;
  }
  .modaal-instagram iframe {
    width: 600px !important;
  }
}
@media screen and (min-width: 769px) and (max-height: 900px) {
  .modaal-instagram iframe {
    width: 500px !important;
  }
  .modaal-video-container {
    max-width: 900px;
    max-height: 510px;
  }
}
@media only screen and (min-width: 769px) and (max-width: 600px) {
  .modaal-instagram iframe {
    width: 280px !important;
  }
}
@media only screen and (min-width: 769px) and (max-height: 820px) {
  .modaal-gallery-label {
    display: none;
  }
}
@media print, screen and (min-width: 769px) {
  .modaal-loading-spinner {
    background: none;
    position: absolute;
    width: 200px;
    height: 200px;
    top: 50%;
    left: 50%;
    margin: -100px 0 0 -100px;
    transform: scale(0.25);
  }
  @-ms-keyframes modaal-loading-spinner {
    0% {
      opacity: 1;
      -ms-transform: scale(1.5);
      -moz-transform: scale(1.5);
      -webkit-transform: scale(1.5);
      -o-transform: scale(1.5);
      transform: scale(1.5);
    }
    100% {
      opacity: 0.1;
      -ms-transform: scale(1);
      -moz-transform: scale(1);
      -webkit-transform: scale(1);
      -o-transform: scale(1);
      transform: scale(1);
    }
  }
  @-moz-keyframes modaal-loading-spinner {
    0% {
      opacity: 1;
      -ms-transform: scale(1.5);
      -moz-transform: scale(1.5);
      -webkit-transform: scale(1.5);
      -o-transform: scale(1.5);
      transform: scale(1.5);
    }
    100% {
      opacity: 0.1;
      -ms-transform: scale(1);
      -moz-transform: scale(1);
      -webkit-transform: scale(1);
      -o-transform: scale(1);
      transform: scale(1);
    }
  }
  @-webkit-keyframes modaal-loading-spinner {
    0% {
      opacity: 1;
      -ms-transform: scale(1.5);
      -moz-transform: scale(1.5);
      -webkit-transform: scale(1.5);
      -o-transform: scale(1.5);
      transform: scale(1.5);
    }
    100% {
      opacity: 0.1;
      -ms-transform: scale(1);
      -moz-transform: scale(1);
      -webkit-transform: scale(1);
      -o-transform: scale(1);
      transform: scale(1);
    }
  }
  @-o-keyframes modaal-loading-spinner {
    0% {
      opacity: 1;
      -ms-transform: scale(1.5);
      -moz-transform: scale(1.5);
      -webkit-transform: scale(1.5);
      -o-transform: scale(1.5);
      transform: scale(1.5);
    }
    100% {
      opacity: 0.1;
      -ms-transform: scale(1);
      -moz-transform: scale(1);
      -webkit-transform: scale(1);
      -o-transform: scale(1);
      transform: scale(1);
    }
  }
  @keyframes modaal-loading-spinner {
    0% {
      opacity: 1;
      -ms-transform: scale(1.5);
      -moz-transform: scale(1.5);
      -webkit-transform: scale(1.5);
      -o-transform: scale(1.5);
      transform: scale(1.5);
    }
    100% {
      opacity: 0.1;
      -ms-transform: scale(1);
      -moz-transform: scale(1);
      -webkit-transform: scale(1);
      -o-transform: scale(1);
      transform: scale(1);
    }
  }
  .modaal-loading-spinner > div {
    width: 24px;
    height: 24px;
    margin-left: 4px;
    margin-top: 4px;
    position: absolute;
  }
  .modaal-loading-spinner > div > div {
    width: 100%;
    height: 100%;
    border-radius: 15px;
    background: #fff;
  }
  .modaal-loading-spinner > div:nth-of-type(1) > div {
    -ms-animation: modaal-loading-spinner 1s linear infinite;
    -moz-animation: modaal-loading-spinner 1s linear infinite;
    -webkit-animation: modaal-loading-spinner 1s linear infinite;
    -o-animation: modaal-loading-spinner 1s linear infinite;
    animation: modaal-loading-spinner 1s linear infinite;
    -ms-animation-delay: 0s;
    -moz-animation-delay: 0s;
    -webkit-animation-delay: 0s;
    -o-animation-delay: 0s;
    animation-delay: 0s;
  }
  .modaal-loading-spinner > div:nth-of-type(2) > div, .modaal-loading-spinner > div:nth-of-type(3) > div {
    -ms-animation: modaal-loading-spinner 1s linear infinite;
    -moz-animation: modaal-loading-spinner 1s linear infinite;
    -webkit-animation: modaal-loading-spinner 1s linear infinite;
    -o-animation: modaal-loading-spinner 1s linear infinite;
  }
  .modaal-loading-spinner > div:nth-of-type(1) {
    -ms-transform: translate(84px, 84px) rotate(45deg) translate(70px, 0);
    -moz-transform: translate(84px, 84px) rotate(45deg) translate(70px, 0);
    -webkit-transform: translate(84px, 84px) rotate(45deg) translate(70px, 0);
    -o-transform: translate(84px, 84px) rotate(45deg) translate(70px, 0);
    transform: translate(84px, 84px) rotate(45deg) translate(70px, 0);
  }
  .modaal-loading-spinner > div:nth-of-type(2) > div {
    animation: modaal-loading-spinner 1s linear infinite;
    -ms-animation-delay: 0.12s;
    -moz-animation-delay: 0.12s;
    -webkit-animation-delay: 0.12s;
    -o-animation-delay: 0.12s;
    animation-delay: 0.12s;
  }
  .modaal-loading-spinner > div:nth-of-type(2) {
    -ms-transform: translate(84px, 84px) rotate(90deg) translate(70px, 0);
    -moz-transform: translate(84px, 84px) rotate(90deg) translate(70px, 0);
    -webkit-transform: translate(84px, 84px) rotate(90deg) translate(70px, 0);
    -o-transform: translate(84px, 84px) rotate(90deg) translate(70px, 0);
    transform: translate(84px, 84px) rotate(90deg) translate(70px, 0);
  }
  .modaal-loading-spinner > div:nth-of-type(3) > div {
    animation: modaal-loading-spinner 1s linear infinite;
    -ms-animation-delay: 0.25s;
    -moz-animation-delay: 0.25s;
    -webkit-animation-delay: 0.25s;
    -o-animation-delay: 0.25s;
    animation-delay: 0.25s;
  }
  .modaal-loading-spinner > div:nth-of-type(4) > div, .modaal-loading-spinner > div:nth-of-type(5) > div {
    -ms-animation: modaal-loading-spinner 1s linear infinite;
    -moz-animation: modaal-loading-spinner 1s linear infinite;
    -webkit-animation: modaal-loading-spinner 1s linear infinite;
    -o-animation: modaal-loading-spinner 1s linear infinite;
  }
  .modaal-loading-spinner > div:nth-of-type(3) {
    -ms-transform: translate(84px, 84px) rotate(135deg) translate(70px, 0);
    -moz-transform: translate(84px, 84px) rotate(135deg) translate(70px, 0);
    -webkit-transform: translate(84px, 84px) rotate(135deg) translate(70px, 0);
    -o-transform: translate(84px, 84px) rotate(135deg) translate(70px, 0);
    transform: translate(84px, 84px) rotate(135deg) translate(70px, 0);
  }
  .modaal-loading-spinner > div:nth-of-type(4) > div {
    animation: modaal-loading-spinner 1s linear infinite;
    -ms-animation-delay: 0.37s;
    -moz-animation-delay: 0.37s;
    -webkit-animation-delay: 0.37s;
    -o-animation-delay: 0.37s;
    animation-delay: 0.37s;
  }
  .modaal-loading-spinner > div:nth-of-type(4) {
    -ms-transform: translate(84px, 84px) rotate(180deg) translate(70px, 0);
    -moz-transform: translate(84px, 84px) rotate(180deg) translate(70px, 0);
    -webkit-transform: translate(84px, 84px) rotate(180deg) translate(70px, 0);
    -o-transform: translate(84px, 84px) rotate(180deg) translate(70px, 0);
    transform: translate(84px, 84px) rotate(180deg) translate(70px, 0);
  }
  .modaal-loading-spinner > div:nth-of-type(5) > div {
    animation: modaal-loading-spinner 1s linear infinite;
    -ms-animation-delay: 0.5s;
    -moz-animation-delay: 0.5s;
    -webkit-animation-delay: 0.5s;
    -o-animation-delay: 0.5s;
    animation-delay: 0.5s;
  }
  .modaal-loading-spinner > div:nth-of-type(6) > div, .modaal-loading-spinner > div:nth-of-type(7) > div {
    -ms-animation: modaal-loading-spinner 1s linear infinite;
    -moz-animation: modaal-loading-spinner 1s linear infinite;
    -webkit-animation: modaal-loading-spinner 1s linear infinite;
    -o-animation: modaal-loading-spinner 1s linear infinite;
  }
  .modaal-loading-spinner > div:nth-of-type(5) {
    -ms-transform: translate(84px, 84px) rotate(225deg) translate(70px, 0);
    -moz-transform: translate(84px, 84px) rotate(225deg) translate(70px, 0);
    -webkit-transform: translate(84px, 84px) rotate(225deg) translate(70px, 0);
    -o-transform: translate(84px, 84px) rotate(225deg) translate(70px, 0);
    transform: translate(84px, 84px) rotate(225deg) translate(70px, 0);
  }
  .modaal-loading-spinner > div:nth-of-type(6) > div {
    animation: modaal-loading-spinner 1s linear infinite;
    -ms-animation-delay: 0.62s;
    -moz-animation-delay: 0.62s;
    -webkit-animation-delay: 0.62s;
    -o-animation-delay: 0.62s;
    animation-delay: 0.62s;
  }
  .modaal-loading-spinner > div:nth-of-type(6) {
    -ms-transform: translate(84px, 84px) rotate(270deg) translate(70px, 0);
    -moz-transform: translate(84px, 84px) rotate(270deg) translate(70px, 0);
    -webkit-transform: translate(84px, 84px) rotate(270deg) translate(70px, 0);
    -o-transform: translate(84px, 84px) rotate(270deg) translate(70px, 0);
    transform: translate(84px, 84px) rotate(270deg) translate(70px, 0);
  }
  .modaal-loading-spinner > div:nth-of-type(7) > div {
    animation: modaal-loading-spinner 1s linear infinite;
    -ms-animation-delay: 0.75s;
    -moz-animation-delay: 0.75s;
    -webkit-animation-delay: 0.75s;
    -o-animation-delay: 0.75s;
    animation-delay: 0.75s;
  }
  .modaal-loading-spinner > div:nth-of-type(7) {
    -ms-transform: translate(84px, 84px) rotate(315deg) translate(70px, 0);
    -moz-transform: translate(84px, 84px) rotate(315deg) translate(70px, 0);
    -webkit-transform: translate(84px, 84px) rotate(315deg) translate(70px, 0);
    -o-transform: translate(84px, 84px) rotate(315deg) translate(70px, 0);
    transform: translate(84px, 84px) rotate(315deg) translate(70px, 0);
  }
  .modaal-loading-spinner > div:nth-of-type(8) > div {
    -ms-animation: modaal-loading-spinner 1s linear infinite;
    -moz-animation: modaal-loading-spinner 1s linear infinite;
    -webkit-animation: modaal-loading-spinner 1s linear infinite;
    -o-animation: modaal-loading-spinner 1s linear infinite;
    animation: modaal-loading-spinner 1s linear infinite;
    -ms-animation-delay: 0.87s;
    -moz-animation-delay: 0.87s;
    -webkit-animation-delay: 0.87s;
    -o-animation-delay: 0.87s;
    animation-delay: 0.87s;
  }
  .modaal-loading-spinner > div:nth-of-type(8) {
    -ms-transform: translate(84px, 84px) rotate(360deg) translate(70px, 0);
    -moz-transform: translate(84px, 84px) rotate(360deg) translate(70px, 0);
    -webkit-transform: translate(84px, 84px) rotate(360deg) translate(70px, 0);
    -o-transform: translate(84px, 84px) rotate(360deg) translate(70px, 0);
    transform: translate(84px, 84px) rotate(360deg) translate(70px, 0);
  }
  .page .singleContents p {
    font-size: 16px;
  }
  .singleContents {
    /* 投稿者情報
    -----------------------------------------*/
    /* メルマガ配信
    -----------------------------------------*/
  }
  .singleContents .has-inline-color.has-luminous-vivid-orange-color {
    color: #f07800 !important;
  }
  .singleContents .inner > *:first-child {
    margin-top: 0;
  }
  .singleContents p {
    font-size: 18px;
  }
  .singleContents p {
    margin-top: 40px;
    margin-bottom: 30px;
  }
  .singleContents h2 + p,
  .singleContents h3 + p,
  .singleContents h4 + p {
    margin-top: 30px;
    margin-bottom: 30px;
  }
  .singleContents * + h2 {
    margin-top: 80px;
  }
  .singleContents * + h3 {
    margin-top: 40px;
  }
  .singleContents * + h4 {
    margin-top: 40px;
  }
  .singleContents * + h2,
  .singleContents * + h3,
  .singleContents * + h4 {
    margin-bottom: 30px;
  }
  .singleContents h2 {
    font-size: 26px;
    padding-bottom: 20px;
  }
  .singleContents h3 {
    font-size: 20px;
    padding-left: 25px;
  }
  .singleContents h4 {
    font-size: 18px;
  }
  .singleContents ul li {
    padding-left: 25px;
    position: relative;
  }
  .singleContents ul li:not(:first-of-type) {
    margin-top: 10px;
  }
  .singleContents ul li:before {
    content: "";
    display: inline-block;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: #17184b;
    position: absolute;
    left: 0;
    top: 12px;
  }
  .singleContents ul li ul {
    margin-bottom: 0.8em;
    margin-top: 10px;
  }
  .singleContents * + ul,
  .singleContents * + ol {
    margin-top: 40px;
    margin-bottom: 40px;
  }
  .singleContents p + .wp-block-gallery {
    margin-top: 10px;
  }
  .singleContents .wp-block-button {
    margin-bottom: 40px;
  }
  .singleContents .wp-block-button a,
  .singleContents .wp-block-button .wp-block-button__link {
    box-shadow: 2px 2px 0 0 #17184b;
    min-width: 288px;
    padding: 14px 40px;
    line-height: 1.2;
    transition: all 0.3s;
  }
  .singleContents .wp-block-button a:hover,
  .singleContents .wp-block-button .wp-block-button__link:hover {
    background-color: #17184b !important;
    color: #fff !important;
  }
  .singleContents .wp-block-button a:hover::before,
  .singleContents .wp-block-button .wp-block-button__link:hover::before {
    background-image: url(../images/common/icon_arrow_white.svg);
  }
  .singleContents .wp-block-button .wp-block-button__link:hover {
    border-color: #17184b;
    background-color: #fff;
    color: #17184b;
  }
  .singleContents .wp-block-button.btnOrange .wp-block-button__link {
    box-shadow: 2px 2px 0 0 #e8651a;
  }
  .singleContents .wp-block-button.btnOrange .wp-block-button__link:hover {
    border-color: #e8651a;
    background-color: #e8651a !important;
  }
  .singleContents .wp-block-button.is-style-outline .wp-block-button__link:hover {
    background-color: #17184b;
    color: #fff;
  }
  .singleContents .wp-block-buttons {
    margin-bottom: 40px;
  }
  .singleContents .wp-block-buttons .wp-block-button {
    margin-bottom: 0;
  }
  .singleContents .wp-block-table tr th {
    padding: 25px 40px;
  }
  .singleContents .wp-block-table tr td {
    padding: 25px 40px;
  }
  .singleContents .wp-block-image {
    margin-bottom: 40px;
  }
  .singleContents .wp-block-media-text {
    margin-bottom: 40px;
  }
  .singleContents .wp-block-media-text .wp-block-media-text__media + .wp-block-media-text__content {
    padding-right: 0;
  }
  .singleContents .wp-block-media-text.has-media-on-the-right .wp-block-media-text__content {
    padding-left: 0;
  }
  .singleContents .wp-block-group {
    margin-bottom: 40px;
  }
  .singleContents .colorBox {
    padding: 40px;
    margin-bottom: 40px;
  }
  .singleContents .colorBox .title {
    font-size: 18px;
    margin-bottom: 20px;
  }
  .singleContents .profileBox {
    padding: 40px 42px;
    margin-bottom: 40px;
  }
  .singleContents .profileBox .profileTitle {
    font-size: 18px;
  }
  .singleContents .profileBox .profileInner .infoArea img {
    width: 100px;
  }
  .singleContents .profileBox .profileInner .authorPosition {
    font-size: 15px;
  }
  .singleContents .profileBox .profileInner .authorName {
    font-size: 18px;
  }
  .singleContents .profileBox .profileInner .authorProfile {
    font-size: 16px;
  }
  .singleContents .profileBox .snsIcon:hover {
    text-decoration: underline;
  }
  .singleContents .magazineBox {
    padding: 40px 42px;
    margin-bottom: 40px;
  }
  .singleContents .magazineBox .title {
    font-size: 24px;
  }
  .singleContents .magazineBox .title .icon.iconMailMagazine {
    padding-left: 40px;
  }
  .singleContents .magazineBox .title .icon.iconMailMagazine::before {
    width: 27px;
    height: 29px;
  }
  .singleContents .magazineBox .magagineTxt {
    font-size: 16px;
    margin-top: 20px;
  }
  .singleContents .magazineBox .magaginePolicy {
    font-size: 12px;
  }
  .singleContents .magazineBox .mailMagazine {
    max-width: 425px;
    margin-inline: auto;
  }
  .singleContents .magazineBox .mailMagazine .inputWrap {
    display: flex;
  }
  .singleContents .magazineBox .mailMagazine .magazineInput {
    font-size: 16px !important;
    height: 30px;
    width: calc(100% - 105px);
    padding: 5px;
  }
  .singleContents .magazineBox .mailMagazine .magazineSubmit {
    font-size: 16px !important;
    box-shadow: 2px 2px 0 0 #17184b;
    cursor: pointer;
    width: 94px;
    margin-left: 7px;
    transition: 0.3s cubic-bezier(0.25, 0.1, 0, 0.72);
  }
  .singleContents .magazineBox .mailMagazine .magazineSubmit:hover {
    background-color: #17184b;
    color: #fff;
  }
  * + p {
    margin-top: 40px;
  }
  h2 + p,
  h3 + p,
  h4 + p {
    margin-top: 30px;
    margin-bottom: 30px;
  }
  * + h2.title,
  * + h2.title2 {
    margin-top: 80px;
  }
  * + h3.title {
    margin-top: 40px;
  }
  * + h4.title {
    margin-top: 40px;
  }
  h2.title {
    font-size: 26px;
    padding-bottom: 20px;
  }
  h2.title2 {
    font-size: 34px;
  }
  h2.title2 .small {
    font-size: 26px;
  }
  h3.title {
    font-size: 20px;
    padding-left: 25px;
  }
  h4.title {
    font-size: 18px;
  }
  ul.commonList li {
    padding-left: 25px;
    position: relative;
  }
  ul.commonList li:not(:first-of-type) {
    margin-top: 10px;
  }
  ul.commonList li:before {
    content: "";
    display: inline-block;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: #17184b;
    position: absolute;
    left: 0;
    top: 12px;
  }
  ul.commonList li ul {
    margin-bottom: 0.8em;
    margin-top: 10px;
  }
  ul.col2List {
    display: flex;
    flex-wrap: wrap;
  }
  ul.col2List li {
    width: 50%;
    flex-basis: 50%;
  }
  ul.col2List li:not(:first-of-type) {
    margin-top: 0px;
  }
  ul.col2List li:nth-of-type(n + 3) {
    margin-top: 10px;
  }
  section.bgGray {
    padding: 80px 0;
  }
  section.bgGray.pageBottom {
    margin-bottom: -100px;
  }
  section.bgGray.harfBottom {
    padding-bottom: 70px;
  }
  section + section {
    margin-top: 80px;
  }
  * + .commonList,
  * + .linkList,
  * + .colorBox,
  * + ol,
  * + .commonTable {
    margin-top: 40px;
  }
  * + .imgLine2,
  * + .imgLine3,
  * + .twoCol {
    margin-top: 40px;
  }
  .catch {
    font-size: 34px;
  }
  a.icon {
    display: inline-block;
    position: relative;
    padding-right: 25px;
  }
  a.icon:after {
    content: "";
    display: block;
    width: 20px;
    height: 20px;
    background-repeat: no-repeat;
    background-position: 50% 0;
    position: absolute;
    right: 0px;
    top: 0.2em;
  }
  a.icon[href$=pdf]:after {
    background-image: url(../images/second/icon_pdf.svg);
  }
  a.icon[href$=doc]:after, a.icon[href$=docx]:after {
    background-image: url(../images/second/icon_word.svg);
  }
  a.icon[href$=xls]:after, a.icon[href$=xlsx]:after {
    background-image: url(../images/second/icon_excel.svg);
  }
  a.icon.front {
    padding-left: 25px;
    padding-right: 0;
  }
  a.icon.front:after {
    left: 0px;
  }
  .iconBlank {
    display: inline-block;
    width: 17px;
    height: 17px;
    background: url("../images/second/icon_blank.svg") no-repeat 0 0;
    background-size: cover;
    vertical-align: middle;
    margin-left: 10px;
    position: relative;
    top: -3px;
  }
  .commonBtn {
    margin: auto 0 0 0;
  }
  .imgLine2 {
    display: flex;
    flex-wrap: wrap;
  }
  .imgLine2 li {
    text-align: center;
    width: calc(50% - 25px);
  }
  .imgLine2 li:nth-of-type(odd) {
    margin-right: 50px;
  }
  .imgLine2 li:nth-of-type(n + 3) {
    margin-top: 50px;
  }
  .imgLine2 li img {
    max-width: 100%;
  }
  .imgLine2.small {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
  }
  .imgLine3 {
    display: flex;
    flex-wrap: wrap;
  }
  .imgLine3 li {
    text-align: center;
    width: calc(33.3333333333% - 17px);
    margin-right: 25px;
  }
  .imgLine3 li:nth-of-type(3n) {
    margin-right: 0;
  }
  .imgLine3 li:nth-of-type(n + 4) {
    margin-top: 25px;
  }
  .imgLine3 li img {
    max-width: 100%;
  }
  .imgInP {
    overflow: hidden;
  }
  .imgInP .img {
    max-width: 300px;
  }
  .imgInP .img img {
    width: 100%;
    height: auto;
  }
  .imgInP .img .cap {
    display: block;
    text-align: left;
    font-size: 14px;
    margin-top: 10px;
  }
  .imgInP .img.left {
    float: left;
    margin-right: 40px;
  }
  .imgInP .img.right {
    float: right;
    margin-left: 40px;
  }
  .twoCol {
    display: flex;
    flex-wrap: wrap;
  }
  .twoCol.center {
    align-items: center;
  }
  .twoCol > div {
    width: calc(50% - 30px);
  }
  .twoCol > div:nth-of-type(odd) {
    margin-right: 60px;
  }
  .twoCol > div:nth-of-type(n + 3) {
    margin-top: 60px;
  }
  .twoCol > div.movie a {
    display: block;
    transition: 0.3s;
    position: relative;
  }
  .twoCol > div.movie a:before {
    content: "";
    display: inline-block;
    background: url(../images/second/play.png) no-repeat;
    background-size: cover;
    width: 96px;
    height: 96px;
    position: absolute;
    top: calc(50% - 48px);
    left: calc(50% - 48px);
  }
  .twoCol > div.movie a:hover {
    opacity: 0.7;
  }
  .twoCol > div.movie img {
    box-shadow: 6px 5px 12px 2px #cccccc, -8px -9px 11px 5px #ffffff;
  }
  .twoCol.imgAdjustCol > div img {
    width: 544px;
  }
  .twoCol .cap {
    display: block;
    text-align: left;
    font-size: 14px;
    margin-top: 10px;
  }
  .colorBox {
    padding: 40px;
    margin-bottom: 40px;
  }
  .colorBox .title {
    font-size: 18px;
    margin-bottom: 20px;
  }
  .iframeWrap {
    position: relative;
    padding-bottom: 56.25%;
    margin-top: 40px;
  }
  .iframeWrap iframe {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
  }
  .commonTable td.wFix,
  .commonTable th.wFix {
    width: 150px;
  }
  .commonTable th {
    padding: 25px 40px;
  }
  .commonTable th.null {
    border-top: 1px solid #c1c1d0;
    border-left: 1px solid #c1c1d0;
    background-color: #fff;
  }
  .commonTable td {
    padding: 25px 40px;
  }
  .scrollTable {
    margin-top: 40px;
  }
  .scrollText {
    font-size: 12px;
    display: block;
    text-align: right;
    margin: 30px 0 -20px;
  }
  .dateArea {
    margin-bottom: 25px;
  }
  .dateArea .entryDate,
  .dateArea .entryUpDate {
    font-size: 13px;
    line-height: 1.5;
  }
  .pointerNone {
    cursor: default;
    text-decoration: none;
  }
  .taC {
    text-align: center !important;
  }
  .taL {
    text-align: left !important;
  }
  .taR {
    text-align: right !important;
  }
  .Center {
    margin: 0 auto !important;
  }
  .ovh {
    overflow: hidden !important;
  }
  .mw100 {
    max-width: 100% !important;
    width: auto !important;
  }
  .w100p {
    width: 100% !important;
  }
  .pT0 {
    padding-top: 0 !important;
  }
  .mB0 {
    margin-bottom: 0 !important;
  }
  .mB5 {
    margin-bottom: 5px !important;
  }
  .mB10 {
    margin-bottom: 10px !important;
  }
  .mB20 {
    margin-bottom: 20px !important;
  }
  .mB30 {
    margin-bottom: 30px !important;
  }
  .mB40 {
    margin-bottom: 40px !important;
  }
  .mB50 {
    margin-bottom: 50px !important;
  }
  .mT0 {
    margin-top: 0 !important;
  }
  .mT5 {
    margin-top: 5px !important;
  }
  .mT10 {
    margin-top: 10px !important;
  }
  .mT20 {
    margin-top: 20px !important;
  }
  .mT30 {
    margin-top: 30px !important;
  }
  .mT40 {
    margin-top: 40px !important;
  }
  .mT50 {
    margin-top: 50px !important;
  }
  .mL0 {
    margin-left: 0px !important;
  }
  .mL5 {
    margin-left: 5px !important;
  }
  .mL10 {
    margin-left: 10px !important;
  }
  .mL20 {
    margin-left: 20px !important;
  }
  .mL30 {
    margin-left: 30px !important;
  }
  .mL40 {
    margin-left: 40px !important;
  }
  .mL50 {
    margin-left: 50px !important;
  }
  .mR0 {
    margin-right: 0px !important;
  }
  .mR5 {
    margin-right: 5px !important;
  }
  .mR10 {
    margin-right: 10px !important;
  }
  .mR20 {
    margin-right: 20px !important;
  }
  .mR30 {
    margin-right: 30px !important;
  }
  .mR40 {
    margin-right: 40px !important;
  }
  .mR50 {
    margin-right: 50px !important;
  }
  .mB0_pc {
    margin-bottom: 0 !important;
  }
  .mB5_pc {
    margin-bottom: 5px !important;
  }
  .mB10_pc {
    margin-bottom: 10px !important;
  }
  .mB20_pc {
    margin-bottom: 20px !important;
  }
  .mB30_pc {
    margin-bottom: 30px !important;
  }
  .mB40_pc {
    margin-bottom: 40px !important;
  }
  .mB50_pc {
    margin-bottom: 50px !important;
  }
  .mT0_pc {
    margin-top: 0 !important;
  }
  .mT5_pc {
    margin-top: 5px !important;
  }
  .mT10_pc {
    margin-top: 10px !important;
  }
  .mT20_pc {
    margin-top: 20px !important;
  }
  .mT30_pc {
    margin-top: 30px !important;
  }
  .mT40_pc {
    margin-top: 40px !important;
  }
  .mT50_pc {
    margin-top: 50px !important;
  }
  .mL0_pc {
    margin-left: 0px !important;
  }
  .mL5_pc {
    margin-left: 5px !important;
  }
  .mL10_pc {
    margin-left: 10px !important;
  }
  .mL20_pc {
    margin-left: 20px !important;
  }
  .mL30_pc {
    margin-left: 30px !important;
  }
  .mL40_pc {
    margin-left: 40px !important;
  }
  .mL50_pc {
    margin-left: 50px !important;
  }
  .mR0_pc {
    margin-right: 0px !important;
  }
  .mR5_pc {
    margin-right: 5px !important;
  }
  .mR10_pc {
    margin-right: 10px !important;
  }
  .mR20_pc {
    margin-right: 20px !important;
  }
  .mR30_pc {
    margin-right: 30px !important;
  }
  .mR40_pc {
    margin-right: 40px !important;
  }
  .mR50_pc {
    margin-right: 50px !important;
  }
  .size12 {
    font-size: 12px !important;
  }
  .size14 {
    font-size: 14px !important;
  }
  .size16 {
    font-size: 16px !important;
  }
  .size18 {
    font-size: 18px !important;
  }
  .size20 {
    font-size: 20px !important;
  }
  .bold {
    font-weight: bold !important;
  }
  .bdNone {
    border: none !important;
  }
  .underline {
    text-decoration: underline !important;
  }
  .textOrange {
    color: #f07800 !important;
  }
  .marker {
    background: linear-gradient(transparent 60%, #ffd4d5 60%) !important;
  }
  .floatL {
    float: left;
  }
  .floatR {
    float: right;
  }
  .preBox {
    background: #eff0f4;
    border: 1px solid #17184b;
    padding: 15px;
    box-sizing: border-box;
    clear: both;
    overflow: hidden;
    margin-top: 30px;
    font-family: Arial, sans-serif;
    line-height: 1.8;
  }
  .preBox pre {
    overflow: auto;
    white-space: pre-wrap;
    word-wrap: break-word;
    text-align-last: auto;
  }
}
@media screen and (min-width: 769px) and (max-width: 1200px) {
  h1,
  h2,
  h3,
  h4,
  h5 {
    line-height: 1.5;
  }
  .pager ul .prev,
  .pager ul .next {
    position: relative;
    border: none;
  }
  .formWrap input[type=checkbox] {
    display: none;
  }
  .formWrap input[type=checkbox] + label {
    position: relative;
    display: inline-block;
    padding: 3px 3px 3px 25px;
    cursor: pointer;
    -webkit-transition: all 0.2s;
    transition: all 0.2s;
  }
  .formWrap input[type=checkbox] + label::before,
  .formWrap input[type=checkbox] + label::after {
    position: absolute;
    content: "";
    -webkit-transition: all 0.2s;
    transition: all 0.2s;
  }
  .formWrap input[type=checkbox] + label::before {
    top: 50%;
    left: 0;
    width: 16px;
    height: 16px;
    margin-top: -8px;
    background: #fff;
    border: 1px solid #ccc;
    border-radius: 3px;
  }
  .formWrap input[type=checkbox] + label::after {
    opacity: 0;
    top: 50%;
    left: 3px;
    width: 10px;
    height: 5px;
    margin-top: -4px;
    border-left: 2px solid #17184b;
    border-bottom: 2px solid #17184b;
    -webkit-transform: rotate(-45deg) scale(0.5);
    transform: rotate(-45deg) scale(0.5);
  }
  .formWrap input[type=checkbox] + label:hover::before {
    background: #fff;
  }
  .formWrap input[type=checkbox]:checked + label::before {
    background: #fff;
    border: 1px solid #17184b;
  }
  .formWrap input[type=checkbox]:checked + label::after {
    opacity: 1;
    -webkit-transform: rotate(-45deg) scale(1);
    transform: rotate(-45deg) scale(1);
  }
  .formWrap input[type=submit].commonBtn {
    background-image: url(../images/common/icon_arrow_white.svg);
    background-repeat: no-repeat;
    background-position: center right 24px;
  }
  .formWrap br {
    display: none;
  }
  #recommend.faqTop {
    background-color: #f8f8f8;
  }
  .solarAbout .fvBtnList li a .icon.iconMail::before {
    background-image: url(../images/common/icon_mail_white.svg);
  }
  #contents .cardListWrap .img {
    width: 160px;
  }
  #contents .cardListWrap .txt {
    width: calc(100% - 160px - 10px);
  }
  .voiceList a.voiceCont .voiceSubTitle .number {
    font-family: "Jost", sans-serif;
    font-weight: 600;
  }
  .voiceList a.voiceCont .voiceImg img {
    object-fit: cover;
    transition: 0.3s transform cubic-bezier(0.25, 0.1, 0, 0.72);
  }
  .main_contact .headDeco {
    display: none;
  }
  .aform input[type=text].error,
  .aform input[type=password].error,
  .aform input[type=tel].error,
  .aform input[type=email].error,
  .aform select.error,
  .aform textarea.error {
    background-color: #fff1f2;
  }
  .aform input[type=radio] {
    display: none;
  }
  .aform input[type=radio] + label {
    position: relative;
    display: inline-block;
    padding: 3px 3px 3px 25px;
    cursor: pointer;
    margin-right: 20px;
  }
  .aform input[type=radio] + label::before,
  .aform input[type=radio] + label::after {
    position: absolute;
    content: "";
    top: 50%;
    border-radius: 100%;
    -webkit-transition: all 0.2s;
    transition: all 0.2s;
  }
  .aform input[type=radio] + label::before {
    left: 0;
    width: 17px;
    height: 17px;
    margin-top: -10px;
    background: #fff;
    border: 1px solid #ccc;
  }
  .aform input[type=radio] + label:hover::before {
    background: #fff;
  }
  .aform input[type=radio] + label::after {
    opacity: 0;
    left: 4px;
    width: 9px;
    height: 9px;
    margin-top: -6px;
    background: #5dbf5d;
    -webkit-transform: scale(2);
    transform: scale(2);
  }
  .aform input[type=radio]:checked + label::before {
    background: #fff;
    border: 1px solid #5dbf5d;
  }
  .aform input[type=radio]:checked + label::after {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  .aform input[type=checkbox] {
    opacity: 0;
    width: 1px;
    height: 1px;
  }
  .aform input[type=checkbox] + label {
    position: relative;
    display: inline-block;
    padding: 3px 3px 3px 40px;
    cursor: pointer;
    -webkit-transition: all 0.2s;
    transition: all 0.2s;
  }
  .aform input[type=checkbox] + label::before,
  .aform input[type=checkbox] + label::after {
    position: absolute;
    content: "";
    -webkit-transition: all 0.2s;
    transition: all 0.2s;
  }
  .aform input[type=checkbox] + label::before {
    top: 50%;
    left: 0;
    width: 20px;
    height: 20px;
    margin-top: -11px;
    background: #fff;
    border: 2px solid #ccc;
    border-radius: 3px;
  }
  .aform input[type=checkbox] + label::after {
    opacity: 0;
    border-left: 2px solid #17184b;
    border-bottom: 2px solid #17184b;
    -webkit-transform: rotate(-45deg) scale(0.5);
    transform: rotate(-45deg) scale(0.5);
  }
  .aform input[type=checkbox] + label:hover::before {
    background: #fff;
  }
  .aform input[type=checkbox]:checked + label::before {
    background: #fff;
    border: 1px solid #ccc;
  }
  .aform input[type=checkbox]:checked + label::after {
    opacity: 1;
    -webkit-transform: rotate(-45deg) scale(1);
    transform: rotate(-45deg) scale(1);
  }
  .form_dt {
    display: block;
    font-weight: bold;
  }
  .aform .aform-content .aform-note {
    background: none;
    margin-top: 20px;
    margin-bottom: 20px;
  }
  #aform_btn_back {
    font-size: 16px;
    order: 2;
  }
  .aform .aform-content dl.aform-error-list {
    border: none;
  }
  .aform .aform-content dl.aform-error-list dt {
    display: block;
  }
  .aform .aform-content dl.aform-error-list dd {
    display: list-item;
    margin-left: 25px;
    width: auto;
  }
  #aform_confirm_msg {
    text-align: center;
    padding-top: 20px;
  }
  img[src$="icon_new_windows.gif"] {
    margin-left: 10px;
    vertical-align: middle;
  }
  .aform-header {
    display: none;
  }
  .aform #aform_result {
    color: #000;
  }
  .aform #aform_result a.commonBtn {
    margin: 20px auto 30px;
  }
  /*!
  	Modaal - accessible modals - v0.4.4
  	by Humaan, for all humans.
  	http://humaan.com
   */
  .modaal-noscroll {
    overflow: hidden;
  }
  .modaal-accessible-hide {
    position: absolute !important;
    clip: rect(1px 1px 1px 1px); /* IE6, IE7 */
    clip: rect(1px, 1px, 1px, 1px);
    padding: 0 !important;
    border: 0 !important;
    height: 1px !important;
    width: 1px !important;
    overflow: hidden;
  }
  .modaal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 999;
    opacity: 0;
  }
  .modaal-wrapper {
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 9999;
    overflow: auto;
    opacity: 1;
    box-sizing: border-box;
    -webkit-overflow-scrolling: touch;
    transition: all 0.3s ease-in-out;
  }
  .modaal-wrapper * {
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-backface-visibility: hidden;
  }
  .modaal-wrapper .modaal-close {
    border: none;
    background: transparent;
    padding: 0;
    -webkit-appearance: none;
  }
  .modaal-wrapper.modaal-start_none {
    display: none;
    opacity: 1;
  }
  .modaal-wrapper.modaal-start_fade {
    opacity: 0;
  }
  .modaal-wrapper *[tabindex="0"] {
    outline: none !important;
  }
  .modaal-wrapper.modaal-fullscreen {
    overflow: hidden;
  }
  .modaal-outer-wrapper {
    display: table;
    position: relative;
    width: 100%;
    height: 100%;
  }
  .modaal-fullscreen .modaal-outer-wrapper {
    display: block;
  }
  .modaal-inner-wrapper {
    display: table-cell;
    width: 100%;
    height: 100%;
    position: relative;
    vertical-align: middle;
    text-align: center;
    padding: 80px 25px;
  }
  .modaal-fullscreen .modaal-inner-wrapper {
    padding: 0;
    display: block;
    vertical-align: top;
  }
  .modaal-container {
    position: relative;
    display: inline-block;
    width: 100%;
    margin: auto;
    text-align: left;
    color: #000;
    max-width: 1000px;
    border-radius: 0px;
    background: #fff;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
    cursor: auto;
  }
  .modaal-container.is_loading {
    height: 100px;
    width: 100px;
    overflow: hidden;
  }
  .modaal-fullscreen .modaal-container {
    max-width: none;
    height: 100%;
    overflow: auto;
  }
  .modaal-close {
    position: fixed;
    right: 20px;
    top: 20px;
    color: #fff;
    cursor: pointer;
    opacity: 1;
    width: 50px;
    height: 50px;
    background: rgba(0, 0, 0, 0);
    border-radius: 100%;
    transition: all 0.2s ease-in-out;
  }
  .modaal-close:focus, .modaal-close:hover {
    outline: none;
    background: #fff;
  }
  .modaal-close:focus:before, .modaal-close:focus:after, .modaal-close:hover:before, .modaal-close:hover:after {
    background: #b93d0c;
  }
  .modaal-close span {
    position: absolute !important;
    clip: rect(1px 1px 1px 1px); /* IE6, IE7 */
    clip: rect(1px, 1px, 1px, 1px);
    padding: 0 !important;
    border: 0 !important;
    height: 1px !important;
    width: 1px !important;
    overflow: hidden;
  }
  .modaal-close:before, .modaal-close:after {
    display: block;
    content: " ";
    position: absolute;
    top: 14px;
    left: 23px;
    width: 4px;
    height: 22px;
    border-radius: 4px;
    background: #fff;
    transition: background 0.2s ease-in-out;
  }
  .modaal-close:before {
    transform: rotate(-45deg);
  }
  .modaal-close:after {
    transform: rotate(45deg);
  }
  .modaal-fullscreen .modaal-close {
    background: #afb7bc;
    right: 10px;
    top: 10px;
  }
  .modaal-content-container {
    padding: 30px;
  }
  .modaal-confirm-wrap {
    padding: 30px 0 0;
    text-align: center;
    font-size: 0;
  }
  .modaal-confirm-btn {
    font-size: 14px;
    display: inline-block;
    margin: 0 10px;
    vertical-align: middle;
    cursor: pointer;
    border: none;
    background: transparent;
  }
  .modaal-confirm-btn.modaal-ok {
    padding: 10px 15px;
    color: #fff;
    background: #555;
    border-radius: 3px;
    transition: background 0.2s ease-in-out;
  }
  .modaal-confirm-btn.modaal-ok:hover {
    background: #2f2f2f;
  }
  .modaal-confirm-btn.modaal-cancel {
    text-decoration: underline;
  }
  .modaal-confirm-btn.modaal-cancel:hover {
    text-decoration: none;
    color: #2f2f2f;
  }
  @keyframes instaReveal {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  @-o-keyframes instaReveal {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  @-moz-keyframes instaReveal {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  @-webkit-keyframes instaReveal {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  @-ms-keyframes instaReveal {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  .modaal-instagram .modaal-container {
    width: auto;
    background: transparent;
    box-shadow: none !important;
  }
  .modaal-instagram .modaal-content-container {
    padding: 0;
    background: transparent;
  }
  .modaal-instagram .modaal-content-container > blockquote {
    width: 1px !important;
    height: 1px !important;
    opacity: 0 !important;
  }
  .modaal-instagram iframe {
    opacity: 0;
    margin: -6px !important;
    border-radius: 0 !important;
    width: 1000px !important;
    max-width: 800px !important;
    box-shadow: none !important;
    animation: instaReveal 1s linear forwards;
  }
  .modaal-image .modaal-inner-wrapper {
    padding-left: 140px;
    padding-right: 140px;
  }
  .modaal-image .modaal-container {
    width: auto;
    max-width: 100%;
  }
  .modaal-gallery-wrap {
    position: relative;
    color: #fff;
  }
  .modaal-gallery-item {
    display: none;
  }
  .modaal-gallery-item img {
    display: block;
  }
  .modaal-gallery-item.is_active {
    display: block;
  }
  .modaal-gallery-label {
    position: absolute;
    left: 0;
    width: 100%;
    margin: 20px 0 0;
    font-size: 18px;
    text-align: center;
    color: #fff;
  }
  .modaal-gallery-label:focus {
    outline: none;
  }
  .modaal-gallery-control {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    opacity: 1;
    cursor: pointer;
    color: #fff;
    width: 50px;
    height: 50px;
    background: rgba(0, 0, 0, 0);
    border: none;
    border-radius: 100%;
    transition: all 0.2s ease-in-out;
  }
  .modaal-gallery-control.is_hidden {
    opacity: 0;
    cursor: default;
  }
  .modaal-gallery-control:focus, .modaal-gallery-control:hover {
    outline: none;
    background: #fff;
  }
  .modaal-gallery-control:focus:before, .modaal-gallery-control:focus:after, .modaal-gallery-control:hover:before, .modaal-gallery-control:hover:after {
    background: #afb7bc;
  }
  .modaal-gallery-control span {
    position: absolute !important;
    clip: rect(1px 1px 1px 1px); /* IE6, IE7 */
    clip: rect(1px, 1px, 1px, 1px);
    padding: 0 !important;
    border: 0 !important;
    height: 1px !important;
    width: 1px !important;
    overflow: hidden;
  }
  .modaal-gallery-control:before, .modaal-gallery-control:after {
    display: block;
    content: " ";
    position: absolute;
    top: 16px;
    left: 25px;
    width: 4px;
    height: 18px;
    border-radius: 4px;
    background: #fff;
    transition: background 0.2s ease-in-out;
  }
  .modaal-gallery-control:before {
    margin: -5px 0 0;
    transform: rotate(-45deg);
  }
  .modaal-gallery-control:after {
    margin: 5px 0 0;
    transform: rotate(45deg);
  }
  .modaal-gallery-next-inner {
    left: 100%;
    margin-left: 40px;
  }
  .modaal-gallery-next-outer {
    right: 45px;
  }
  .modaal-gallery-prev:before, .modaal-gallery-prev:after {
    left: 22px;
  }
  .modaal-gallery-prev:before {
    margin: 5px 0 0;
    transform: rotate(-45deg);
  }
  .modaal-gallery-prev:after {
    margin: -5px 0 0;
    transform: rotate(45deg);
  }
  .modaal-gallery-prev-inner {
    right: 100%;
    margin-right: 40px;
  }
  .modaal-gallery-prev-outer {
    left: 45px;
  }
  .modaal-video-wrap {
    margin: auto 50px;
    position: relative;
  }
  .modaal-video-container {
    position: relative;
    padding-bottom: 56.25%;
    height: 0;
    overflow: hidden;
    max-width: 100%;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    background: #000;
    max-width: 1300px;
    margin-left: auto;
    margin-right: auto;
  }
  .modaal-video-container iframe,
  .modaal-video-container object,
  .modaal-video-container embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  .modaal-iframe .modaal-content {
    width: 100%;
    height: 100%;
  }
  .modaal-iframe-elem {
    width: 100%;
    height: 100%;
    display: block;
  }
  /* common class */
}
@media only screen and (min-width: 769px) and (max-width: 1200px) and (min-width: 1400px) {
  .modaal-video-container {
    padding-bottom: 0;
    height: 731px;
  }
}
@media only screen and (min-width: 769px) and (max-width: 1200px) and (max-width: 1140px) {
  .modaal-image .modaal-inner-wrapper {
    padding-left: 25px;
    padding-right: 25px;
  }
  .modaal-gallery-control {
    top: auto;
    bottom: 20px;
    transform: none;
    background: rgba(0, 0, 0, 0.7);
  }
  .modaal-gallery-control:before, .modaal-gallery-control:after {
    background: #fff;
  }
  .modaal-gallery-next {
    left: auto;
    right: 20px;
  }
  .modaal-gallery-prev {
    left: 20px;
    right: auto;
  }
}
@media screen and (min-width: 769px) and (max-width: 1200px) and (max-width: 900px) {
  .modaal-instagram iframe {
    width: 500px !important;
  }
}
@media screen and (min-width: 769px) and (max-width: 1200px) and (max-height: 1100px) {
  .modaal-instagram iframe {
    width: 700px !important;
  }
}
@media screen and (min-width: 769px) and (max-width: 1200px) and (max-height: 1000px) {
  .modaal-inner-wrapper {
    padding-top: 60px;
    padding-bottom: 60px;
  }
  .modaal-instagram iframe {
    width: 600px !important;
  }
}
@media screen and (min-width: 769px) and (max-width: 1200px) and (max-height: 900px) {
  .modaal-instagram iframe {
    width: 500px !important;
  }
  .modaal-video-container {
    max-width: 900px;
    max-height: 510px;
  }
}
@media only screen and (min-width: 769px) and (max-width: 1200px) and (max-width: 600px) {
  .modaal-instagram iframe {
    width: 280px !important;
  }
}
@media only screen and (min-width: 769px) and (max-width: 1200px) and (max-height: 820px) {
  .modaal-gallery-label {
    display: none;
  }
}
@media screen and (min-width: 769px) and (max-width: 1200px) {
  .modaal-loading-spinner {
    background: none;
    position: absolute;
    width: 200px;
    height: 200px;
    top: 50%;
    left: 50%;
    margin: -100px 0 0 -100px;
    transform: scale(0.25);
  }
  @-ms-keyframes modaal-loading-spinner {
    0% {
      opacity: 1;
      -ms-transform: scale(1.5);
      -moz-transform: scale(1.5);
      -webkit-transform: scale(1.5);
      -o-transform: scale(1.5);
      transform: scale(1.5);
    }
    100% {
      opacity: 0.1;
      -ms-transform: scale(1);
      -moz-transform: scale(1);
      -webkit-transform: scale(1);
      -o-transform: scale(1);
      transform: scale(1);
    }
  }
  @-moz-keyframes modaal-loading-spinner {
    0% {
      opacity: 1;
      -ms-transform: scale(1.5);
      -moz-transform: scale(1.5);
      -webkit-transform: scale(1.5);
      -o-transform: scale(1.5);
      transform: scale(1.5);
    }
    100% {
      opacity: 0.1;
      -ms-transform: scale(1);
      -moz-transform: scale(1);
      -webkit-transform: scale(1);
      -o-transform: scale(1);
      transform: scale(1);
    }
  }
  @-webkit-keyframes modaal-loading-spinner {
    0% {
      opacity: 1;
      -ms-transform: scale(1.5);
      -moz-transform: scale(1.5);
      -webkit-transform: scale(1.5);
      -o-transform: scale(1.5);
      transform: scale(1.5);
    }
    100% {
      opacity: 0.1;
      -ms-transform: scale(1);
      -moz-transform: scale(1);
      -webkit-transform: scale(1);
      -o-transform: scale(1);
      transform: scale(1);
    }
  }
  @-o-keyframes modaal-loading-spinner {
    0% {
      opacity: 1;
      -ms-transform: scale(1.5);
      -moz-transform: scale(1.5);
      -webkit-transform: scale(1.5);
      -o-transform: scale(1.5);
      transform: scale(1.5);
    }
    100% {
      opacity: 0.1;
      -ms-transform: scale(1);
      -moz-transform: scale(1);
      -webkit-transform: scale(1);
      -o-transform: scale(1);
      transform: scale(1);
    }
  }
  @keyframes modaal-loading-spinner {
    0% {
      opacity: 1;
      -ms-transform: scale(1.5);
      -moz-transform: scale(1.5);
      -webkit-transform: scale(1.5);
      -o-transform: scale(1.5);
      transform: scale(1.5);
    }
    100% {
      opacity: 0.1;
      -ms-transform: scale(1);
      -moz-transform: scale(1);
      -webkit-transform: scale(1);
      -o-transform: scale(1);
      transform: scale(1);
    }
  }
  .modaal-loading-spinner > div {
    width: 24px;
    height: 24px;
    margin-left: 4px;
    margin-top: 4px;
    position: absolute;
  }
  .modaal-loading-spinner > div > div {
    width: 100%;
    height: 100%;
    border-radius: 15px;
    background: #fff;
  }
  .modaal-loading-spinner > div:nth-of-type(1) > div {
    -ms-animation: modaal-loading-spinner 1s linear infinite;
    -moz-animation: modaal-loading-spinner 1s linear infinite;
    -webkit-animation: modaal-loading-spinner 1s linear infinite;
    -o-animation: modaal-loading-spinner 1s linear infinite;
    animation: modaal-loading-spinner 1s linear infinite;
    -ms-animation-delay: 0s;
    -moz-animation-delay: 0s;
    -webkit-animation-delay: 0s;
    -o-animation-delay: 0s;
    animation-delay: 0s;
  }
  .modaal-loading-spinner > div:nth-of-type(2) > div, .modaal-loading-spinner > div:nth-of-type(3) > div {
    -ms-animation: modaal-loading-spinner 1s linear infinite;
    -moz-animation: modaal-loading-spinner 1s linear infinite;
    -webkit-animation: modaal-loading-spinner 1s linear infinite;
    -o-animation: modaal-loading-spinner 1s linear infinite;
  }
  .modaal-loading-spinner > div:nth-of-type(1) {
    -ms-transform: translate(84px, 84px) rotate(45deg) translate(70px, 0);
    -moz-transform: translate(84px, 84px) rotate(45deg) translate(70px, 0);
    -webkit-transform: translate(84px, 84px) rotate(45deg) translate(70px, 0);
    -o-transform: translate(84px, 84px) rotate(45deg) translate(70px, 0);
    transform: translate(84px, 84px) rotate(45deg) translate(70px, 0);
  }
  .modaal-loading-spinner > div:nth-of-type(2) > div {
    animation: modaal-loading-spinner 1s linear infinite;
    -ms-animation-delay: 0.12s;
    -moz-animation-delay: 0.12s;
    -webkit-animation-delay: 0.12s;
    -o-animation-delay: 0.12s;
    animation-delay: 0.12s;
  }
  .modaal-loading-spinner > div:nth-of-type(2) {
    -ms-transform: translate(84px, 84px) rotate(90deg) translate(70px, 0);
    -moz-transform: translate(84px, 84px) rotate(90deg) translate(70px, 0);
    -webkit-transform: translate(84px, 84px) rotate(90deg) translate(70px, 0);
    -o-transform: translate(84px, 84px) rotate(90deg) translate(70px, 0);
    transform: translate(84px, 84px) rotate(90deg) translate(70px, 0);
  }
  .modaal-loading-spinner > div:nth-of-type(3) > div {
    animation: modaal-loading-spinner 1s linear infinite;
    -ms-animation-delay: 0.25s;
    -moz-animation-delay: 0.25s;
    -webkit-animation-delay: 0.25s;
    -o-animation-delay: 0.25s;
    animation-delay: 0.25s;
  }
  .modaal-loading-spinner > div:nth-of-type(4) > div, .modaal-loading-spinner > div:nth-of-type(5) > div {
    -ms-animation: modaal-loading-spinner 1s linear infinite;
    -moz-animation: modaal-loading-spinner 1s linear infinite;
    -webkit-animation: modaal-loading-spinner 1s linear infinite;
    -o-animation: modaal-loading-spinner 1s linear infinite;
  }
  .modaal-loading-spinner > div:nth-of-type(3) {
    -ms-transform: translate(84px, 84px) rotate(135deg) translate(70px, 0);
    -moz-transform: translate(84px, 84px) rotate(135deg) translate(70px, 0);
    -webkit-transform: translate(84px, 84px) rotate(135deg) translate(70px, 0);
    -o-transform: translate(84px, 84px) rotate(135deg) translate(70px, 0);
    transform: translate(84px, 84px) rotate(135deg) translate(70px, 0);
  }
  .modaal-loading-spinner > div:nth-of-type(4) > div {
    animation: modaal-loading-spinner 1s linear infinite;
    -ms-animation-delay: 0.37s;
    -moz-animation-delay: 0.37s;
    -webkit-animation-delay: 0.37s;
    -o-animation-delay: 0.37s;
    animation-delay: 0.37s;
  }
  .modaal-loading-spinner > div:nth-of-type(4) {
    -ms-transform: translate(84px, 84px) rotate(180deg) translate(70px, 0);
    -moz-transform: translate(84px, 84px) rotate(180deg) translate(70px, 0);
    -webkit-transform: translate(84px, 84px) rotate(180deg) translate(70px, 0);
    -o-transform: translate(84px, 84px) rotate(180deg) translate(70px, 0);
    transform: translate(84px, 84px) rotate(180deg) translate(70px, 0);
  }
  .modaal-loading-spinner > div:nth-of-type(5) > div {
    animation: modaal-loading-spinner 1s linear infinite;
    -ms-animation-delay: 0.5s;
    -moz-animation-delay: 0.5s;
    -webkit-animation-delay: 0.5s;
    -o-animation-delay: 0.5s;
    animation-delay: 0.5s;
  }
  .modaal-loading-spinner > div:nth-of-type(6) > div, .modaal-loading-spinner > div:nth-of-type(7) > div {
    -ms-animation: modaal-loading-spinner 1s linear infinite;
    -moz-animation: modaal-loading-spinner 1s linear infinite;
    -webkit-animation: modaal-loading-spinner 1s linear infinite;
    -o-animation: modaal-loading-spinner 1s linear infinite;
  }
  .modaal-loading-spinner > div:nth-of-type(5) {
    -ms-transform: translate(84px, 84px) rotate(225deg) translate(70px, 0);
    -moz-transform: translate(84px, 84px) rotate(225deg) translate(70px, 0);
    -webkit-transform: translate(84px, 84px) rotate(225deg) translate(70px, 0);
    -o-transform: translate(84px, 84px) rotate(225deg) translate(70px, 0);
    transform: translate(84px, 84px) rotate(225deg) translate(70px, 0);
  }
  .modaal-loading-spinner > div:nth-of-type(6) > div {
    animation: modaal-loading-spinner 1s linear infinite;
    -ms-animation-delay: 0.62s;
    -moz-animation-delay: 0.62s;
    -webkit-animation-delay: 0.62s;
    -o-animation-delay: 0.62s;
    animation-delay: 0.62s;
  }
  .modaal-loading-spinner > div:nth-of-type(6) {
    -ms-transform: translate(84px, 84px) rotate(270deg) translate(70px, 0);
    -moz-transform: translate(84px, 84px) rotate(270deg) translate(70px, 0);
    -webkit-transform: translate(84px, 84px) rotate(270deg) translate(70px, 0);
    -o-transform: translate(84px, 84px) rotate(270deg) translate(70px, 0);
    transform: translate(84px, 84px) rotate(270deg) translate(70px, 0);
  }
  .modaal-loading-spinner > div:nth-of-type(7) > div {
    animation: modaal-loading-spinner 1s linear infinite;
    -ms-animation-delay: 0.75s;
    -moz-animation-delay: 0.75s;
    -webkit-animation-delay: 0.75s;
    -o-animation-delay: 0.75s;
    animation-delay: 0.75s;
  }
  .modaal-loading-spinner > div:nth-of-type(7) {
    -ms-transform: translate(84px, 84px) rotate(315deg) translate(70px, 0);
    -moz-transform: translate(84px, 84px) rotate(315deg) translate(70px, 0);
    -webkit-transform: translate(84px, 84px) rotate(315deg) translate(70px, 0);
    -o-transform: translate(84px, 84px) rotate(315deg) translate(70px, 0);
    transform: translate(84px, 84px) rotate(315deg) translate(70px, 0);
  }
  .modaal-loading-spinner > div:nth-of-type(8) > div {
    -ms-animation: modaal-loading-spinner 1s linear infinite;
    -moz-animation: modaal-loading-spinner 1s linear infinite;
    -webkit-animation: modaal-loading-spinner 1s linear infinite;
    -o-animation: modaal-loading-spinner 1s linear infinite;
    animation: modaal-loading-spinner 1s linear infinite;
    -ms-animation-delay: 0.87s;
    -moz-animation-delay: 0.87s;
    -webkit-animation-delay: 0.87s;
    -o-animation-delay: 0.87s;
    animation-delay: 0.87s;
  }
  .modaal-loading-spinner > div:nth-of-type(8) {
    -ms-transform: translate(84px, 84px) rotate(360deg) translate(70px, 0);
    -moz-transform: translate(84px, 84px) rotate(360deg) translate(70px, 0);
    -webkit-transform: translate(84px, 84px) rotate(360deg) translate(70px, 0);
    -o-transform: translate(84px, 84px) rotate(360deg) translate(70px, 0);
    transform: translate(84px, 84px) rotate(360deg) translate(70px, 0);
  }
  .singleContents {
    /* 投稿者情報
    -----------------------------------------*/
    /* メルマガ配信
    -----------------------------------------*/
  }
  .singleContents .has-inline-color.has-luminous-vivid-orange-color {
    color: #f07800 !important;
  }
  .singleContents .inner > *:first-child {
    margin-top: 0;
  }
  .singleContents p + .wp-block-gallery {
    margin-top: 10px;
  }
  a.icon {
    display: inline-block;
    position: relative;
    padding-right: 25px;
  }
  a.icon:after {
    content: "";
    display: block;
    width: 20px;
    height: 20px;
    background-repeat: no-repeat;
    background-position: 50% 0;
    position: absolute;
    right: 0px;
    top: 0.2em;
  }
  a.icon[href$=pdf]:after {
    background-image: url(../images/second/icon_pdf.svg);
  }
  a.icon[href$=doc]:after, a.icon[href$=docx]:after {
    background-image: url(../images/second/icon_word.svg);
  }
  a.icon[href$=xls]:after, a.icon[href$=xlsx]:after {
    background-image: url(../images/second/icon_excel.svg);
  }
  a.icon.front {
    padding-left: 25px;
    padding-right: 0;
  }
  a.icon.front:after {
    left: 0px;
  }
  .iconBlank {
    display: inline-block;
    width: 17px;
    height: 17px;
    background: url("../images/second/icon_blank.svg") no-repeat 0 0;
    background-size: cover;
    vertical-align: middle;
    margin-left: 10px;
    position: relative;
    top: -3px;
  }
  .commonBtn {
    margin: auto 0 0 0;
  }
  .imgLine2 li {
    text-align: center;
  }
  .imgLine2 li img {
    max-width: 100%;
  }
  .imgLine3 li {
    text-align: center;
  }
  .imgLine3 li img {
    max-width: 100%;
  }
  .imgInP .img .cap {
    display: block;
    text-align: left;
    font-size: 14px;
    margin-top: 10px;
  }
  .twoCol .cap {
    display: block;
    text-align: left;
    font-size: 14px;
    margin-top: 10px;
  }
  .iframeWrap {
    position: relative;
    padding-bottom: 56.25%;
  }
  .iframeWrap iframe {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
  }
  .commonTable th.null {
    border-top: 1px solid #c1c1d0;
    border-left: 1px solid #c1c1d0;
    background-color: #fff;
  }
  .scrollText {
    font-size: 12px;
    display: block;
    text-align: right;
    margin: 30px 0 -20px;
  }
  .pointerNone {
    cursor: default;
    text-decoration: none;
  }
  .taC {
    text-align: center !important;
  }
  .taL {
    text-align: left !important;
  }
  .taR {
    text-align: right !important;
  }
  .Center {
    margin: 0 auto !important;
  }
  .ovh {
    overflow: hidden !important;
  }
  .mw100 {
    max-width: 100% !important;
    width: auto !important;
  }
  .w100p {
    width: 100% !important;
  }
  .pT0 {
    padding-top: 0 !important;
  }
  .mB0 {
    margin-bottom: 0 !important;
  }
  .mB5 {
    margin-bottom: 5px !important;
  }
  .mB10 {
    margin-bottom: 10px !important;
  }
  .mB20 {
    margin-bottom: 20px !important;
  }
  .mB30 {
    margin-bottom: 30px !important;
  }
  .mB40 {
    margin-bottom: 40px !important;
  }
  .mB50 {
    margin-bottom: 50px !important;
  }
  .mT0 {
    margin-top: 0 !important;
  }
  .mT5 {
    margin-top: 5px !important;
  }
  .mT10 {
    margin-top: 10px !important;
  }
  .mT20 {
    margin-top: 20px !important;
  }
  .mT30 {
    margin-top: 30px !important;
  }
  .mT40 {
    margin-top: 40px !important;
  }
  .mT50 {
    margin-top: 50px !important;
  }
  .mL0 {
    margin-left: 0px !important;
  }
  .mL5 {
    margin-left: 5px !important;
  }
  .mL10 {
    margin-left: 10px !important;
  }
  .mL20 {
    margin-left: 20px !important;
  }
  .mL30 {
    margin-left: 30px !important;
  }
  .mL40 {
    margin-left: 40px !important;
  }
  .mL50 {
    margin-left: 50px !important;
  }
  .mR0 {
    margin-right: 0px !important;
  }
  .mR5 {
    margin-right: 5px !important;
  }
  .mR10 {
    margin-right: 10px !important;
  }
  .mR20 {
    margin-right: 20px !important;
  }
  .mR30 {
    margin-right: 30px !important;
  }
  .mR40 {
    margin-right: 40px !important;
  }
  .mR50 {
    margin-right: 50px !important;
  }
  .bold {
    font-weight: bold !important;
  }
  .bdNone {
    border: none !important;
  }
  .underline {
    text-decoration: underline !important;
  }
  .textOrange {
    color: #f07800 !important;
  }
  .marker {
    background: linear-gradient(transparent 60%, #ffd4d5 60%) !important;
  }
  .floatL {
    float: left;
  }
  .floatR {
    float: right;
  }
  .preBox {
    background: #eff0f4;
    border: 1px solid #17184b;
    padding: 15px;
    box-sizing: border-box;
    clear: both;
    overflow: hidden;
    margin-top: 30px;
    font-family: Arial, sans-serif;
    line-height: 1.8;
  }
  .preBox pre {
    overflow: auto;
    white-space: pre-wrap;
    word-wrap: break-word;
    text-align-last: auto;
  }
}
@media (min-width: 601px) and (max-width: 767px) {
  .singleContents .wp-block-media-text .wp-block-media-text__content {
    padding: 0 4%;
  }
}