@charset "UTF-8";

// IMPPRT INIT

@import "_setting";
$type: "";
@import "_second";
@import "_second_common";

// FOR SMARTPHONE DISPLAY SIZE
//-----------------------------------------------------------------
@media screen and (max-width:$breakpoint_sp) {
	$type: sp;
	@import "_second";
	@import "_second_common";
}

// FOR LARGE DISPLAY SIZE
//-----------------------------------------------------------------
@media print, screen and (min-width:$breakpoint_pc) {
	$type: pc;
	@import "_second";
	@import "_second_common";
}

// FOR TABLET DISPLAY SIZE
//-----------------------------------------------------------------
@media screen and (min-width:$breakpoint_pc) and (max-width:$breakpoint_tb) {
  $type: tb;
  @import "_second";
  @import "_second_common";
}

@media (min-width: 601px) and (max-width: 767px){
	.singleContents{
		.wp-block-media-text .wp-block-media-text__content{
			padding: 0 4%;
		}
	}

	.wp-block-media-text{
		.wp-block-media-text__media + .wp-block-media-text__content{
				@if $type == pc {
						padding-right: 0;
				}
		}
	}
	.wp-block-media-text.has-media-on-the-right{
		.wp-block-media-text__content{
				@if $type == pc {
						padding-left: 0;
				}
		}
	}
}

// STYLE FOR PRINT
//-----------------------------------------------------------------
@media print {
	$type: print;
}
